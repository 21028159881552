import { TrashIcon, PlusCircleIcon } from "@heroicons/react/24/outline";
import TextInput from "../../inputs/TextInput";

function MultiTextInput({ inputsData, setinputsData }) {
  const handleInputChange = (index, fieldName, value) => {
    const newData = [...inputsData];
    newData[index][fieldName] = value;
    setinputsData(newData);
  };

  const handleAddInput = (e) => {
    e.preventDefault();
    setinputsData([
      ...inputsData,
      {
        label: "",
        name: "",
        placeholder: "",
        type: "",
      },
    ]);
  };

  const handleRemoveInput = (e, index) => {
    e.preventDefault();
    const newData = [...inputsData];
    newData.splice(index, 1);
    setinputsData(newData);
  };

  return (
    <>
      {inputsData.map((data, index) => (
        <div key={index} className="flex space-x-4 mb-5">
          <TextInput label='Label' type="text" placeholder="label of input" value={data.label} onChange={(event) => handleInputChange(index, "label", event.target.value)} />
          <TextInput label='Name' type="text" placeholder="name of input" value={data.name} onChange={(event) => handleInputChange(index, "name", event.target.value)} />
          <TextInput label='Placeholder' type="text" placeholder="placeholder of input" value={data.placeholder} onChange={(event) => handleInputChange(index, "placeholder", event.target.value)} />




          <div className="grow">
            <label className="block text-sm font-medium text-gray-700 font-inter">
              Type
            </label>

            <select
              id="type"
              name="type"
              value={data.type ? data.type : "DEFAULT"}
              onChange={(e) => handleInputChange(index, "type", e.target.value)}
              className="w-full h-11 px-4 py-2 mt-2 rounded-lg border border-gray-300 
                     focus:ring-1  focus:ring-gray-400 focus:border-gray-400
                     shadow-sm bg-input placeholder-gray-400 
                     text-gray-900 focus:outline-none transition duration-300 ease-in-out"
            >
              <option value="DEFAULT" disabled>
                Select
              </option>

              <option value={"text"}>String</option>
              <option value={"number"}>Number</option>
              <option value={"bool"}>Boolean</option>
            </select>
          </div>

          <div className="flex space-x-2 my-auto pt-4">
            <TrashIcon
              onClick={(e) => handleRemoveInput(e, index)}
              className="h-5 w-5 text-red-700 "
            />

            <PlusCircleIcon
              onClick={(e) => handleAddInput(e)}
              className="h-5 w-5 my-auto text-green-700"
            />
          </div>
        </div>
      ))}
    </>
  );
}

export default MultiTextInput;
