import axios from "axios"
import { BASE_URL } from "../../constants/config"

export const update = async (id, body) => {
    try {
        const response = await axios.put(`${BASE_URL}/system_config/organization/${id}`, body, {
            headers: {
                'Content-Type': 'application/json',
            }
        })
        if (response.status === 200) {
            return response.data.data
        }
        return response
    } catch (error) {
        console.log('error', error)
        throw (error.response.data)
    }
}


export const read = async (id) => {
    try {
        const response = await axios.get(`${BASE_URL}/system_config/organization/${id}`, {
            headers: {
                'Content-Type': 'application/json',
            }
        })
        if (response.status === 200) {
            return response.data.data
        }
        return response
    } catch (error) {
        console.log('error', error)
        throw (error.response.data)
    }
}