import moment from "moment"
import Pagination from "../Pagination"
import { useEffect } from "react"



export default function ConversationsTable({ conversations, filters, paginate, fields, systemFields, questionFields }) {

    return (
        <div className="relative overflow-x-auto mb-4 bg-white my-8 px-16 py-8 rounded-lg border shadow-lg">
            <table className="w-full text-sm text-left rtl:text-right text-gray-900">
                <thead className="text-xs text-primary uppercase bg-white  ">
                    <tr className="border-b border-gray-700 text-center font-inter">
                        {systemFields.length > 0 ?
                            systemFields.map((field) => {
                                return (
                                    <th scope="col" className="px-6 py-3" >
                                        {field}
                                    </th>
                                )
                            })
                            : undefined}



                        {fields.length > 0 ?
                            fields.map((field) => {
                                return (
                                    <th scope="col" className="px-6 py-3" >
                                        {field}
                                    </th>
                                )
                            })
                            : undefined}

                        {questionFields.length > 0 ?
                            questionFields.map((field) => {
                                return (
                                    <th scope="col" className="px-6 py-3" >
                                        {field}
                                    </th>
                                )
                            })
                            : undefined}




                    </tr>
                </thead>
                <tbody>
                    {conversations.data.map((call) => {
                        return (<TableItem key={call.sid} call={call} fields={fields} systemFields={systemFields} questionFields={questionFields}></TableItem>)
                    })}
                </tbody>
            </table>
            <Pagination total={conversations.total} limit={filters.limit} paginate={paginate} />
        </div>
    )
}

const TableItem = ({ call, fields, systemFields, questionFields }) => {
    const metadata = call.metadata || []
    const dynamic_values = call.dynamic_values || []


    const getQuestion = (field) => {
        if (dynamic_values.length > 0) {
            return dynamic_values.find((item) => item.question === field).value
        }
        return ''
    }


    return (

        <tr className="bg-white border-b border-gray-400 text-center font-inter">

            {systemFields.length > 0 ?
                systemFields.map((field) => {
                    return (
                        <td className="px-6 py-4">
                            {call[field]}
                        </td>
                    )
                })
                : undefined}

            {fields.length > 0 ?
                fields.map((field) => {
                    return (
                        <td className="px-6 py-4">
                            {metadata[field]}
                        </td>
                    )
                })
                : undefined}

            {questionFields.length > 0 ?
                questionFields.map((field) => {
                    return (
                        <td className="px-6 py-4">
                            {getQuestion(field)}
                        </td>
                    )
                })
                : undefined}

        </tr>

    )
}

// {call.dynamic_values &&
//     <td className="px-2 py-4 w-1/4">
//         <div class="w-full h-40 overflow-y-auto flex flex-col justify-center items-end py-4">
//             {call.dynamic_values.map((item, index) => {
//                 return (
//                     <p className="text-right" key={index}>{item.question} ? {item.value}</p>
//                 )
//             })}
//         </div>
//     </td>
// }