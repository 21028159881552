import { Routes, Route, Navigate } from "react-router-dom";
import Login from "./pages/Login";
import CallBot from "./pages/CallBot";
import UseCases from "./pages/UseCases";
import UserManagement from "./pages/UserManagement";
import PendingCalls from "./pages/PendingCalls";
import Admin from "./pages/Admin";

import Batch from "./pages/Batch";
import OrderRequest from "./pages/OrderRequest";
import Reports from "./pages/Reports";
import VoiceAgent from "./pages/VoiceAgent";
import VoiceAgentForm from "./pages/VoiceAgentForm";
import UserManagementOrg from "./pages/UserManagementOrg";
import Dashboard from "./pages/Dashboard";
import Dashboard2 from './pages/dashboard/Dashboard2'
import TestingList from "./pages/TestingList";
import "./App.css";
import Error from "./pages/Error";
import { useContext } from "react";
import { AuthContext } from "./store/auth_context";
import Unauthorized from "./pages/Unauthorized";
import Report from "./pages/Report";
import CancelledCalls from "./pages/CancelledCalls";
import Profile from "./pages/Profile";

import SucceedCalls from "./pages/SucceedCalls";
import Organizations from "./pages/admin/organizations/Organizations";
import AddOrganization from "./pages/admin/organizations/AddOrganization";
import Calls from "./pages/calls/Calls";
import Settings from "./pages/settings/Settings";
import UserManagement2 from "./pages/users/management/UserManagement2";
import Temp from "./pages/Temp";




function App() {
  const { profile } = useContext(AuthContext)

  return (
    <>
      <Routes>
        <Route path="/" element={<Login />}></Route>
        <Route path="/dashboard" element={profile && profile.role === "tester" ? <Navigate to="/unauthorized" /> : <Dashboard />}></Route>
        <Route path="/reports" element={profile && profile.role === "tester" ? <Navigate to="/unauthorized" /> : <Reports />}></Route>
        <Route path="/reports/:id" element={profile && profile.role === "tester" ? <Navigate to="/unauthorized" /> : <Report />}></Route>

        <Route path="/pending_calls" element={<PendingCalls />}></Route>
        <Route path="/succeed_calls" element={<SucceedCalls />}></Route>
        <Route path="/cancelled_calls" element={<CancelledCalls />}></Route>
        <Route path="/settings" element={profile && profile.role !== "administrator" ? <Navigate to="/unauthorized" /> : <Settings />}></Route>
        <Route path="/batch" element={profile && profile.role === "tester" ? <Navigate to="/unauthorized" /> : <Batch />}></Route>
        <Route path="/job_order" element={profile && profile.role === "tester" ? <Navigate to="/unauthorized" /> : <OrderRequest />}></Route>
        <Route path="/voice_agents" element={<VoiceAgent />}></Route>
        <Route path="/create_voice_agent" element={<VoiceAgentForm />}></Route>
        <Route path="/use_cases" element={profile && profile.role === "monitor" ? <Navigate to="/unauthorized" /> : <UseCases />}></Route>
        <Route path="/callbot" element={profile && profile.role === "monitor" ? <Navigate to="/unauthorized" /> : <CallBot />}></Route>
        <Route path="/testing_list" element={profile && profile.role === "monitor" ? <Navigate to="/unauthorized" /> : <TestingList />}></Route>
        <Route path="/users" element={profile && profile.role !== "administrator" ? <Navigate to="/unauthorized" /> : <UserManagementOrg />}></Route>

        <Route path="/users" element={profile && profile.role !== "administrator" ? <Navigate to="/unauthorized" /> : <UserManagementOrg />}></Route>
        <Route path="/user_management" element={<UserManagement />}></Route>
        <Route path="/calls" element={<Calls />}></Route>

        <Route path="/test" element={<Dashboard2 />}></Route>
        <Route path="/test2" element={<UserManagement2 />}></Route>
        <Route path="/test3" element={<Temp />}></Route>

        <Route path="/admin" element={<Admin />}></Route>
        <Route path="/admin/organizations" element={localStorage.getItem('access') === 'zfRJDUsySR' ? <Organizations /> : <Navigate to="/unauthorized" />}></Route>
        <Route path="/admin/add_organization" element={localStorage.getItem('access') === 'zfRJDUsySR' ? <AddOrganization /> : <Navigate to="/unauthorized" />}></Route>

        <Route path="/unauthorized" element={<Unauthorized />}></Route>
        <Route path="*" element={<Error />} />
      </Routes>



    </>
  );
}

export default App;
