const limits = [
    { text: '10', value: 10 },
    { text: '20', value: 20 },
    { text: '50', value: 40 },
    { text: '100', value: 100 },
]

export default function Pagination({ total, limit, paginate }) {
    const pageNumbers = [];

    for (let i = 1; i <= Math.ceil(total / limit); i++) {
        pageNumbers.push(i);
    }

    return (
        <nav className="flex justify-start pt-10">
            <select className="flex-none bg-white px-2 py-2 border rounded-lg font-inter text-center mr-4">
                <option className="bg-white text-center" value={0}>0</option>
                {limits.map((item) => (<option key={item.value} className="">{item.value}</option>))}

            </select>
            <p className="flex-none text-gray-500 self-center">Showing {total} items of {total}</p>


            <ul className="inline-flex space-x-1 grow justify-center">
                {pageNumbers.map(number => (
                    <li key={number}>
                        <button
                            onClick={() => paginate(number)}
                            className="px-3 py-2 ml-0 leading-tight rounded-lg  font-inter bg-white border border-gray-300 hover:bg-gray-100 hover:text-gray-700"
                        >
                            {number}
                        </button>
                    </li>
                ))}
            </ul>
        </nav>
    );
};