import publicInstance from "../../utils/publicInstance";
import {
  API_GET_CALLS_STATS,
  API_GET_CALLS,
  API_PENDING_GET_CALLS,
  API_EDIT_PENDING_CALL
} from "../../constants/apis/dashboard";
import { BASE_URL } from "../../constants/config";
import axios from "axios";

const getAllCallsStatsAPI = async (params, organization_id) => {
  return await publicInstance({
    ...API_GET_CALLS_STATS,
    url: API_GET_CALLS_STATS.url + "/organization/" + organization_id,
    params,
  })
    .then((res) => {
      return res;
    })
    .catch((e) => {
      throw e;
    });
};

const getAllCallsAPI = async (params, organization_id) => {
  return await publicInstance({
    ...API_GET_CALLS,
    url: API_GET_CALLS.url + "/organization/" + organization_id,
    params
  })
    .then((res) => {
      return res;
    })
    .catch((e) => {
      throw e;
    });
};

const getAllPendingCallsAPI = async (organization_id, params) => {
  return await publicInstance({
    ...API_PENDING_GET_CALLS,
    url: API_PENDING_GET_CALLS.url + "/organization/" + organization_id,
    params
  })
    .then((res) => {
      return res;
    })
    .catch((e) => {
      throw e;
    });
};





const updateCallStatusBtId = async (id, data) => {
  return await publicInstance({
    url: `${API_EDIT_PENDING_CALL.url}/${id}`,
    method: API_EDIT_PENDING_CALL.method,
    data,
  })
    .then((res) => {
      return res;
    })
    .catch((e) => {
      throw e;
    });
};


const read = async (id, query) => {
  try {
    const response = await axios.get(`${BASE_URL}/calls/dashboard/organization/${id}?${query}`, {
      headers: {
        'Content-Type': 'application/json',
      }
    })
    return response.data.result
  } catch (error) {
    console.log('error', error)
    throw (error.response.data)
  }
}

const readByDays = async (id, query) => {
  try {
    const response = await axios.get(`${BASE_URL}/calls/dashboard/organization/${id}/days?${query}`, {
      headers: {
        'Content-Type': 'application/json',
      }
    })
    return response.data.result
  } catch (error) {
    console.log('error', error)
    throw (error.response.data)
  }
}

export const ReadInProgressCalls = async (id) => {
  try {
    const response = await axios.get(`${BASE_URL}/calls/status/inprogress/${id}`, { headers: { 'Content-Type': 'application/json' } })
    // console.log('response', response)
    if (response.status === 200) {
      if (response.data.result) {
        if (response.data.result.length > 0) {
          return response.data.result[0].count
        } else {
          return 0
        }
      }
    }
    return 0
  } catch (error) {
    console.log('error', error)
    throw (error.response.data)
  }
}


export const ReadInPendingCalls = async (id) => {
  try {
    const response = await axios.get(`${BASE_URL}/calls/status/inpending/${id}`, { headers: { 'Content-Type': 'application/json' } })
    // console.log('response', response)
    if (response.status === 200) {
      if (response.data.result) {
        return response.data.result
      } else {
        return 0
      }
    }
    return 0
  } catch (error) {
    console.log('error', error)
    throw (error.response.data)
  }
}

export const cancelCalls = async (body) => {
  try {
    const response = await axios.put(`${BASE_URL}/calls/update/cancel`, body, { headers: { 'Content-Type': 'application/json' } })
    console.log('response', response)
    if (response.status === 200) {
      return "cancelCalls Successfully!"
    }
    return response
  } catch (error) {
    console.log('error', error)
    throw (error.response.data)
  }
}

export const executePending = async (body) => {
  try {
    const response = await axios.put(`${BASE_URL}/calls/execute/pending`, body, { headers: { 'Content-Type': 'application/json' } })
    console.log('response', response)
    if (response.status === 200) {
      return "executePending Successfully!"
    }
    return response
  } catch (error) {
    console.log('error', error)
    throw (error.response.data)
  }
}

export { getAllCallsStatsAPI, getAllCallsAPI, getAllPendingCallsAPI, updateCallStatusBtId, read, readByDays };
