export const API_GET_CALLS_STATS = {
  url: "/calls/stats",
  method: "GET",
};

export const API_GET_CALLS = {
  url: "/calls",
  method: "GET",
};

export const API_PENDING_GET_CALLS = {
  url: "/calls/pending",
  method: "GET",
};


export const API_EDIT_PENDING_CALL = {
  url: "/calls/pending",
  method: "PUT",
};
