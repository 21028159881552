import Datepicker from "react-tailwindcss-datepicker"
import SelectInput from "../../inputs/SelectInput"
import TextInput from "../../inputs/TextInput"
import { useState } from "react"



const types = [
    { text: 'Calls', value: 'calls' },
    { text: 'Order Requests', value: 'orderRequests' },
    { text: 'Batches', value: 'batches' },
    { text: 'Conversations', value: 'conversations' }
]

const statues = [
    { "text": "FAILED", "value": "FAILED" },
    { "text": "SUCCEED", "value": "SUCCEED" },
    { "text": "IDLE", "value": "IDLE" },
    { "text": "IN PROGRESS", "value": "IN_PROGRESS" }
]

const dateFilterTypes = [
    { "text": "Range Filter", "value": "range" },
    { "text": "Last N-Days", "value": "lastN" },
]

export default function ReportDialog({ openCreateModal, handleOnCloseCreateModal, handleOnUpdateReport, handleOnCreateReport, data, setData, useCases, range, setRange, mode, dateType, setDateType, voiceAgents, setFields, fields, questionFields, setQuestionFields, systemfields, checkpoints, setCheckpoints }) {



    console.log('data', data)
    const handleChangeExecution = () => {
        setData((previous) => {
            return {
                ...previous,
                isExecution: !previous.isExecution
            }
        })
    };

    const handleOnChipClick = (label) => {
        setData((previous) => {
            return {
                ...previous,
                fields: previous.fields.includes(label)
                    ? previous.fields.filter((item) => item !== label)
                    : [...previous.fields, label]
            }
        })
    };


    const handleOnChipClickSystemFields = (label) => {
        setData((previous) => {
            return {
                ...previous,
                system_fields: previous.system_fields.includes(label)
                    ? previous.system_fields.filter((item) => item !== label)
                    : [...previous.system_fields, label]
            }
        })
    };


    const handleOnChipClickQuestionFields = (label) => {
        setData((previous) => {
            return {
                ...previous,
                questions_fields: previous.questions_fields.includes(label)
                    ? previous.questions_fields.filter((item) => item !== label)
                    : [...previous.questions_fields, label]
            }
        })
    };


    //Check if type of report is calls or not because the other types don't have status (we ensure to avoid fill them)
    const handleOnInputChange = (event) => {
        if (event.target.name === "type" && (event.target.value !== "calls" && event.target.value !== "conversations")) {
            setData({ ...data, 'subStatus': '', 'fields': [], 'checkPoint': '', [event.target.name]: event.target.value })
        } else {
            let usecase = {}
            if (event.target.name === "usecase") {
                const voiceAgent = voiceAgents.find((field) => field._id === event.target.value)
                usecase = voiceAgent
                const keys = []
                const questions = []
                for (const field of Object.values(voiceAgent.fields)) {
                    keys.push(field.label)
                }
                for (const question of Object.values(voiceAgent.custom_questions)) {
                    questions.push(question.question)
                }
                setFields(keys)
                setQuestionFields(questions)
            }

            if (usecase) {
                if (usecase.checkpoints && usecase.checkpoints.length > 0) {
                    setCheckpoints(usecase.checkpoints.map((item) => ({ text: item.label, value: item.id })))
                    console.log('checkpoints', checkpoints)
                }
            }

        }

        setData({ ...data, 'system_fields': [], [event.target.name]: event.target.value })
    }




    //Check if type of report is is range date or last days number (we ensure to avoid fill them both)
    const handleOnDateTypeChange = (event) => {
        if (event.target.value === "range") {
            setDateType(event.target.value)
            setData({ ...data, lastN: '' })
        } else {
            setDateType(event.target.value)
            setData({ ...data, start_date: '', end_date: '' })
        }
    }

    const handleOnDateChange = (value) => {
        setRange(value)
        if (value.startDate) {
            const startDate = new Date(value.startDate)
            if (!isNaN(startDate)) {
                setData((previous) => { return { ...previous, 'start_date': startDate } })
            }
        } else {
            setData((previous) => { return { ...previous, 'start_date': '' } })
        }

        if (value.endDate) {
            const endDate = new Date(value.endDate)
            if (!isNaN(endDate)) {
                setData((previous) => { return { ...previous, 'end_date': endDate } })
            }
        } else {
            setData((previous) => { return { ...previous, 'end_date': '' } })
        }

    }

    if (!openCreateModal) return null

    return (
        <>
            <div className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
                <div className="relative w-1/2 my-6 mx-auto max-w-6xl">
                    <div className="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
                        <div className="flex items-start justify-between p-5 border-b border-solid border-blueGray-200 rounded-t">
                            <h4 className="text-xl font-semibold font-inter">Create Report</h4>
                            <button onClick={() => handleOnCloseCreateModal()} className="p-1 ml-auto  border-0 text-black  float-right text-3xl leading-none font-semibold outline-none focus:outline-none">
                                <span className=" text-black  h-6 w-6 text-2xl block outline-none focus:outline-none">×</span>
                            </button>
                        </div>

                        <div className=" p-6 flex-auto">
                            <div className="w-full mb-4">
                                <h5 className="text-xl font-semibold font-inter mb-4">Report Details</h5>
                                <div className="flex space-x-4">
                                    <TextInput label="Name" type="text" id="name" name="name" placeholder="Report Name" onChange={handleOnInputChange} value={data.name} />
                                    <SelectInput label='Type' title='Type' options={types} id="type" name="type" onChange={handleOnInputChange} value={data.type} />
                                    {(data.type === "calls" || data.type === "conversations") &&
                                        <div className="flex justify-between items-center px-4">
                                            <label htmlFor="isExecution" className="ml-2 text-gray-700 font-inter my-0 ">
                                                Is Execution?
                                            </label>
                                            <label className="inline-flex items-center cursor-pointer ml-3">
                                                <p className="inline mr-3"><span className={`${data.isExecution && "text-primary"} font-normal m-1`}>Yes</span>/<span className={`${!data.isExecution && "text-primary"} font-normal m-1`}>No</span></p>
                                                <input type="checkbox" name='isExecution' id="isExecution" checked={data.isExecution} onChange={handleChangeExecution} className="shadow-sm w-4 h-4  border-gray-300 rounded focus:ring-primary focus:ring-2 font-inter sr-only peer" />
                                                <div className="relative w-9 h-5 bg-gray-200 peer-focus:outline-none peer-focus:ring-1 peer-focus:ring-primary rounded-full peer  peer-checked:after:translate-x-full rtl:peer-checked:after:-translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:start-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-4 after:w-4 after:transition-all dark:border-gray-600 peer-checked:bg-primary"></div>
                                            </label>
                                        </div>
                                    }
                                </div>
                                <div className="mb-3">
                                    <label className="block text-sm font-medium text-gray-700 mb-1 font-inter">Description</label>
                                    <textarea type="text" id="description" name="description" placeholder="Description Here..." onChange={handleOnInputChange} value={data.description}
                                        className="w-full  px-4 py-2 mt-1 rounded-lg border border-gray-300 focus:ring-1  focus:ring-gray-400 focus:border-gray-400 shadow-sm bg-input placeholder-gray-400 text-gray-900 focus:outline-none transition duration-300 ease-in-out h-32" >
                                    </textarea>
                                </div>
                            </div>


                            <div className="w-full mb-5">
                                <h5 className="text-xl font-semibold font-inter mb-4">Choose Filters</h5>
                                <div className="grid grid-cols-2 gap-4">
                                    <SelectInput label='Voice Agent' title='Voice Agent' id="usecase" name="usecase" options={useCases} onChange={handleOnInputChange} value={data.usecase} />
                                    {(data.type === "calls") &&
                                        <SelectInput label='Status' title='All' options={statues} disabledTitle={false} id="subStatus" name="subStatus" onChange={handleOnInputChange} value={data.subStatus} />
                                    }
                                    {(data.type === "conversations") &&
                                        <SelectInput label='Status' title='All' options={checkpoints} disabledTitle={false} id="checkPoint" name="checkPoint" onChange={handleOnInputChange} value={data.checkPoint} />
                                    }
                                </div>

                                <SelectInput label='Date Filter' title='Date Filter' options={dateFilterTypes} id="dateFilter" value={dateType} onChange={handleOnDateTypeChange} />
                                {dateType === "lastN" ?
                                    <TextInput type="number" id="lastN" name="lastN" placeholder="Last N-Days" onChange={handleOnInputChange} value={data.lastN} />
                                    :
                                    <Datepicker separator="to" showFooter={true} value={range} onChange={(value) => handleOnDateChange(value)}
                                        placeholder="Date Range Filter"
                                        containerClassName="relative dark:bg-white"
                                        inputClassName="  h-10 w-full px-5  rounded-r-lg  font-inter   shadow-sm dark:bg-select  bg-select text-md font-light focus:outline-none focus:ring-2 focus:ring-primary "
                                        toggleClassName="absolute bg-primary rounded-r-lg text-white right-0 h-full px-3 text-gray-400 ">
                                    </Datepicker>
                                }
                            </div>

                            {(data.type === "calls" || data.type === "conversations") && data.usecase ?

                                <>

                                    <div className="w-full mb-4">
                                        <h5 className="text-xl font-semibold font-inter mb-4">Select Fields</h5>
                                        <div className="flex space-x-2 flex-wrap space-y-1 ">
                                            {fields.map((label) => (
                                                <CheckboxChip
                                                    key={label}
                                                    label={label}
                                                    isSelected={data.fields.length > 0 ? data.fields.includes(label) : false}
                                                    onClick={() => handleOnChipClick(label)}
                                                />
                                            ))}
                                        </div>
                                    </div>

                                    <div className="w-full mb-4">
                                        <h5 className="text-xl font-semibold font-inter mb-4">Questions Fields</h5>
                                        <div className="flex space-x-2 flex-wrap space-y-1 ">
                                            {questionFields.map((label) => (
                                                <CheckboxChip
                                                    key={label}
                                                    label={label}
                                                    isSelected={data.questions_fields.length > 0 ? data.questions_fields.includes(label) : false}
                                                    onClick={() => handleOnChipClickQuestionFields(label)}
                                                />
                                            ))}
                                        </div>
                                    </div>
                                </>

                                :
                                undefined
                            }

                            <div className="w-full mb-4">
                                <h5 className="text-xl font-semibold font-inter mb-4">Select System Fields</h5>
                                <div className="flex space-x-2 flex-wrap space-y-2 ">
                                    {(data.type === "calls" || data.type === "conversations") ?
                                        systemfields.calls_system_fields.map((label) => (
                                            <CheckboxChip
                                                key={label}
                                                label={label}
                                                isSelected={data.system_fields.length > 0 ? data.system_fields.includes(label) : false}
                                                onClick={() => handleOnChipClickSystemFields(label)}
                                            />
                                        ))
                                        : data.type === "batches" ?
                                            systemfields.batches_system_fields.map((label) => (
                                                <CheckboxChip
                                                    key={label}
                                                    label={label}
                                                    isSelected={data.system_fields.length > 0 ? data.system_fields.includes(label) : false}
                                                    onClick={() => handleOnChipClickSystemFields(label)}
                                                />
                                            )) :
                                            data.type === "orderRequests" ? systemfields.orderRequests_system_fields.map((label) => (
                                                <CheckboxChip
                                                    key={label}
                                                    label={label}
                                                    isSelected={data.system_fields.length > 0 ? data.system_fields.includes(label) : false}
                                                    onClick={() => handleOnChipClickSystemFields(label)}
                                                />
                                            )) : undefined}
                                </div>
                            </div>

                        </div>



                        <div className="flex items-center justify-end p-6 border-t border-solid border-blueGray-200 rounded-b">
                            {mode === "update" ?
                                <button onClick={() => handleOnUpdateReport()} className="bg-emerald-500 text-white active:bg-emerald-600 font-bold uppercase text-sm px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150 rounded-lg" type="button">Save</button>
                                :
                                <button onClick={() => handleOnCreateReport()} className="bg-emerald-500 text-white active:bg-emerald-600 font-bold uppercase text-sm px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150 rounded-lg" type="button">Create</button>
                            }
                        </div>

                    </div>
                </div>
            </div>
            <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
        </>
    )
}


const CheckboxChip = ({ label, isSelected, onClick }) => (
    <div
        className={`px-4 py-1 rounded-full cursor-pointer m-1 
                  ${isSelected ? 'bg-primary text-white' : 'bg-gray-200 text-gray-800'}`}
        onClick={onClick}
    >
        {label}
    </div>
);