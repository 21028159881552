import { TrashIcon, PlusCircleIcon } from "@heroicons/react/24/outline";
import TextInput from "../../inputs/TextInput";
import SelectInput from "../../inputs/SelectInput";


const types = [
    { text: 'Yes/No', value: 'yes_no' },
    { text: 'Text', value: 'text' }
]

const confimations = [
    { text: "Yes", value: "yes" },
    { text: "No", value: "no" }
]

function MultiQuestions({ inputsData, setinputsData }) {


    const handleInputChange = (index, fieldName, value) => {
        const newData = [...inputsData];
        newData[index][fieldName] = value;
        setinputsData(newData);
    };

    const handleAddInput = (e) => {
        e.preventDefault();
        setinputsData([
            ...inputsData,
            {
                question: "",
                expected_answer: "",
                type: ""
            },
        ]);
    };

    const handleRemoveInput = (e, index) => {
        e.preventDefault();
        const newData = [...inputsData];
        newData.splice(index, 1);
        setinputsData(newData);
    };



    return (
        <>
            {inputsData.map((data, index) => (
                <div key={index} className="flex space-x-4 mb-5">
                    <TextInput label={`Question ${index + 1}`} type="text" placeholder="Question" value={data.question} onChange={(event) => handleInputChange(index, "question", event.target.value)} />

                    <div className="grow flex space-x-4">
                        <SelectInput label='Type' title="Type" options={types} id="type" name="type" value={data.type} onChange={(event) => handleInputChange(index, "type", event.target.value)} />


                        {
                            data.type ? data.type === "text" ?
                                <div className="grow">
                                    <TextInput label='Expected Answer' type="text" placeholder="Expected Answer" value={data.expected_answer} onChange={(event) => handleInputChange(index, "expected_answer", event.target.value)} />
                                </div>
                                :
                                <SelectInput label='Expected Answer' title="Expected Answer" options={confimations} value={data.expected_answer} onChange={(event) => handleInputChange(index, "expected_answer", event.target.value)} />
                                :
                                undefined
                        }


                    </div>





                    <div className="flex space-x-2 my-auto pt-2">
                        <TrashIcon
                            onClick={(e) => handleRemoveInput(e, index)}
                            className="h-5 w-5 text-red-700 cursor-pointer"
                        />

                        <PlusCircleIcon
                            onClick={(e) => handleAddInput(e)}
                            className="h-5 w-5 my-auto text-green-700 cursor-pointer"
                        />
                    </div>
                </div>
            ))}
        </>
    );
}

export default MultiQuestions;
