import { Link } from "react-router-dom"


export default function SidebarGroupItems({ items, location }) {

    return (
        <ul id="dropdown-example" className="block py-2 space-y-2 ">
            {items.map((item) => {
                return (
                    <li key={item.title}>
                        <Link to={item.link} className={`flex items-center w-full p-2 text-gray-900 transition duration-75 rounded-lg pl-11 group text-xs  font-inter ${location.pathname === item.link ? 'font-bold' : undefined}`}>{item.title}</Link>
                    </li>
                )
            })}
        </ul>
    )
}
