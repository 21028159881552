import publicInstance from "../../utils/publicInstance";
import {
  API_GET_USE_CASES,
  API_EDIT_USE_CASE,
  API_CREATE_USE_CASES,
  API_EDIT_SYSTEM_CONFIG,
} from "../../constants/apis/useCases";
import axios from "axios";
import { BASE_URL } from "../../constants/config";



const getAllUseCasesAPI = async (organization_id) => {
  return await publicInstance(
    {
      ...API_GET_USE_CASES,
      url: `${API_GET_USE_CASES.url}/organization/${organization_id}`,
    }
  )
    .then((res) => {
      return res;
    })
    .catch((e) => {
      throw e;
    });
};

const getUseCaseByIdAPI = async (id) => {
  return await publicInstance({
    ...API_GET_USE_CASES,
    url: `${API_GET_USE_CASES.url}/${id}`,
  })
    .then((res) => {
      return res;
    })
    .catch((e) => {
      throw e;
    });
};

const editUseCaseAPI = async (id, data) => {
  return await publicInstance({
    url: `${API_EDIT_USE_CASE.url}/${id}`,
    method: API_EDIT_USE_CASE.method,
    data,
  })
    .then((res) => {
      return res;
    })
    .catch((e) => {
      throw e;
    });
};

const createUseCaseAPI = async (data) => {
  return await publicInstance({
    ...API_CREATE_USE_CASES,
    data,
  })
    .then((res) => {
      return res;
    })
    .catch((e) => {
      throw e;
    });
};

const editSystemConfigAPI = async (organization_id, data) => {
  return await publicInstance({
    ...API_EDIT_SYSTEM_CONFIG,
    url: API_EDIT_SYSTEM_CONFIG.url + "/organization/" + organization_id,
    data,
  })
    .then((res) => {
      return res;
    })
    .catch((e) => {
      throw e;
    });
};


const deleteUseCase = async (id) => {
  try {
    const response = await axios.delete(`${BASE_URL}/use_cases/delete/${id}`, { headers: { 'Content-Type': 'application/json' } })
    console.log('response', response)
    if (response.status === 204) {
      return "Voice Agent Removed Successfully!"
    }
    return response
  } catch (error) {
    console.log('error', error)
    throw (error.response.data)
  }
}

export {
  getAllUseCasesAPI,
  editUseCaseAPI,
  createUseCaseAPI,
  getUseCaseByIdAPI,
  editSystemConfigAPI,
  deleteUseCase
};
