import axios, { AxiosError } from "axios"
import { BASE_URL } from "../../constants/config"


export const read = async (query) => {
    try {
        const response = await axios.get(`${BASE_URL}/calls/report?${query}`, {
            headers: {
                'Content-Type': 'application/json',
            }
        })
        return response
    } catch (error) {
        console.log('error', error)
        if (error.code && error.code === "ERR_NETWORK") {
            throw (new AxiosError(`Internal Server Error (${error.message && error.message})`, 500))
        }
        throw (error)
    }
}


export const ReadByOrgId = async (id, query) => {
    try {
        const response = await axios.get(`${BASE_URL}/calls/report/organization/${id}?${query}`, {
            headers: { 'Content-Type': 'application/json', }
        })
        console.log('response', response)
        if (response.status === 200) {
            return response.data
        }
        return response
    } catch (error) {
        console.log('error', error)
        throw (error.response.data)
    }
}

