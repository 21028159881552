import axios from "axios"
import { BASE_URL } from "../../constants/config"

export const ReadByOrgId = async (id) => {
    try {
        const response = await axios.get(`${BASE_URL}/reports/organization/${id}`, { headers: { 'Content-Type': 'application/json' } })
        console.log('response', response)
        if (response.status === 200) {
            return response.data.data
        }
        return null
    } catch (error) {
        console.log('error', error)
        throw (error.response.data)
    }
}


export const ReadOne = async (id) => {
    try {
        const response = await axios.get(`${BASE_URL}/reports/read/${id}`, { headers: { 'Content-Type': 'application/json' } })
        console.log('response', response)
        if (response.status === 200) {
            return response.data.data
        }
        return response
    } catch (error) {
        console.log('error', error)
        throw (error.response.data)
    }
}

export const create = async (data) => {
    try {
        const response = await axios.post(`${BASE_URL}/reports`, data, { headers: { 'Content-Type': 'application/json' } })
        console.log('response', response)
        if (response.status === 201) {
            return response.data.data
        }
        return response
    } catch (error) {
        console.log('error', error)
        throw (error.response.data)
    }
}

export const deleteReport = async (id) => {
    try {
        const response = await axios.delete(`${BASE_URL}/reports/delete/${id}`, { headers: { 'Content-Type': 'application/json' } })
        console.log('response', response)
        if (response.status === 204) {
            return "Report Removed Successfully!"
        }
        return response
    } catch (error) {
        console.log('error', error)
        throw (error.response.data)
    }
}

export const updateReport = async (id, body) => {
    try {
        const response = await axios.put(`${BASE_URL}/reports/update/${id}`, body, { headers: { 'Content-Type': 'application/json' } })
        console.log('response', response)
        if (response.status === 200) {
            return "Report updated Successfully!"
        }
        return response
    } catch (error) {
        console.log('error', error)
        throw (error.response.data)
    }
}