


export default function TextInput({ label, ...props }) {
    return (
        <div className="mb-3">
            {label &&
                <label className="block text-sm font-medium text-gray-700 mb-1 font-inter">
                    {label}
                </label>
            }
            <input
                type="text"
                className="w-full h-11 px-4 py-2 mt-1 rounded-lg border border-gray-300 
                     focus:ring-1  focus:ring-gray-400 focus:border-gray-400
                     shadow-sm bg-input placeholder-gray-400 
                     text-gray-900 focus:outline-none transition duration-300 ease-in-out"
                {...props}

            />
        </div>
    )
}