import { Link } from "react-router-dom"


export default function SidebarItem({ title, icon, link, location }) {

    return (
        <li>
            <Link to={link} className="flex items-center w-full p-3 text-sm text-gray-900 font-normal rounded-lg group bg-active">
                <img src={icon ? icon : '/icons/Category.png'} alt="Headphone Icon" className="w-5 h-5 mr-2" />
                <span className={`flex-1 ms-3 text-left rtl:text-right whitespace-nowrap font-inter ${location.pathname === link ? 'font-semibold' : undefined}`}>{title}</span>
            </Link>
        </li>
    )
}