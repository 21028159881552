import Pagination from "../Pagination"




export default function Table({ header, data, filters, paginate }) {
    console.log('header', header)
    return (
        <div className="relative overflow-x-auto mb-4 p-8 rounded-lg border shadow-sm">
            <table className="min-w-full text-sm text-left rtl:text-right text-gray-900">
                <TableHeader header={header} />
                <TableBody header={header} data={data} />
            </table>


            <Pagination total={data.total} limit={filters.limit} paginate={paginate} />

        </div>
    )
}


const TableHeader = ({ header }) => {

    return (
        <thead className="text-xs text-primary uppercase   ">
            <tr className="border-b border-gray-400   font-inter">
                <th scope="col" className="font-semibold px-6 py-3 w-10" > #</th>
                {header.map((item, index) => {
                    return (<td key={index} className="px-6 py-3 " >{item}</td>
                    )
                })}
            </tr>
        </thead>
    )
}

const TableBody = ({ header, data }) => {

    return (
        <tbody>
            {data.data.map((row, rowIndex) => {
                return (
                    <tr key={rowIndex} className={`${rowIndex >= data.length - 1 ? undefined : 'border-b border-gray-200'} font-inter h-20`}>
                        <th scope="col" className="font-semibold px-6 py-3 w-10" >{rowIndex + 1}</th>
                        {header.map((col, colIndex) => (
                            <td key={colIndex} className="px-6 py-3" >{row[col]}</td>
                        ))}
                    </tr>
                )
            })}
        </tbody>
    )
}

