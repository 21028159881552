import { useContext, useEffect, useState } from "react"
import { constructQueryParams } from "../utils/encodeURI"
import { ReadByOrgId as callsReport } from "../services/apis/reports";
import { report as batchesReport } from "../services/apis/batches"
import { report as orderRequestsReport } from '../services/apis/orderRequest'
import { AuthContext } from "../store/auth_context";
import { ReadOne } from "../services/apis/custom_reports";
import CallsTable from "../components/reports/CallsTable";
import BatchesTable from "../components/reports/BatchesTable";
import OrderRequestsTable from "../components/reports/OrderRequestsTable";
import { saveAs } from 'file-saver';
import Papa from 'papaparse';
import NoData from "../components/NoData";
import Layout from "../components/Layout";
import { useParams } from "react-router-dom";
import Loading from "../components/Loading";
import Error from "../components/Error";
import TextInput from "../components/inputs/TextInput";
import { ReadConversationReport } from "../services/apis/calls";
import ConversationsTable from "../components/reports/ConversationsTable";
import Table from "../components/tables/Table";

const formatDate = (custom) => {
    const date = new Date(custom)
    const day = String(date.getDate()).padStart(2, '0');
    const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are 0-indexed in JS
    const year = date.getFullYear();
    return `${day}-${month}-${year}`
}

export default function CustomReport() {
    const params = useParams()
    const { profile } = useContext(AuthContext)
    const [filters, setFilters] = useState({ usecase: '', status: '', subStatus: '', checkPoint: '', gte: '', lte: '', lastN: '', isExecution: false, limit: 10, page: 1 })

    const [type, setType] = useState('calls')
    const [fields, setFields] = useState([])
    const [systemFields, setSystemFields] = useState([])
    const [questionFields, setQuestionFields] = useState([])
    const [calls, setCalls] = useState({ data: [], total: 0, count: 0 })
    const [batches, setBatches] = useState({ data: [], total: 0, count: 0 })
    const [orderRequests, setOrderRequests] = useState({ data: [], total: 0, count: 0 })
    const [conversations, setConversations] = useState({ data: [], total: 0, count: 0 })

    const [loading, setLoading] = useState(false)
    const [error, setError] = useState('')


    const paginate = (page) => {
        setFilters((previous) => { return { ...previous, page: page } })
    }

    //handle columns for array of objects
    const handleExportCSV = () => {
        const csv = Papa.unparse(type === "calls" ? calls.data : type === "batches" ? batches.data : orderRequests.data);
        const blob = new Blob([csv], { type: 'text/csv;charset=utf-8;' });
        saveAs(blob, 'data.csv');
    };

    useEffect(() => {
        setLoading(true)
        ReadOne(params.id).then((response) => {
            setFilters({
                checkPoint: response.checkPoint ? response.checkPoint : '',
                usecase: response.usecase ? response.usecase._id : '',
                subStatus: response.subStatus || '',
                gte: response.start_date ? formatDate(response.start_date) : '',
                lte: response.end_date ? formatDate(response.end_date) : '',
                lastN: response.lastN || '',
                isExecution: response.isExecution,
                limit: 10, page: 1
            })
            setQuestionFields(response.questions_fields || [])
            setSystemFields(response.system_fields || [])
            setFields(response.fields || [])
            setType(response.type)
            setLoading(false)
        }).catch((error) => {
            console.log('error', error)
            setLoading(false)
            setError(error.message)
        })
    }, [params.id])


    const preprocessingCalls = (calls) => {
        const updatedCalls = calls.map(call => {
            const { target, Fname, ...otherMetadata } = call.metadata || {};
            const questions = {};
            call.dynamic_values.forEach(({ question, value }) => {
                questions[question] = value;
            });
            return {
                ...call,
                target,
                Fname,
                ...otherMetadata,
                ...questions
            };
        });
        console.log('updatedCalls', updatedCalls)
        return updatedCalls
    }


    useEffect(() => {
        setLoading(true)
        const query = constructQueryParams(filters);
        if (type === "calls") {
            callsReport(profile.organization._id, query).then((response) => {
                setLoading(false)
                setCalls((previous) => {
                    return ({
                        ...previous,
                        data: preprocessingCalls(response.calls),
                        total: response.total,
                        count: response.count
                    })
                })
                console.log('data', response)
            }).catch((error) => {
                setLoading(false)
                setError(error.message)
            })
        }
        else if (type === "batches") {
            batchesReport(profile.organization._id, query).then((response) => {
                setLoading(false)
                setBatches((previous) => {
                    return ({
                        ...previous,
                        data: response.data.batches,
                        total: response.data.total,
                        count: response.data.count
                    })
                })
            }).catch((error) => {
                setLoading(false)
                setError(error.message)
            })
        } else if (type === "orderRequests") {
            orderRequestsReport(profile.organization._id, query).then((response) => {
                setLoading(false)
                setOrderRequests((previous) => {
                    return ({
                        ...previous,
                        data: response.data.orderRequests,
                        total: response.data.total,
                        count: response.data.count
                    })
                })
            }).catch((error) => {
                setLoading(false)
                setError(error.message)
            })
        } else if (type === "conversations") {
            ReadConversationReport(profile.organization._id, query).then((response) => {
                console.log('conversation', response)
                setLoading(false)
                setConversations((previous) => {
                    return ({
                        ...previous,
                        data: preprocessingCalls(response.calls),
                        total: response.total,
                        count: response.count
                    })
                })
                console.log('data', response)
            }).catch((error) => {
                setLoading(false)
                setError(error.message)
            })
        }

    }, [filters, type, profile.organization._id])


    if (loading) {
        return (
            <Layout title='Reports'>
                <Loading />
            </Layout>
        )
    }

    if (error) {
        return (
            <Layout title='Reports'>
                <Error />
            </Layout>
        )
    }

    return (
        <Layout title={`Reports`}>

            <div className="flex mb-5">
                <div className="flex space-x-4 justify-start ">
                    {type && <TextInput label="Type" value={type} disabled />}
                    {filters.usecase && <TextInput label="Voice Agent" value={calls.data.length > 0 ? calls.data[0].use_case_id.name : filters.usecase} disabled />}

                    {type === "conversations" ?
                        filters.checkPoint && <TextInput label="Status" value={filters.checkPoint} disabled />
                        :
                        filters.subStatus && <TextInput label="Status" value={filters.subStatus} disabled />
                    }

                    {filters.lastN && <TextInput label="last N-Days" value={filters.lastN} disabled />}
                    {filters.gte && <TextInput label="Date Range" value={`${filters.gte} - ${filters.lte}`} disabled />}
                </div>
                <button className="self-center  bg-primary h-10 w-20 rounded-lg text-white shadow-sm mt-4 ml-auto" onClick={handleExportCSV}>Export</button>
            </div>

            {type === 'batches' ? batches.data.length > 0 ? <BatchesTable batches={batches} filters={filters} paginate={paginate}></BatchesTable> : <NoData /> : undefined}
            {type === 'orderRequests' ? orderRequests.data.length > 0 ? <OrderRequestsTable orderRequests={orderRequests} filters={filters} paginate={paginate}></OrderRequestsTable> : <NoData /> : undefined}

            {type === 'conversations' ? calls.data.length > 0 ? <Table data={conversations} filters={filters} paginate={paginate} header={[...fields, ...systemFields, ...questionFields]}></Table> : <NoData /> : undefined}
            {type === 'calls' ? calls.data.length > 0 ? <Table data={calls} filters={filters} paginate={paginate} header={[...fields, ...systemFields, ...questionFields]}></Table> : <NoData /> : undefined}

            {/* {type === 'calls' ? calls.data.length > 0 ? <CallsTable calls={calls} filters={filters} paginate={paginate} fields={fields} systemFields={systemFields} questionFields={questionFields}></CallsTable> : <NoData /> : undefined} */}
            {/* {type === 'conversations' ? conversations.data.length > 0 ? <ConversationsTable conversations={conversations} filters={filters} paginate={paginate} fields={fields} systemFields={systemFields} questionFields={questionFields}></ConversationsTable> : <NoData /> : undefined} */}


        </Layout>

    )
}