import moment from "moment"
import Pagination from "../Pagination"



export default function BatchesTable({ batches, filters, paginate }) {
    return (
        <div className="relative overflow-x-auto mb-4 bg-white my-8 px-16 py-8 rounded-lg border shadow-lg">
            <table className="w-full text-sm text-left rtl:text-right text-gray-900">
                <thead className="text-xs text-primary uppercase bg-white  ">
                    <tr className="border-b border-gray-700 text-center font-inter">
                        <th scope="col" className="px-6 py-3" >
                            Name
                        </th>
                        <th scope="col" className="px-6 py-3" >
                            Voice Agent
                        </th>
                        <th scope="col" className="px-6 py-3" >
                            Description
                        </th>
                        <th scope="col" className="px-6 py-3" >
                            Date
                        </th>
                    </tr>
                </thead>
                <tbody>
                    {batches.data.map((batch) => {
                        return (<TableItem key={batch._id} batch={batch}></TableItem>)
                    })}
                </tbody>
            </table>
            <Pagination total={batches.total} limit={filters.limit} paginate={paginate} />
        </div>
    )
}

const TableItem = ({ batch }) => {

    return (

        <tr className="bg-white border-b border-gray-400 text-center font-inter">
            <th scope="row" className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap ">
                {batch.name}
            </th>
            <td className="px-6 py-4 text-gray-600">
                {batch.usecase.name}
            </td>
            <td className="px-6 py-4 text-gray-600">
                {batch.description}
            </td>
            <td className="px-6 py-4">
                {moment(batch.createdAt).format('DD-MM-YYYY')}
            </td>
        </tr>

    )
}