import { useContext, useEffect, useState } from "react";
import Layout from "../components/Layout";
import MultiTextInput from "../components/module/configManager/MultiTextInput";
import MultiQuestionsInput from "../components/module/configManager/MultiQuestionsInput";
import { useLocation, useNavigate } from "react-router-dom";
import { createUseCaseAPI, editUseCaseAPI, getUseCaseByIdAPI } from "../services/apis/useCases";
import { Slide, toast } from "react-toastify";
import Loading from "../components/Loading";
import { AuthContext } from "../store/auth_context";
import TextInput from "../components/inputs/TextInput";
import SelectInput from "../components/inputs/SelectInput";
import { ReadByOrgId } from "../services/apis/users";
import { readOne } from "../services/apis/organization";
import MultiQuestions from "../components/module/configManager/MultiQuestions";
import MultiQuestionCheckPoints from "../components/module/configManager/MultiQuestionCheckPoints";




const types = [
    { text: 'Campaign', value: 'CAMPAIGN' },
    { text: 'Event', value: 'EVENT' },
]

const priorities = [
    { text: 'Low', value: 0 },
    { text: 'Medium', value: 1 },
    { text: 'High', value: 2 },
]

export default function VoiceAgentForm() {
    const { profile } = useContext(AuthContext)
    const navigate = useNavigate();
    const location = useLocation();
    const useCaseId = location?.state?.id;
    const organization_id = profile.organization._id
    const [users, setUsers] = useState([])
    const [departements, setDepartments] = useState([])
    const [botIds, setBotIds] = useState([])

    const [loading, setLoading] = useState(false)
    const [useCase, setuseCase] = useState({
        id: "",
        name: "",
        description: "",
        departement: "",
        botId: "",
        arabic_caller: "",
        english_caller: "",
        type: "",
        priority: '',
        is_production: true,
        bot_max_cc: "",
        level: "",
        botEndpoint: "",
        user: "",
        jwt_token: "",
        client_id: "",
        client_secret: ""
    });

    const [inputsData, setinputsData] = useState([
        {
            label: "",
            name: "",
            placeholder: "",
            type: "",
        },
    ]);

    const [customQuestions2, setCustomQuestions2] = useState([
        {
            question: "",
            expected_answer: "",
            type: ""
        }
    ])

    const [checkpoints, setCheckpoints] = useState([
        {
            label: "",
            id: "",
        }
    ])
    // const [customQuestions, setCustomQuestions] = useState([""]);

    const handleOnInputChange = (event) => {

        if (event.target.name === 'is_production') {
            setuseCase({ ...useCase, [event.target.name]: !useCase.is_production })
        } else {
            setuseCase({ ...useCase, [event.target.name]: event.target.value })
        }

    }

    const handleCsvHeader = () => {
        return inputsData.length > 0 ? inputsData.map((item) => ({ label: item.label, key: item.name })) : []
    }

    async function createNewUseCase() {
        let data = {
            ...useCase,
            fields: inputsData,
            headers: handleCsvHeader(),
            organization: organization_id,
            custom_questions: customQuestions2,
            checkpoints: checkpoints
        };

        console.log('lastbefore', data)

        setLoading(true)
        createUseCaseAPI(data)
            .then((res) => {
                toast.success("Voice Agent Successfully Created!", {
                    transition: Slide,
                    position: "bottom-right",
                    autoClose: 1500,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });
                setTimeout(() => {
                    setLoading(false)
                    navigate("/voice_agents");
                }, 1800);
            })
            .catch((error) => { toast.error(error.messsage, { transition: Slide }) });
    }

    async function getUseCaseById() {
        getUseCaseByIdAPI(useCaseId)
            .then((res) => {

                setuseCase(res.data.usecase);
                setinputsData(res.data.usecase.fields);
                setCustomQuestions2(res.data.usecase.custom_questions);
                setCheckpoints(res.data.usecase.checkpoints);
                // if (res.data.usecase.checkpoints.length > 0)

                // else
                //     setCheckpoints([" "]);

            })
            .catch((err) => console.log(err));
    }

    function editUseCase() {
        let data = {
            ...useCase,
            fields: inputsData,
            headers: handleCsvHeader(),
            custom_questions: customQuestions2,
            checkpoints: checkpoints
        };
        setLoading(true)
        editUseCaseAPI(useCaseId, data)
            .then((res) => {
                toast.success("Voice Agent Successfully Edited!", {
                    transition: Slide,
                    position: "bottom-right",
                    autoClose: 1500,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });

                setTimeout(() => {

                    setLoading(false)
                    navigate("/voice_agents");
                }, 1800);
            })
            .catch((err) => console.log(err));
    }

    useEffect(() => {
        if (useCaseId) {
            getUseCaseById();
        }
    }, [useCaseId]);

    useEffect(() => {
        ReadByOrgId(profile.organization._id).then((response) => {
            if (response.length > 0) {
                setUsers(response.map((item) => ({ "text": item.name, "value": item._id })));
            }
        }).catch((error) => {
            toast.error(error.messsage, { transition: Slide })
        });
    }, [profile.organization._id]);

    useEffect(() => {
        readOne(profile.organization._id).then((response) => {
            console.log('profile', response)
            if (response.departments && response.departments.length > 0) {
                setDepartments(response.departments.map((departement) => {
                    return { text: departement, value: departement }
                }))
            }

            if (response.botIds && response.botIds.length > 0) {
                setBotIds(response.botIds.map((botId) => {
                    return { text: botId, value: botId }
                }))
            }

        }).catch((error) => {
            console.log('error', error)
        })
    }, [profile.organization._id])


    return (
        <Layout title='Create Voice Agent'>


            <div className="bg-white p-8 mb-4  rounded-lg shadow-lg">
                <h1 className="text-xl font-semibold text-secondary font-inter mb-2">
                    1.0 Basic Info
                </h1>
                <p className="text-sm leading-6 text-gray-400 font-inter mb-4 w-1/2">
                    Configure Your Bot, Set up your voice agent by providing the basic information, including the agent's name and the bot ID...
                </p>

                <div className="grid grid-cols-1 md:grid-cols-2 gap-12">
                    <div>
                        <TextInput label="Id" type="text" id="id" name="id" value={useCase.id} onChange={handleOnInputChange} placeholder="id" required />
                        <TextInput label="Name" type="text" id="name" name="name" value={useCase.name} onChange={handleOnInputChange} placeholder="name" required />
                        <SelectInput label="Bot Id" title="Bot Id" options={botIds} id="botId" name="botId" value={useCase.botId} onChange={handleOnInputChange} />
                        <TextInput label="Bot Endpoint" type="text" id="botEndpoint" value={useCase.botEndpoint} name="botEndpoint" onChange={handleOnInputChange} placeholder="Bot Endpoint URL" required />
                        <TextInput label="JWT Token" type="text" id="jwt_token" value={useCase.jwt_token} name="jwt_token" onChange={handleOnInputChange} placeholder="JWT Token" required />
                        <TextInput label="Arabic Caller" type="text" id="arabic_caller" value={useCase.arabic_caller} onChange={handleOnInputChange} name="arabic_caller" placeholder="arabic caller" required />
                        <TextInput label="English Caller" type="text" id="english_caller" value={useCase.english_caller} onChange={handleOnInputChange} name="english_caller" placeholder="english caller" required />
                        <SelectInput label="Departement" title="Departement" options={departements} id="departement" name="departement" value={useCase.departement} onChange={handleOnInputChange} />
                        <TextInput label="Level" type="number" min="1" id="level" value={useCase.level} onChange={handleOnInputChange} name="level" placeholder="Level" required />

                    </div>


                    <div>
                        <SelectInput label="User" title="User" options={users} id="user" name="user" value={useCase.user} onChange={handleOnInputChange} />

                        <SelectInput label="Type" title="Type" options={types} id="type" name="type" value={useCase.type} onChange={handleOnInputChange} />
                        {useCase.type === 'EVENT' &&
                            <>
                                <SelectInput label="Priority" title="Priority" options={priorities} id="priority" name="priority" value={useCase.priority} onChange={handleOnInputChange} />
                                <TextInput label="Bot Max CC" min="1" type="number" id="bot_max_cc" value={useCase.bot_max_cc} onChange={handleOnInputChange} name="bot_max_cc" placeholder="Bot Max CC" />
                            </>
                        }

                        <div className="mb-3">
                            <label className="block text-sm font-medium text-gray-700 mb-1 font-inter">
                                Description
                            </label>
                            <textarea type="text" id="description" name="description" value={useCase.description} onChange={handleOnInputChange} placeholder="description" required className="w-full  px-4 py-2 mt-1 rounded-lg border border-gray-300 focus:ring-1  focus:ring-gray-400 focus:border-gray-400 shadow-sm bg-input placeholder-gray-400 text-gray-900 focus:outline-none transition duration-300 ease-in-out h-40" ></textarea>
                        </div>

                        {/* <TextInput label="Client ID" type="text" id="client_id" value={useCase.client_id} name="client_id" onChange={handleOnInputChange} placeholder="Client ID" required />


                        <TextInput label="Client Secret" type="text" id="client_secret" value={useCase.client_secret} name="client_secret" onChange={handleOnInputChange} placeholder="Client Secret" required /> */}

                        <div className="mt-8 flex justify-between pr-4">
                            <label htmlFor="is_production" className="ml-2 text-gray-700 font-inter my-0">
                                Is Production?
                            </label>
                            <label className="inline-flex items-center mb-5 cursor-pointer">
                                <p className="inline mr-3"><span className={`${useCase.is_production && "text-primary"} font-normal m-1`}>Yes</span>/<span className={`${!useCase.is_production && "text-primary"} font-normal m-1`}>No</span></p>
                                <input type="checkbox" name='is_production' id="is_production" checked={useCase.is_production} onChange={handleOnInputChange} className="shadow-sm w-4 h-4  border-gray-300 rounded focus:ring-primary focus:ring-2 font-inter sr-only peer" />
                                <div className="relative w-9 h-5 bg-gray-200 peer-focus:outline-none peer-focus:ring-1 peer-focus:ring-primary rounded-full peer  peer-checked:after:translate-x-full rtl:peer-checked:after:-translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:start-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-4 after:w-4 after:transition-all dark:border-gray-600 peer-checked:bg-primary"></div>
                            </label>
                        </div>

                    </div>
                </div>


                <h1 className="text-xl font-semibold text-gray-900 font-inter text-secondary mt-10 mb-2">
                    2.0 Voice Agent Fields
                </h1>
                <p className="text-sm leading-6 text-gray-400 font-inter mb-4 w-3/4">
                    The data that you will fill here will be the inputs of the
                    voice agent.
                </p>
                <MultiTextInput
                    inputsData={inputsData}
                    setinputsData={setinputsData}
                />


                <h1 className="text-xl font-semibold text-secondary font-inter mt-10 mb-2">
                    3.0 Custom Questions / Data Points
                </h1>
                <p className="text-sm leading-6 text-gray-400 font-inter mb-4 w-3/4">
                    You can customize specific questions of data points by entering them below
                </p>
                {/* <MultiQuestionsInput
                    customQuestions={customQuestions}
                    setCustomQuestions={setCustomQuestions}
                /> */}


                <MultiQuestions
                    inputsData={customQuestions2}
                    setinputsData={setCustomQuestions2}
                />


                <h1 className="text-xl font-semibold text-secondary font-inter mt-10 mb-2">
                    4.0 Conversations Check Points
                </h1>
                <p className="text-sm leading-6 text-gray-400 font-inter mb-4 w-3/4">
                    You can handle multiple checkpoints to enhance determination of conservation statueses
                </p>


                <MultiQuestionCheckPoints
                    inputsData={checkpoints}
                    setinputsData={setCheckpoints}
                />
            </div>


            <button disabled={loading} className="bg-primary px-5 text-white font-semibold font-inter py-2 rounded-md float-right mb-4 shadow-sm" onClick={() => {
                if (useCaseId) {
                    editUseCase();
                } else {
                    createNewUseCase();
                }
            }} >{loading ? "Loading...." : useCaseId ? "Edit" : "Create"}</button>

        </Layout>
    )
}

/**
 * <div className="flex flex-col md:flex-row  space-x-5">


                     <div className="mb-4 grow">
                        <label htmlFor="active" className="block text-sm font-medium text-gray-700 font-inter">Active</label>
                        <div className="mt-2">
                            <select id="active" name="active" value={useCase.active} onChange={handleOnInputChange} className="shadow-sm bg-input mt-1 block w-full  rounded-md px-4 py-2  focus:outline-none  font-inter h-12" >
                                <option value={true}>True</option>
                                <option value={false}>False</option>
                            </select>
                        </div>
                    </div> 
                    </div>

                    <div className="flex flex-col md:flex-row space-x-5">
                         <div className="mb-4 grow">
                            <label htmlFor="is_production" className="block text-sm font-medium text-gray-700 font-inter">Is Production</label>
                            <div className="mt-2">
                                <select id="is_production" name="is_production" value={useCase.is_production} onChange={handleOnInputChange} className="mt-1 bg-white font-inter block w-full border border-gray-300 rounded-md p-2 h-10 focus:outline-none focus:ring-2 focus:ring-primary shadow-sm" >
                                    <option value={true}>True</option>
                                    <option value={false}>False</option>
                                </select>
                            </div>
                        </div> 
    
    
    
    
                    </div>
    
    
                    <div className="flex flex-col md:flex-row space-x-5">
    
                    </div>
 */