import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { ToastContainer, toast, Slide } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import axios from "axios";
import { BASE_URL } from "../constants/config";

export default function Login() {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const navigate = useNavigate();

  const handleOnLogin = async (event) => {
    event.preventDefault();

    console.log('here')

    if (!email || !password) {
      toast.error("Login failed. Please check your credentials.", {
        transition: Slide,
      });
      return
    }

    if (email !== 'admin@ocm.com' && password !== 'admin123') {
      toast.error("Login failed. Please check your credentials.", {
        transition: Slide,
      });
      return
    }

    localStorage.setItem('access', 'zfRJDUsySR')
    navigate('/admin/organizations')

  };

  return (
    <div class="min-h-screen flex items-center justify-center bg-default">
      <div class="w-full max-w-3xl  overflow-hidden ">
        <div class="p-8 flex flex-col justify-center rounded-lg shadow-lg bg-white h-full py-20 px-10">

          <h4 className="text-secondary leading-10 text-3xl font-bold">Super Admin</h4>
          <h6 className="text-coal leading-10 text-2xl font-bold">Login</h6>

          <form class="mt-4" onSubmit={handleOnLogin}>

            <div class="mb-4">
              <label class="block text-gray-500 mb-2">email</label>
              <input type="email" name='email' onChange={(e) => { setEmail(e.target.value) }} value={email} class="w-full px-4 py-2 border rounded-lg focus:outline-none focus:ring-2 focus:primary" placeholder="Enter your email address" />
            </div>
            <div class="mb-4">
              <label class="block text-gray-500 mb-2">password</label>
              <input type="password" name='password' onChange={(e) => { setPassword(e.target.value) }} value={password} class="w-full px-4 py-2 border rounded-lg focus:outline-none focus:ring-2 focus:primary" placeholder="Create your password" />
            </div>

            <button class="w-full bg-primary text-white py-2 rounded-lg hover:bg-secondary transition duration-300" type="submit">
              Login
            </button>
          </form>
        </div>



        <ToastContainer />
      </div>
    </div>
  );
}
