
import { EyeIcon, PencilSquareIcon, TrashIcon } from "@heroicons/react/24/outline";
import { formatDateDDMMYY } from "../../../utils/formatting";

const types = { "calls": "Calls", "batches": "Batches", "orderRequests": "Order Requests", "conversations": "Conversations" }

export default function ReportCard({ report, handleOnRemove, handleOnViewReport, handleOnUpdate }) {

    return (
        <div className="max-w-xs w-full p-6 bg-white rounded-lg shadow-lg relative border flex flex-col">
            <div className="w-full break-words mb-4 ">
                <h4 className="text-lg font-semibold text-gray-800  max-w-48">{report.name}</h4>
                <div className="flex space-x-2 my-1">
                    <span className="text-sm font-semibold px-2 py-1 text-primary rounded-lg bg-input">{types[report.type]}</span>
                    {(report.type === "calls" || report.type === "conversations") && <span className="text-sm font-semibold px-2 py-1 text-primary rounded-lg bg-input">{report.isExecution ? "Execution" : "Simulation"}</span>}
                </div>
            </div>

            <p className="text-gray-600 mb-4 grow line-clamp-5">{report.description}</p>
            <hr />
            <div className="mb-2 rounded-lg p-2 mt-2">
                {report.start_date && report.end_date &&
                    <div className="flex space-x-2">
                        <span className="px-3 py-1 text-sm font-medium bg-primary text-white rounded-full font-inter">
                            {formatDateDDMMYY(report.start_date)} To {formatDateDDMMYY(report.end_date)}
                        </span>
                    </div>
                }

                <div className="flex flex-wrap gap-2 mt-2">
                    {report.lastN && <span className="px-3 py-1 text-sm font-medium bg-primary text-white rounded-full font-inter"> {`Last ${report.lastN} Days`}</span>}
                    <span className="px-3 py-1 text-sm font-medium bg-primary text-white rounded-full font-inter">{report.usecase ? report.usecase.name : '∀ voice agents'}</span>

                    {report.type === "calls" &&
                        <span className="px-3 py-1 text-sm font-medium bg-primary text-white rounded-full font-inter">{report.subStatus ? report.subStatus : '∀ statuses'}</span>
                    }
                    {report.type === "conversations" &&
                        <span className="px-3 py-1 text-sm font-medium bg-primary text-white rounded-full font-inter">{report.checkPoint ? report.checkPoint : '∀ statuses'}</span>
                    }

                    {!report.start_date && !report.lastN && <span className="px-3 py-1 text-sm font-medium bg-primary text-white rounded-full font-inter">∀ time</span>}
                </div>

            </div>

            <span className="text-gray-400 mb-2">{report.created_on}</span>
            <div className="absolute top-4 right-4 flex space-x-3" >
                <TrashIcon className="w-5 h-5 text-red-500 cursor-pointer" onClick={() => handleOnRemove(report._id)} />
                <PencilSquareIcon className="w-5 h-5 text-gray-500 cursor-pointer" onClick={() => handleOnUpdate(report._id)} />
                <EyeIcon className="w-5 h-5 text-primary cursor-pointer" onClick={() => handleOnViewReport(report._id)} />
            </div>
        </div>
    )
}