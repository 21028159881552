import { useContext, useEffect, useState } from "react";
import Layout from "../components/Layout";
import { cancelCalls, executePending, getAllPendingCallsAPI, } from "../services/apis/dashboard";
import { AuthContext } from "../store/auth_context";
import NoData from "../components/NoData";
import PendingCallsTable from "../components/PendingCallsTable";
import PendingDialog from "../components/pending/PendingDialog";
import { Slide, toast, ToastContainer } from "react-toastify";
import DeleteModal from "../components/DeleteModal";

export default function PendingCalls() {
  const { profile } = useContext(AuthContext)
  const [calls, setcalls] = useState([]);
  const [filters, setFilters] = useState({ usecase: '', status: '', subStatus: '', gte: '', lte: '', lastN: '', limit: 10, page: 1 })
  const [isOpen, setIsOpen] = useState(false)
  const [isOpenExecute, setIsOpenExecute] = useState(false)
  const [trigger, setTrigger] = useState(0)

  const [selectedRows, setSelectedRows] = useState([]);
  const allChecked = selectedRows.length === calls.length;


  const handleSelectAll = () => {
    setSelectedRows(allChecked ? [] : calls.map((item) => item._id));
  };

  const handleSelectRow = (id) => {
    setSelectedRows((prevSelected) =>
      prevSelected.includes(id)
        ? prevSelected.filter((rowId) => rowId !== id)
        : [...prevSelected, id]
    );
  };

  const handleOnSubmit = async (event) => {
    // event.preventDefault()
    // const formData = new FormData(event.target)
    // const data = Object.fromEntries(formData.entries())
    // console.log('data', data)
    executePending({ 'calls': selectedRows }).then((response) => {
      toast.success("Execute Pending Calls Successfully!", { transition: Slide })
      setTrigger(prev => prev + 1)
    }).catch((error) => {
      toast.error(error.message, { transition: Slide })
    })
  }

  const paginate = (page) => {
    setFilters((previous) => { return { ...previous, page: page } })
  }

  const handleOnCancel = async (event) => {
    cancelCalls({ 'calls': selectedRows }).then((response) => {
      toast.success("Calls Cancelled Successfully!", { transition: Slide })
      setTrigger(prev => prev + 1)
    }).catch((error) => {
      toast.error(error.message, { transition: Slide })
    })
  }


  useEffect(() => {
    getAllPendingCallsAPI(profile.organization._id, filters)
      .then((res) => {
        setcalls(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  }, [profile.organization._id, filters, trigger]);


  return (
    <Layout title='Pending Calls'>

      {selectedRows.length > 0 &&
        <div className="flex space-x-2 float-right mb-5">
          <button className="bg-primary text-white p-2 rounded-lg shadow-lg opacity-80" onClick={() => setIsOpenExecute(true)}>Execute</button>
          <button className="bg-red-400 text-white p-2 rounded-lg shadow-lg opacity-80" onClick={() => setIsOpen(true)}>Cancel</button>
        </div>}

      {/* <PendingDialog isOpen={isOpen} setIsOpen={setIsOpen} /> */}

      {calls.length > 0 ?
        <PendingCallsTable calls={calls} filters={filters} paginate={paginate} allChecked={allChecked} handleSelectAll={handleSelectAll} selectedRows={selectedRows} handleSelectRow={handleSelectRow} />

        :
        <NoData />
      }

      <DeleteModal open={isOpenExecute} setOpen={setIsOpenExecute} deleteFunc={() => { handleOnSubmit(); }} title="Execute Pending Calls" description="Are you sure you want to execute selected calls?" />

      <DeleteModal open={isOpen} setOpen={setIsOpen} deleteFunc={() => { handleOnCancel(); }} title="Cancel Calls" description="Are you sure you want to cancel selected calls?" />

      <ToastContainer />
    </Layout>
  );
}
