import { TrashIcon, PlusCircleIcon } from "@heroicons/react/24/outline";
import TextInput from "../../inputs/TextInput";
import SelectInput from "../../inputs/SelectInput";




function MultiQuestionCheckPoints({ inputsData, setinputsData }) {

    const handleInputChange = (index, fieldName, value) => {
        const newData = [...inputsData];
        newData[index][fieldName] = value;
        setinputsData(newData);
    };

    const handleAddInput = (e) => {
        e.preventDefault();
        setinputsData([
            ...inputsData,
            {
                label: "",
                id: "",
            },
        ]);
    };

    const handleRemoveInput = (e, index) => {
        e.preventDefault();
        const newData = [...inputsData];
        newData.splice(index, 1);
        setinputsData(newData);
    };

    return (
        <>
            {inputsData.map((data, index) => (
                <div key={index} className="flex space-x-4 mb-5">
                    <TextInput label="Id" type="text" placeholder="Id" value={data.id} onChange={(event) => handleInputChange(index, "id", event.target.value)} />

                    <TextInput label='Label' type="text" placeholder="Label" value={data.label} onChange={(event) => handleInputChange(index, "label", event.target.value)} />




                    <div className="flex space-x-2 my-auto pt-2">
                        <TrashIcon
                            onClick={(e) => handleRemoveInput(e, index)}
                            className="h-5 w-5 text-red-700 cursor-pointer"
                        />

                        <PlusCircleIcon
                            onClick={(e) => handleAddInput(e)}
                            className="h-5 w-5 my-auto text-green-700 cursor-pointer"
                        />
                    </div>
                </div>
            ))}
        </>
    );
}

export default MultiQuestionCheckPoints;
