import { useState } from "react";
import Layout from "../components/Layout";
import TextInput from "../components/inputs/TextInput";
import SelectInput from "../components/inputs/SelectInput";


export default function Temp() {
    const [inputs, setInputs] = useState([{ label: "", name: "", placeholder: "", type: "" }])
    return (
        <Layout title="Temp">
            <div className="bg-white shadow-lg rounded-lg p-5">
                <MultiTextInput inputs={inputs} setInputs={setInputs} />
            </div>
        </Layout>
    )
}


const types = [
    { text: "String", value: "text" },
    { text: "Number", value: "number" },
    { text: "Boolean", value: "bool" }
]

const MultiTextInput = ({ inputs, setInputs }) => {
    return (
        <>
            {
                inputs.map((data, index) => (
                    <div key={index} className="flex space-x-4 mb-5">
                        <VoiceAgentFields data={data} index={index} />
                    </div>
                ))
            }
        </>

    )
}

const VoiceAgentFields = (data, index) => {
    return (
        <>
            <TextInput label='Label' type="text" placeholder="label of input" value={data.label} />
            <TextInput label='Name' type="text" placeholder="name of input" value={data.name} />
            <TextInput label='Placeholder' type="text" placeholder="placeholder of input" value={data.placeholder} />
            <SelectInput label='Type' options={types} title='select' disabledTitle={true} />
        </>
    )
}