import { useState, useEffect, useContext } from "react";
import { useNavigate } from "react-router-dom";
import { ToastContainer, toast, Slide } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import { getAllUseCasesAPI } from "../services/apis/useCases";

import Layout from "../components/Layout";
import { AuthContext } from "../store/auth_context";

export default function UseCases() {
  const { profile } = useContext(AuthContext)
  const navigate = useNavigate();
  const [useCases, setuseCases] = useState([]);
  const [selectedCase, setselectedCase] = useState({});
  const [selectedType, setselectedType] = useState("Simulate");
  const organization_id = profile.organization._id

  async function get_all_use_cases() {
    if (organization_id)
      getAllUseCasesAPI(organization_id)
        .then((res) => {
          setuseCases(res.data.usecases);
          setselectedCase(res.data.usecases[0]);
        })
        .catch((err) => {
          console.log(err);
        });
  }

  useEffect(() => {
    get_all_use_cases();
  }, []);

  return (
    <Layout title='Simulation'>
      <div className="flex justify-center " >
        <div className="w-full m-auto border p-7 rounded-lg shadow-lg bg-white">
          <div className="space-y-12">
            <div className="pb-5">
              <h2 className="text-base font-semibold text-gray-900 font-inter text-secondary">
                Voice Agent Simulation
              </h2>
              <p className="mt-1 text-sm text-gray-600 mb-3 font-inter">
                You need to choose the desired use case in order to handle the
                right payload and bot api.
              </p>
              <div className="grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
                <div className="sm:col-span-3 ">
                  <label className="block text-sm font-medium leading-6 text-gray-900 capitalize font-inter">
                    Name
                  </label>
                  <div className="mt-2">
                    <select
                      id="useCase"
                      name="useCase"
                      defaultValue="DEFAULT"
                      onChange={(e) => {
                        setselectedCase(JSON.parse(e.target.value));
                      }}
                      className="shadow-sm bg-input mt-1 block w-full  rounded-md px-4 py-2  focus:outline-none  font-inter h-10"
                    >
                      <option value="DEFAULT" disabled>
                        Select
                      </option>
                      {useCases.map((item) => {
                        return (
                          <option key={item._id} value={JSON.stringify(item)}>
                            {item.name}
                          </option>
                        );
                      })}
                    </select>
                  </div>
                </div>


                {/** 
                <div className="sm:col-span-3">
                  <label className="block text-sm font-medium leading-6 text-gray-900 capitalize font-inter">
                    Type
                  </label>
                  <div className="mt-2">
                    <select
                      id="type"
                      name="type"
                      defaultValue="DEFAULT"
                      onChange={(e) => {
                        setselectedType(e.target.value);
                      }}
                      className="shadow-sm bg-input mt-1 block w-full  rounded-md px-4 py-2  focus:outline-none  font-inter h-10"
                    >
                      <option value="DEFAULT" disabled>
                        Select
                      </option>
                      <option value="Simulate">Simulate</option>
                       <option value="Exectue">Exectue</option> 
                    </select>
                  </div>
                </div>
              */}

              </div>
            </div>
          </div>
          <button
            onClick={(e) => {
              if (selectedCase && selectedType) {
                navigate("/callbot", {
                  state: {
                    selectedCase: selectedCase,
                    selectedType: selectedType,
                  },
                });
              } else {
                toast.error("Please select the use case and the type.", {
                  transition: Slide,
                  position: "bottom-right",
                  autoClose: 1500,
                  hideProgressBar: false,
                  closeOnClick: true,
                  pauseOnHover: true,
                  draggable: true,
                  progress: undefined,
                });
              }
            }}
            type="submit"
            className="rounded-md float-right bg-primary px-7 py-2 text-sm font-semibold text-white shadow-sm focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2"
          >
            Next
          </button>

        </div>
      </div>
      <ToastContainer />
    </Layout>
  );
}
