import { useContext } from "react";
import { Link } from "react-router-dom";
import { AuthContext } from "../store/auth_context";


export default function Error() {
    const { profile } = useContext(AuthContext)
    return (
        <div className="flex justify-center items-center mx-auto bg-white h-screen ">
            <div className="flex flex-col justify-center items-center bg-white rounded-lg p-10 ">
                <p className="text-xlg font-inter text-secondary font-bold text-center ">404 Page Error</p>
                <img src='error404.png' alt='...' className=" h-50" />
                <p className="font-inter text-gray-400 w-full lg:w-1/2 text-center mb-4">We're sorry, the page you requested couldn't be found please go back to the homepage</p>

                <Link to={profile.role === "tester" ? '/voice_agents' : '/dashboard'} className="shadow-sm bg-secondary text-white font-inter w-1/4 rounded-lg py-2 text-center">Go Home</Link>
            </div>

        </div>
    )
}