export const API_GET_ALL_USERS = {
  url: "/admin",
  method: "GET",
};

export const API_DELETE_USER = {
  url: "/admin",
  method: "DELETE",
};

export const API_CREATE_USER = {
  url: "/admin",
  method: "POST",
};

export const API_LOGIN_USER = {
  url: "/admin/login",
  method: "POST",
};
