import { useContext, useEffect, useState } from "react";
import Layout from "../components/Layout";
import { AuthContext } from "../store/auth_context";
import { create, deleteReport, ReadByOrgId, updateReport } from "../services/apis/custom_reports";
import Loading from "../components/Loading";
import Error from "../components/Error";
import ReportCard from "../components/reports/custom/ReportCard";
import NoData from "../components/NoData";
import DeleteModal from "../components/DeleteModal";
import { Slide, toast, ToastContainer } from "react-toastify";
import { useNavigate } from "react-router-dom";
import ReportDialog from "../components/reports/custom/ReportDialog";
import { getAllUseCasesAPI } from "../services/apis/useCases";

// "use_case_id", "organization",
//usecase", 
//, "batch", "use_case_id"
const calls_system_fields = ["lang", "sid", "callId", "trials", "current_time", "is_pending", "is_cancelled", "status", "subStatus", "reason", "reasonCode", "callDuration", "isExecution", "checkPoint"]
const batches_system_fields = ["createdAt", "description", "name", "faileds", "file"]
const orderRequests_system_fields = ["status", "executed_by", "re_trials", "next_dial_time", "is_scheduled", "name", "description", "start_date"]

const systemfields = {
    calls_system_fields: calls_system_fields,
    batches_system_fields: batches_system_fields,
    orderRequests_system_fields: orderRequests_system_fields
}

export default function Reports() {
    const { profile } = useContext(AuthContext)
    const navigate = useNavigate()
    const [mode, setMode] = useState('create')
    const [dateType, setDateType] = useState('lastN')

    const [reports, setReports] = useState([])
    const [data, setData] = useState({ name: '', description: '', type: 'calls', usecase: '', subStatus: '', checkPoint: '', start_date: '', end_date: '', lastN: '', fields: [], questions_fields: [], system_fields: [], isExecution: false })
    const [range, setRange] = useState({ startDate: '', endDate: '' })


    const [loading, setLoading] = useState(false)
    const [error, setError] = useState('')

    const [selected, setSelected] = useState('')
    const [openDeleteModal, setOpenDeleteModal] = useState(false)
    const [openCreateModal, setOpenCreateModal] = useState(false)

    const [useCases, setUseCases] = useState([]) //this for select input
    const [voiceAgents, setVoiceAgents] = useState([]) //this for store all usecases and get fields 
    const [trigger, setTrigger] = useState(0)
    const [fields, setFields] = useState([])
    const [questionFields, setQuestionFields] = useState([])
    const [checkpoints, setCheckpoints] = useState([])

    const handleOnRemove = (id) => {
        setSelected(id)
        setOpenDeleteModal(true)
    }

    const handleOnRemoveReport = async (id) => {
        deleteReport(id).then((response) => {
            setSelected('')
            setReports((previous) => previous.filter((report) => report._id !== id))
            toast.success("Report Deleted Successfully", { transition: Slide })
        }).catch((error) => {
            setSelected('')
            toast.error(error.messsage, { transition: Slide })
        })
    }

    const handleOnViewReport = (id) => {
        navigate(`../reports/${id}`)
    }

    const handleOnCreateReport = async () => {
        const report = {
            ...data,
            usecase: data.usecase ? data.usecase : undefined,
            organization: profile.organization._id
        }
        console.log('report', report)
        create(report).then((response) => {
            setTrigger(prev => prev + 1)
            handleOnCloseCreateModal()
            toast.success("Report Added Successfully!", { transition: Slide })
        }).catch((error) => {
            toast.error(error.messsage, { transition: Slide })
        })

    }

    const handleOnUpdate = async (id) => {
        const details = reports.find((report) => report._id === id)

        setData({ ...details, usecase: details.usecase ? details.usecase._id : '', subStatus: details.subStatus ? details.subStatus : '', checkPoint: details.checkPoint ? details.checkPoint : '' })

        if (details.start_date && details.end_date) {
            setDateType('range')
            setRange({ startDate: details.start_date, endDate: details.end_date })
        } else {
            setDateType('lastN')
        }

        if (details.usecase) {
            const voiceAgent = voiceAgents.find((field) => field._id === details.usecase._id)
            const keys = []
            const questions = []
            for (const field of Object.values(voiceAgent.fields)) {
                keys.push(field.label)
            }
            for (const question of Object.values(voiceAgent.custom_questions)) {
                questions.push(question.question)
            }
            setFields(keys)
            setQuestionFields(questions)
            console.log(questions)
            if (voiceAgent.checkpoints && voiceAgent.checkpoints.length > 0) {
                setCheckpoints(voiceAgent.checkpoints.map((item) => ({ text: item.label, value: item.id })))
            }
        }

        setMode('update')
        setSelected(id)
        setOpenCreateModal(true)
    }

    const handleOnUpdateReport = async () => {
        const report = {
            ...data,
            usecase: data.usecase ? data.usecase : undefined,
            organization: profile.organization._id
        }
        updateReport(selected, report).then((response) => {
            setSelected('')
            setTrigger(prev => prev + 1)
            handleOnCloseCreateModal()
            setRange({ startDate: '', endDate: '' })
            toast.success("Report Updated Successfully", { transition: Slide })
        }).catch((error) => {
            setError(error.message)
        })
    }


    const handleOnCloseCreateModal = () => {
        setOpenCreateModal(false)
        setData({ name: '', description: '', type: 'calls', usecase: '', subStatus: '', checkPoint: '', start_date: '', end_date: '', lastN: '', fields: [], system_fields: [], questions_fields: [], isExecution: false })
        setRange({ startDate: '', endDate: '' })
        setMode('create')
    }

    useEffect(() => {
        setLoading(true)
        ReadByOrgId(profile.organization._id).then((response) => {
            setLoading(false)
            setReports(response)
        }).catch((error) => {
            setLoading(false)
            setError(error.message)
        })
    }, [trigger, profile.organization._id])

    useEffect(() => {
        getAllUseCasesAPI(profile.organization._id).then((response) => {
            if (response && response.data) {
                setVoiceAgents(response.data.usecases)
                setUseCases(response.data.usecases.map((usecase) => {
                    return { text: usecase.name, value: usecase._id }
                }))
            }
        }).catch((error) => {
            setError(error)
        })
    }, [profile.organization._id])

    if (loading) {
        return (
            <Layout title='Reports'>
                <Loading />
            </Layout>
        )
    }

    if (error) {
        return (
            <Layout title='Reports'>
                <Error />
            </Layout>
        )
    }

    return (
        <Layout title='Reports'>

            <div className="float-right">
                <button onClick={() => setOpenCreateModal(true)} className="bg-primary h-10 p-2 rounded-lg text-white shadow-sm" >Create Report</button>
            </div>

            <ReportDialog
                data={data}
                useCases={useCases}
                voiceAgents={voiceAgents}
                openCreateModal={openCreateModal}
                dateType={dateType}
                range={range}
                mode={mode}
                fields={fields}
                systemfields={systemfields}
                questionFields={questionFields}
                setQuestionFields={setQuestionFields}
                checkpoints={checkpoints}
                setFields={setFields}
                setDateType={setDateType}
                handleOnUpdateReport={handleOnUpdateReport}
                handleOnCloseCreateModal={handleOnCloseCreateModal}
                setRange={setRange}
                setOpenCreateModal={setOpenCreateModal}
                handleOnCreateReport={handleOnCreateReport}
                setData={setData}
                setCheckpoints={setCheckpoints} />

            {/* voiceAgent={voiceAgents.find((item) => item._id === report.usecase._id)}  */}
            {reports.length > 0 ?
                <div className="flex flex-wrap gap-4 px-2 py-8">
                    {reports.map((report) => {
                        return (<ReportCard key={report._id} report={report} handleOnRemove={handleOnRemove} handleOnViewReport={handleOnViewReport} handleOnUpdate={handleOnUpdate} />)
                    })}
                </div>
                : <NoData />}




            <DeleteModal open={openDeleteModal} setOpen={setOpenDeleteModal} deleteFunc={() => { handleOnRemoveReport(selected); }} />
            <ToastContainer />

        </Layout>

    )
}