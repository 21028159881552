
import { useContext, useEffect, useState } from 'react';
import Layout from '../components/Layout'
import { PencilSquareIcon, PlusCircleIcon, TrashIcon } from '@heroicons/react/24/outline';
import { read, update } from '../services/apis/systemConfigs'
import { Slide, toast, ToastContainer } from 'react-toastify';
import { AuthContext } from '../store/auth_context';
import NoData from '../components/NoData';
import TextInput from '../components/inputs/TextInput';

function isValidSaudiNumber(number) {
    const regex = /^\+9665\d{8}$/;
    return regex.test(number);
}

export default function TestingList() {
    const { profile } = useContext(AuthContext)
    const role = profile.role

    const [items, setItems] = useState([]);
    const [editIndex, setEditIndex] = useState(null);
    const [newValue, setNewValue] = useState('');
    const [newItem, setNewItem] = useState('');
    const [trigger, setTrigger] = useState(0)

    const handleEdit = (index) => {
        setEditIndex(index);
        setNewValue(items[index]);
    };

    const handleRemove = async (element) => {
        // setItems((previous) => previous.filter((item, index) => index !== element))
        update(profile.organization._id, { testing_list: items.filter((item, index) => index !== element) }).then((response) => {
            toast.success("Phone Removed Successfully!", { transition: Slide })
            setTrigger(prev => prev + 1)
        }).catch((error) => {
            console.log('error', error)
            toast.error(error.messsage, { transition: Slide })
        })


        // setTrigger(prev => prev + 1)
    }

    const handleSave = async (index) => {
        const updatedItems = [...items];
        updatedItems[index] = newValue;
        if (isValidSaudiNumber(newValue)) {
            setEditIndex(null);
            update(profile.organization._id, { testing_list: updatedItems }).then((response) => {
                toast.success("Phone Edited Successfully!", { transition: Slide })
                setTrigger(prev => prev + 1)
            }).catch((error) => {
                console.log('error', error)
                toast.error(error.messsage, { transition: Slide })
            })
        } else {
            toast.error('Invalid phone number format +9665XXXXXXXX', { transition: Slide })
        }


    };

    const handleCancel = () => {
        setEditIndex(null); // Exit edit mode without saving
    };

    const handleAddItem = async () => {
        if (newItem.trim() === '') return; // Avoid adding empty items
        if (isValidSaudiNumber(newItem)) {
            update(profile.organization._id, { testing_list: [...items, newItem] }).then((response) => {
                toast.success("Phone Added Successfully!", { transition: Slide })
                setNewItem('')
                setTrigger(prev => prev + 1)
            }).catch((error) => {
                console.log('error', error)
                toast.error(error.messsage, { transition: Slide })
            })
        } else {
            toast.error('Invalid phone number format +9665XXXXXXXX', { transition: Slide })
        }

    };


    useEffect(() => {
        read(profile.organization._id).then((response) => {
            setItems(response.testing_list ? response.testing_list : [])
        }).catch((error) => {
            toast.error(error.messsage, { transition: Slide })
        })
    }, [trigger, profile.organization._id])

    return (
        <Layout title="Testing List">
            <div className=" mx-auto bg-white  shadow-lg rounded-lg p-10 pb-16 border">

                <h1 className="text-xl font-semibold text-secondary font-inter mb-2">
                    Phone Numbers List
                </h1>

                <p className="text-sm leading-6 text-gray-400 font-inter mb-4 w-3/4 mb-4">
                    Create a new phone number list by adding individual numbers. Ensure the numbers are correctly formatted to avoid errors.
                </p>


                {role === "tester" ? undefined :
                    <div className="flex mb-4 space-x-2 px-5 ">
                        <TextInput label="Phone" placeholder="+9665XXXXXXXX" value={newItem} onChange={(event) => setNewItem(event.target.value)} />

                        <button
                            className="px-4 py-1 h-11 mt-4 bg-primary rounded-lg text-white  self-center "
                            onClick={handleAddItem}
                        >
                            Add
                        </button>
                    </div>
                }
                {items.length > 0 ?
                    <ul className="space-y-2 px-5 mb-4">
                        {items.map((item, index) => (
                            <li
                                key={index}
                                className="flex items-center justify-between rounded-lg "
                            >
                                {editIndex === index ? (
                                    <div className='grow'>
                                        <TextInput value={newValue} onChange={(event) => setNewValue(event.target.value)} />

                                    </div>
                                ) : (
                                    <div className='grow'>
                                        <TextInput value={item} disabled />
                                    </div>
                                )}
                                {role === "tester" ? undefined :
                                    <div className="ml-4 self-center h-11">
                                        {editIndex === index ? (
                                            <>
                                                <button
                                                    className="px-4 py-1 bg-primary text-white rounded-lg "
                                                    onClick={() => handleSave(index)}
                                                >
                                                    Save
                                                </button>
                                                <button
                                                    className="ml-2 px-4 py-1 bg-red-400 text-white rounded-lg"
                                                    onClick={handleCancel}
                                                >
                                                    Cancel
                                                </button>
                                            </>
                                        ) : (
                                            <div className='flex span-x-2'>
                                                <PencilSquareIcon className="h-6 w-6 text-primary mr-4 inline align-bottom cursor-pointer" onClick={() => handleEdit(index)} />
                                                <TrashIcon className="h-6 w-6 text-red-600   mr-4 inline align-bottom cursor-pointer" onClick={() => handleRemove(index)} />

                                            </div>
                                        )}

                                    </div>
                                }
                            </li>
                        ))}
                    </ul>
                    : <NoData />}


            </div>
            <ToastContainer />
        </Layout>
    )
}