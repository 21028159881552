import moment from "moment"
import Pagination from "../Pagination"
import { useEffect } from "react"



export default function CallsTable({ calls, filters, paginate, fields, systemFields, questionFields }) {

    return (
        <div className="relative overflow-x-auto mb-4 bg-white my-8 px-16 py-8 rounded-lg border shadow-lg">
            <table className="w-full text-sm text-left rtl:text-right text-gray-900">
                <thead className="text-xs text-primary uppercase bg-white  ">
                    <tr className="border-b border-gray-700 text-center font-inter">
                        {systemFields.length > 0 ?
                            systemFields.map((field) => {
                                return (
                                    <th scope="col" className="px-6 py-3" >
                                        {field}
                                    </th>
                                )
                            })
                            : undefined}



                        {fields.length > 0 ?
                            fields.map((field) => {
                                return (
                                    <th scope="col" className="px-6 py-3" >
                                        {field}
                                    </th>
                                )
                            })
                            : undefined}

                        {questionFields && questionFields.length > 0 ?
                            questionFields.map((field) => {
                                return (
                                    <th scope="col" className="px-6 py-3" >
                                        {field}
                                    </th>
                                )
                            })
                            : undefined}


                    </tr>
                </thead>
                <tbody>
                    {calls.data.map((call) => {
                        return (<TableItem key={call.sid} call={call} fields={fields} systemFields={systemFields} questionFields={questionFields}></TableItem>)
                    })}
                </tbody>
            </table>
            <Pagination total={calls.total} limit={filters.limit} paginate={paginate} />
        </div>
    )
}

const TableItem = ({ call, fields, systemFields, questionFields }) => {
    const metadata = call.metadata || []
    const dynamic_values = call.dynamic_values || []

    dynamic_values.map((item) => {
        console.log('dynamic_values-----------------------', item)
    })

    const getQuestion = (field) => {
        if (dynamic_values && dynamic_values.length > 0) {
            return dynamic_values.find((item) => item.question === field).value
        }
        return ''
    }
    return (

        <tr className="bg-white border-b border-gray-400 text-center font-inter">

            {systemFields.length > 0 ?
                systemFields.map((field) => {
                    return (
                        <td className="px-6 py-4">
                            {call[field]}
                        </td>
                    )
                })
                : undefined}

            {fields.length > 0 ?
                fields.map((field) => {
                    return (
                        <td className="px-6 py-4">
                            {metadata[field]}
                        </td>
                    )
                })
                : undefined}

            {questionFields && questionFields.length > 0 ?
                questionFields.map((field) => {
                    return (
                        <td className="px-6 py-4">
                            {getQuestion(field)}
                        </td>
                    )
                })
                : undefined}

        </tr>

    )
}

{/* <td className="px-6 py-4 text-gray-600">
                {moment(call.created_on).format('DD-MM-YYYY')}
            </td>   */}