import moment from "moment"
import Pagination from "./Pagination"

export default function CancelledCallsTable({ calls }) {


    return (
        <div className="relative overflow-x-auto mb-4 bg-white my-4 px-8 py-8 rounded-lg border shadow-lg clear-right">
            <table className="w-full text-sm text-left rtl:text-right text-gray-900">
                <thead className="text-xs text-primary uppercase bg-white  ">
                    <tr className="border-b border-gray-700 text-center font-inter">

                        <th scope="col" className="px-6 py-3" >
                            session id
                        </th>

                        <th scope="col" className="px-6 py-3" >
                            call duration
                        </th>

                        <th scope="col" className="px-6 py-3" >
                            Sub Status
                        </th>

                        <th scope="col" className="px-6 py-3" >
                            status
                        </th>
                        <th scope="col" className="px-6 py-3" >
                            voice agent
                        </th>
                        <th scope="col" className="px-6 py-3" >
                            date
                        </th>

                    </tr>
                </thead>
                <tbody>
                    {calls.map((call) => {
                        return (<TableItem key={call._id} call={call}></TableItem>)
                    })}
                </tbody>
            </table>

        </div>
    )
}

const TableItem = ({ call }) => {

    return (

        <tr className="bg-white border-b border-gray-400 text-center font-inter">

            <td className="px-6 py-4 text-gray-600">
                {call.sid}
            </td>
            <td className="px-6 py-4 text-gray-600">
                {call.callDuration ? call.callDuration : "N/A"}
            </td>
            <td className="px-6 py-4 text-gray-600">
                {call.subStatus}
            </td>
            <td className="px-6 py-4 text-gray-600">
                {call.status}
            </td>
            <td className="px-6 py-4 text-gray-600">
                {moment(call.created_on).format('DD-MM-YYYY')}
            </td>

        </tr>

    )
}