

import React from 'react';
import { CSVLink } from 'react-csv';

const CsvTemplate = ({ headers, classes }) => {
    return (
        <div>
            <CSVLink
                headers={headers}
                data={[]}
                filename="template.csv"
                target="_blank"
                className={classes}
            >
                Download Template
            </CSVLink>
        </div>
    );
};

export default CsvTemplate;
