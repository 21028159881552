



const FilterSelectInput = ({ name, id, options, onChange, value }) => {

    return (
        <div className="max-w-sm">
            <select id={id} value={value} onChange={onChange} className="py-2 font-inter block w-full rounded-lg shadow-sm bg-select   px-6 border-gray-300  h-10 p-2 focus:outline-none focus:ring-2 focus:ring-primary">
                <option value="" disabled>{name}</option>
                {options.map((option) => {
                    return (
                        <option key={option.value} value={option.value}>{option.text}</option>
                    )
                })}
            </select>
        </div>
    )
}

export default FilterSelectInput