import { Link } from "react-router-dom";



export default function NoData({ button = false }) {
    return (
        <div className=" mx-auto mt-12  bg-white h-full ">
            <div className="flex flex-col justify-center items-center bg-white rounded-lg p-10 ">
                <p className="text-xlg font-inter text-secondary font-bold text-center ">No Data Available!</p>
                <img src='nodata.png' alt='...' className=" h-40" />
                <p className="font-inter text-gray-400 w-full lg:w-1/2 text-center mb-4">It looks like there’s no data to display at the moment, Please try again later or adjust your search criteria.</p>

                {button &&
                    <Link to='/dashboard' className="shadow-sm bg-secondary text-white font-inter w-1/4 rounded-lg py-2 text-center">Go Home</Link>
                }
            </div>

        </div>
    )
}