import { BASE_URL } from "../../constants/config";
import axios from "axios";

export const dashboard = async (id, query) => {
    try {
        const response = await axios.get(`${BASE_URL}/calls/dashboard/organization/${id}?${query}`, { headers: { 'Content-Type': 'application/json' } })
        return response.data.result
    } catch (error) {
        console.log('error', error)
        throw (error.response.data)
    }
}

export const dashboardByDays = async (id, query) => {
    try {
        const response = await axios.get(`${BASE_URL}/calls/dashboard/organization/${id}/days?${query}`, { headers: { 'Content-Type': 'application/json' } })
        return response.data.result
    } catch (error) {
        console.log('error', error)
        throw (error.response.data)
    }
}


export const ReadCancelledCalls = async (id) => {
    try {
        const response = await axios.get(`${BASE_URL}/calls/cancelled/organization/${id}`, { headers: { 'Content-Type': 'application/json' } })
        return response.data.data
    } catch (error) {
        console.log('error', error)
        throw (error.response.data)
    }
}


export const conversationDashboard = async (id, query) => {
    try {
        const response = await axios.get(`${BASE_URL}/calls/conversations/organization/${id}/stats?${query}`, {
            headers: { 'Content-Type': 'application/json', }
        })
        console.log('response', response)
        if (response.status === 200) {
            return response.data
        }
        return response
    } catch (error) {
        console.log('error', error)
        throw (error.response.data)
    }
}


export const getCounting = async (id) => {
    try {
        const response = await axios.get(`${BASE_URL}/calls/counting/organization/${id}`, {
            headers: { 'Content-Type': 'application/json', }
        })
        console.log('response', response)
        if (response.status === 200) {
            return response.data
        }
        return response
    } catch (error) {
        console.log('error', error)
        throw (error.response.data)
    }
}


export const ReadConversationReport = async (id, query) => {
    try {
        const response = await axios.get(`${BASE_URL}/calls/conversations/organization/${id}/report?${query}`, {
            headers: { 'Content-Type': 'application/json', }
        })
        console.log('response', response)
        if (response.status === 200) {
            return response.data
        }
        return response
    } catch (error) {
        console.log('error', error)
        throw (error.response.data)
    }
}
