
exports.constructQueryParams = (filters) => {
    const queryParams = [];
    for (const key in filters) {
        if (filters.hasOwnProperty(key) && filters[key] && filters[key].length !== 0) {
            if (key !== 'active') {
                queryParams.push(`${key}=${encodeURIComponent(filters[key])}`);
            }
        }
    }
    console.log('#Query', queryParams)
    return queryParams.join('&');
};
