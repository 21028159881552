import { useState } from "react";
import Layout from "../components/Layout";
import Table from "../components/tables/Table";


const header = ["title", "description", "date"]

const footer = { metadata: "Showing 20 items of 50" }


const data2 = [{
    "lang": "ar",
    "sid": null,
    "callId": null,
    "trials": 1,
    "current_time": "2024-11-10T22:52:53.821Z", "is_pending": false,
    "is_cancelled": null,
    "status": "completed",
    "subStatus": "SUCCEED",
    "reason": null,
    "reasonCode": null,
    "callDuration": null,
    "dynamic_values": [{ "question": "Are you avaliable now?", "value": "No" },
    { "question": "Are you good?", "value": "Yes" }],
    "isExecution": "673138f4dd4c8d02687f8392", "_id": "67313945dd4c8d02687f8432", "use_case_id": { "id": 1, "type": "EVENT", "fields": [{ "label": "target", "name": "target", "placeholder": "target", "type": "text" }], "headers": [{ "label": "target", "key": "target" }], "custom_questions": [{ "question": "Are you good?", "expected_answer": "yes", "type": "yes_no" }, { "question": "Are you avaliable now?", "expected_answer": "yes", "type": "yes_no" }], "checkpoints": [{ "label": "Hangout", "id": "1" }, { "label": "Success", "id": "2" }, { "label": "Failed", "id": "3" }], "active": true, "current_order_request": "6734b0cce0965922bc4f319c", "is_production": true, "_id": "67346b9296532e4090b042c6", "name": "fraud detection", "description": "fraud detection voice agent ", "departement": "Call Center", "botId": "st-56e8c25d-ac3e-5e2b-b8d7-40096c1e8e36", "arabic_caller": "+862", "english_caller": "+862", "priority": 1, "bot_max_cc": 22, "level": 2, "botEndpoint": "http://localhost:3000/create_voice_agent", "user": "67294dc4d8dd720644faa329", "jwt_token": "http://localhost:3000/create_voice_agent", "client_id": "", "client_secret": "", "organization": "67294dc4d8dd720644faa328", "created_on": "2024-11-13T09:04:18.759Z", "__v": 0 }, "organization": "67294dc4d8dd720644faa328", "metadata": { "name": "ayham", "target": "962779195671" }, "created_on": "2024-11-10T22:52:53.825Z", "__v": 0
}
]




export default function Test() {
    const [data, setData] = useState({ data: data2, total: 10, count: 10 })
    const [filters, setFilters] = useState({ usecase: '', status: '', subStatus: '', checkPoint: '', gte: '', lte: '', lastN: '', isExecution: false, limit: 10, page: 1 })


    const paginate = (page) => {
        setFilters((previous) => { return { ...previous, page: page } })
    }


    return (
        <Layout title="Test">
            <Table header={header} data={data} filters={filters} paginate={paginate} />
        </Layout>
    )
}