import React, { useContext, useEffect, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import axios from "axios";
import moment from "moment";
import { StopCircleIcon } from "@heroicons/react/24/outline";

import { getUseCaseByIdAPI } from "../services/apis/useCases";
import {
  createOrderReqAPI,
  stopOrderReqAPI,
} from "../services/apis/orderRequest";

import { read as getTestingList } from '../services/apis/systemConfigs'
import Layout from "../components/Layout";
import MultiTextInput from "../components/MultiTextInput";
import ConfirmModal from "../components/common/modals/ConfirmModal";

import { sendToQueue } from "../services/apis/queue";
import { AuthContext } from "../store/auth_context";


export default function CallBot() {
  const { profile } = useContext(AuthContext)
  const navigate = useNavigate();
  const location = useLocation();
  const selectedCase = location.state?.selectedCase;
  const selectedType = location.state?.selectedType;
  const UserEmail = profile.email
  const [openStopUsecaseModal, setopenStopUsecaseModal] = useState(false);

  const [useCase, setuseCase] = useState({
    name: "",
    description: "",
    departement: "",
    botId: "",
    arabic_caller: "",
    english_caller: "",
    type: "",
    priority: 0,
    active: false,
  });

  const [inputsData, setinputsData] = useState([
    [
      {
        label: "",
        name: "",
        placeholder: "",
        type: "",
      },
    ],
  ]);

  const [testingList, setTestingList] = useState([])
  const [originalInputs, setoriginalInputs] = useState([]);
  const [data, setdata] = useState([]);
  const [orderRequests, setorderRequests] = useState([]);
  const [language, setlanguage] = useState("ar");
  const [selectedId, setselectedId] = useState(null);

  async function call(e) {
    e.preventDefault();

    let metaData = data;
    let promises = [];
    console.log(metaData)
    console.log('metaData', metaData)
    metaData.map((call) => {
      console.log({
        use_case_id: selectedCase._id,
        "organization": profile.organization._id,
        lang: language,
        target: correctPhoneNumber(call["target"]),
        metadata: call,
      })
      promises.push(
        // axios.post(`${process.env.REACT_APP_BASE_URL}calls/sent_to_queue`, {
        //   use_case_id: selectedCase._id,
        //   lang: language,
        //   target: correctPhoneNumber(call["target"]),
        //   metadata: call,
        // })
        sendToQueue({
          use_case_id: selectedCase._id,
          "organization": profile.organization._id,
          lang: language,
          target: correctPhoneNumber(call["target"]),
          metadata: call,
        })
      );
    });

    try {
      let result = await Promise.all(promises);
      alert(JSON.stringify(result));
    } catch (err) {
      alert(err.message);
    }
  }

  async function getUseCaseById() {
    getUseCaseByIdAPI(selectedCase._id)
      .then((res) => {
        setorderRequests(res.data.order_requests);
        setuseCase(res.data.usecase);
        setinputsData([res.data.usecase.fields])
        setoriginalInputs(res.data.usecase.fields);
      })
      .catch((err) => console.log(err));
  }

  async function createOrderReq() {
    console.log({
      use_case_id: selectedCase._id,
      executed_by: UserEmail,
    });
    createOrderReqAPI({
      use_case_id: selectedCase._id,
      executed_by: UserEmail,
    })
      .then((res) => {
        window.location.reload();
      })
      .catch((err) => console.log(err));
  }

  async function stopOrderReq(id) {
    stopOrderReqAPI(id, {
      status: "STOPPED",
    })
      .then((res) => {
        window.location.reload();
      })
      .catch((err) => console.log(err));
  }

  function correctPhoneNumber(phoneNumber) {
    const correctPrefix = "+9665";

    // Check if the phone number already starts with +9665
    if (phoneNumber.startsWith(correctPrefix)) {
      console.log("Phone number is correctly formatted.");
      return phoneNumber;
    }

    // Remove any existing country code, leading zeros, or variations like 05 or 5
    let correctedNumber = phoneNumber.replace(/^\+?966?5?|0?5/, "");

    // Add the correct prefix
    correctedNumber = correctPrefix + correctedNumber;

    console.log("Corrected Phone Number: ", correctedNumber);
    return correctedNumber;
  }

  useEffect(() => {
    if (selectedCase) {
      getUseCaseById();
    }
  }, [selectedCase]);

  useEffect(() => {
    getTestingList(profile.organization._id).then((response) => {

      setTestingList(response.testing_list)
    }).catch((error) => {
      setTestingList([])
      console.log(error)
    })
  }, [])

  return (
    <Layout title='Callbot'>
      <div className="bg-white p-8 mb-4 border shadow-lg rounded-lg ">

        <h2 className="text-lg font-semibold text-gray-900 font-inter text-secondary">Outbound Calls</h2>

        <div className="grid grid-cols-2 md:grid-cols-2 lg:grid-cols-2 gap-5 mt-5 mb-5">

          <div className="shadow-sm border p-3 bg-gray-100 rounded-xl">
            <p className="text-sm text-gray-600 my-1">Bot Id</p>
            <h3 className="text-md font-semibold">{useCase.botId}</h3>
          </div>
          <div className="shadow-sm border p-3 bg-gray-100 shadow-lg rounded-xl grow">
            <p className="text-md text-gray-600 my-1">Description</p>
            <h3 className="text-md font-semibold">{useCase.description}</h3>
          </div>
        </div>

        <div className="grid grid-cols-2 md:grid-cols-4 lg:grid-cols-6 gap-5 mt-5 mb-10">

          <div className="shadow-sm border p-3 bg-gray-100 rounded-xl">
            <p className="text-sm text-gray-600 my-1">Name</p>
            <h3 className="text-md font-semibold">{useCase.name}</h3>
          </div>



          <div className="shadow-sm border p-3 bg-gray-100 rounded-xl">
            <p className="text-md text-gray-600 my-1">Departement</p>
            <h3 className="text-md font-semibold">{useCase.departement}</h3>
          </div>

          <div className="shadow-sm border p-3 bg-gray-100 rounded-xl">
            <p className="text-md text-gray-600 my-1">Type</p>
            <h3 className="text-md font-semibold">{useCase.type}</h3>
          </div>

          <div className="shadow-sm border p-3 bg-gray-100 rounded-xl">
            <p className="text-md text-gray-600 my-1">Priority</p>
            <h3 className="text-md font-semibold">
              {useCase.priority == 0
                ? "Low"
                : useCase.priority == 1
                  ? "Medium"
                  : "High"}
            </h3>
          </div>

          <div className="shadow-sm border p-3 bg-gray-100 rounded-xl">
            <p className="text-md text-gray-600 my-1">Active</p>
            <h3 className="text-md font-semibold">
              {useCase.active.toString()}
            </h3>
          </div>

          <div className="shadow-sm border p-3 bg-gray-100 rounded-xl">
            <p className="text-md text-gray-600 my-1">Arabic Caller</p>
            <h3 className="text-md font-semibold">{useCase.arabic_caller}</h3>
          </div>

          <div className="shadow-sm border p-3 bg-gray-100 rounded-xl">
            <p className="text-md text-gray-600 my-1">English Caller</p>
            <h3 className="text-md font-semibold">
              {useCase.english_caller}
            </h3>
          </div>

          <div className="shadow-sm border p-3 bg-gray-100 rounded-xl">
            <p className="text-md text-gray-600 my-1">Creation Date</p>
            <h3 className="text-md font-semibold">
              {moment(useCase.created_on).format("DD/MM/YYYY")}{" "}
            </h3>
          </div>
        </div>

        {selectedType == "Simulate" ? (
          <>
            <div>
              <fieldset>
                <label
                  htmlFor="single-brand"
                  className="block text-sm font-medium leading-6 text-gray-900 mb-1"
                >
                  Language
                </label>
                <div className="flex space-x-6">
                  <div className="flex items-center gap-x-3">
                    <input
                      id="language"
                      name="language"
                      type="radio"
                      onChange={() => setlanguage("ar")} // Step 2
                      checked={language === "ar"} // Check if this radio button is selected
                      className="h-4 w-4 border-gray-300 text-indigo-600 focus:ring-indigo-600"
                    />
                    <label className="block text-sm font-medium leading-6 text-gray-900">
                      Arabic
                    </label>
                  </div>
                  <div className="flex items-center gap-x-3">
                    <input
                      id="language"
                      name="language"
                      type="radio"
                      onChange={() => setlanguage("en")} // Step 2
                      checked={language === "en"} // Check if this radio button is selected
                      className="h-4 w-4 border-gray-300 text-indigo-600 focus:ring-indigo-600"
                    />
                    <label className="block text-sm font-medium leading-6 text-gray-900">
                      English
                    </label>
                  </div>
                </div>
              </fieldset>
            </div>


            <div className="mt-6">
              <MultiTextInput
                originalInputs={originalInputs}
                inputsData={inputsData}
                setinputsData={setinputsData}
                data={data}
                setdata={setdata}
                testingList={testingList}
              />
            </div>


            <div className="mt-6 flex items-center justify-start gap-x-6">
              <button
                type="button"
                className="rounded-md bg-gray-600 px-7 py-2 text-sm font-semibold text-white shadow-sm hover:bg-gray-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2"
              >
                Reset
              </button>
              <button
                onClick={(e) => {
                  call(e);
                }}
                type="submit"
                className="rounded-md bg-primary px-7 py-2 text-sm font-semibold text-white shadow-sm  focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 ="
              >
                Send Call
              </button>
            </div>
          </>
        ) : (
          <>
            <button
              onClick={() => createOrderReq()}
              className="rounded-md bg-primary px-4 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm mb-5"
            >
              Execute New Order
            </button>

            <Table
              columns={["Use Case Id", "Executed by", "Status", "Date", ""]}
              data={orderRequests}
              setopenStopUsecaseModal={setopenStopUsecaseModal}
              setselectedId={setselectedId}
            />
          </>
        )}

      </div>

      <ConfirmModal
        open={openStopUsecaseModal}
        setOpen={setopenStopUsecaseModal}
        confirmFunc={() => {
          stopOrderReq(selectedId);
        }}
      />
    </Layout>
  );
}

const Table = ({ columns, data, setopenStopUsecaseModal, setselectedId }) => {
  return (
    <div className="overflow-x-auto">
      <table className="min-w-full border-collapse rounded-lg overflow-hidden">
        <thead className="bg-gray-100">
          <tr>
            {columns.map((col, idx) => {
              return (
                <th key={idx} className="border-b border-gray-300 py-3 px-4">
                  {col}
                </th>
              );
            })}
          </tr>
        </thead>
        <tbody>
          {data.map((item, index) => (
            <tr
              key={index}
              className={index % 2 === 0 ? "bg-gray-50" : "bg-white"}
            >
              <td className="w-52 border-b border-gray-300 p-4 text-center">
                {item?.use_case_id}
              </td>

              <td className="border-b border-gray-300 p-4 text-center">
                {item.executed_by}
              </td>
              <td className="border-b border-gray-300 p-4 text-center">
                {item.status}
              </td>
              <td className="border-b border-gray-300 p-4 text-center">
                {moment(item.created_on).format("DD/MM/YYYY")}
              </td>
              <td className="border-b border-gray-300 p-4 text-center">
                {item.status === "RUNNING" ? (
                  <StopCircleIcon
                    onClick={() => {
                      setselectedId(item._id);
                      setopenStopUsecaseModal(true);
                    }}
                    className="h-6 w-6 cursor-pointer mx-auto"
                  />
                ) : null}
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

// {/* <div className="basis-2/5">
//       <ConverstionUI language={language} />
//     </div> */}
// function ConverstionUI({ language }) {
//   const [path, setPath] = useState(1);

//   const conversation_ar1 = [
//     {
//       text: "مرحبا بك، معك المساعد الإفتراضي من مصرف الراجحي. ارجو منك تأكيد معرفتك بعملية الشراء في متجر *اسم المتجر، بمبلغ * ريال سعودي في *دولة*",
//       isBot: true,
//     },
//     {
//       text: "هل لديك علم بهذه العملية؟",
//       isBot: true,
//     },
//     {
//       text: "نعم",
//       isBot: false,
//     },
//     {
//       text: "شكرا على تأكيدك، سيتم إكمال العملية، يسرني تقديم عرض حصري لك، لزيادة بقيمة 10 بالمية على الحد الائتماني لبطاقتك، هل تريد مني تفعيل هذا العرض؟",
//       isBot: true,
//     },
//     {
//       text: "نعم",
//       isBot: false,
//     },
//     {
//       text: "شكرا لك، جاري إتمام عملية زيادة الحد الائتماني، وستصلك رسالة التأكيد فور اكتمال العملية. يومك سعيد ",
//       isBot: true,
//     },
//   ];
//   const conversation_ar2 = [
//     {
//       text: "مرحبا بك، معك المساعد الإفتراضي من مصرف الراجحي. ارجو منك تأكيد معرفتك بعملية الشراء في متجر *اسم المتجر، بمبلغ * ريال سعودي في *دولة*",
//       isBot: true,
//     },
//     {
//       text: "هل لديك علم بهذه العملية؟",
//       isBot: true,
//     },
//     {
//       text: "لا",
//       isBot: false,
//     },
//     {
//       text: "شكرا لتعاونك ويومك سعيد",
//       isBot: true,
//     },
//   ];

//   const conversation_en1 = [
//     {
//       text: "Welcome, I am the virtual assistant from Al Rajhi Bank. How are you today? I would like to confirm if you are aware of the purchase trnsaction for the merchant (***) with the amount of (***) Saudi Riyals in the country (****). Are you aware of that transaction?",
//       isBot: true,
//     },
//     {
//       text: "Yes",
//       isBot: false,
//     },
//     {
//       text: "Thank you for your confirmation, the transaction will be released. By the way, We have a personalized offer for you to increase the credit limit by 10% on your card. Would you like me to activate this offer for you?",
//       isBot: true,
//     },
//     {
//       text: "Yes",
//       isBot: false,
//     },
//     {
//       text: "Thanks, the limit increase is being processed, and you will receive a confirmation message once it's done. Have a nice day.",
//       isBot: true,
//     },
//   ];

//   const conversation_en2 = [
//     {
//       text: "Welcome, I am the virtual assistant from Al Rajhi Bank. How are you today? I would like to confirm if you are aware of the purchase trnsaction for the merchant (***) with the amount of (***) Saudi Riyals in the country (****). Are you aware of that transaction?",
//       isBot: true,
//     },
//     {
//       text: "No",
//       isBot: false,
//     },
//     {
//       text: "Thank you for your cooreration , have a great day!",
//       isBot: true,
//     },
//   ];
//   //
//   const [conversation, setConversation] = useState(conversation_ar1);
//   const [note, setNote] = useState("");

//   useEffect(() => {
//     setConversation(language == "ar" ? conversation_ar1 : conversation_en1);
//     setPath(1);
//   }, [language]);

//   return (
//     <div className="bg-white overflow-y-auto border-l  p-3 h-screen">
//       <div className="flex justify-between px-5 ">
//         <h3 className="text-center font-medium text-lg text-gray-600 my-auto">
//           Call Script
//         </h3>

//         <select
//           id="havecredit"
//           name="havecredit"
//           // value={data.havecredit ? data.havecredit : "DEFAULT"}
//           onChange={(e) => {
//             if (language == "ar") {
//               setConversation(
//                 e.target.value == 1 ? conversation_ar1 : conversation_ar2
//               );
//             } else {
//               setConversation(
//                 e.target.value == 1 ? conversation_en1 : conversation_en2
//               );
//             }
//           }}
//           className="block px-3 rounded-md border border-gray-300  text-gray-900 shadow-sm py-2"
//         >
//           <option value="DEFAULT" disabled>
//             Path
//           </option>
//           <option value={1}>Path 1</option>
//           <option value={2}>Path 2</option>
//         </select>
//       </div>

//       {conversation.map((element, idx) => {
//         return (
//           <div key={idx} className="my-7">
//             <div
//               className={`flex ${
//                 element.isBot ? "flex-row-reverse" : "flex-row"
//               } `}
//             >
//               <img
//                 src={
//                   element.isBot
//                     ? "https://w1.pngwing.com/pngs/278/853/png-transparent-line-art-nose-chatbot-internet-bot-artificial-intelligence-snout-head-smile-black-and-white.png"
//                     : "https://cdn-icons-png.flaticon.com/512/3177/3177440.png"
//                 }
//                 className="h-10 w-10 rounded-full mt-auto"
//                 alt="user"
//               />
//               <div
//                 className={` p-2 rounded-t-lg ${
//                   element.isBot
//                     ? "rounded-l-lg bg-primary text-white"
//                     : "rounded-r-lg bg-gray-100 text-black"
//                 } mx-3 my-auto `}
//               >
//                 {element.text}
//               </div>
//             </div>
//           </div>
//         );
//       })}

//       <div className="sm:col-span-2">
//         <label
//           for="message"
//           className="block text-sm font-semibold leading-6 text-gray-900"
//         >
//           Notes
//         </label>
//         <div className="mt-2.5">
//           <textarea
//             onChange={(e) => setNote(e.target.value)}
//             value={note}
//             placeholder="You can note anything you face as feedback"
//             name="message"
//             id="message"
//             rows="4"
//             className="block w-full rounded-md border-0 px-3.5 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
//           ></textarea>
//         </div>

//         <button
//           onClick={(e) => {
//             alert("Your note has been submitted");
//             setNote("");
//           }}
//           type="submit"
//           className="rounded-md bg-gray-400 px-7 py-2 text-sm font-semibold text-white shadow-sm mt-2 "
//         >
//           Send Log
//         </button>
//       </div>
//     </div>
//   );
// }
