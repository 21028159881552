import publicInstance from "../../utils/publicInstance";
import {
  API_GET_ALL_USERS,
  API_DELETE_USER,
  API_CREATE_USER,
  API_LOGIN_USER,
} from "../../constants/apis/usersManagement";

const getAllUsersAPI = async () => {
  return await publicInstance(API_GET_ALL_USERS)
    .then((res) => {
      return res;
    })
    .catch((e) => {
      throw e;
    });
};

const createUserAPI = async (data) => {
  return await publicInstance({
    ...API_CREATE_USER,
    data,
  })
    .then((res) => {
      return res;
    })
    .catch((e) => {
      throw e;
    });
};

const deleteUserAPI = async (id) => {
  const config = {
    url: `${API_DELETE_USER.url}/${id}`,
    method: API_DELETE_USER.method,
  };
  return await publicInstance(config)
    .then((res) => {
      return res;
    })
    .catch((e) => {
      throw e;
    });
};

const loginUserAPI = async (data) => {
  return await publicInstance({
    ...API_LOGIN_USER,
    data,
  })
    .then((res) => {
      return res;
    })
    .catch((e) => {
      throw e;
    });
};

export { getAllUsersAPI, createUserAPI, deleteUserAPI, loginUserAPI };
