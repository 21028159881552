import { Routes, Route, Navigate } from "react-router-dom";
import Login from "./pages/Login";
import CallBot from "./pages/CallBot";
import UseCases from "./pages/UseCases";
import UserManagement from "./pages/UserManagement";
import PendingCalls from "./pages/PendingCalls";
import Admin from "./pages/Admin";
import Tanants from "./pages/Tanants";
import Batch from "./pages/Batch";
import OrderRequest from "./pages/OrderRequest";
import Reports from "./pages/Reports";
import VoiceAgent from "./pages/VoiceAgent";
import VoiceAgentForm from "./pages/VoiceAgentForm";
import UserManagementOrg from "./pages/UserManagementOrg";
import Dashboard from "./pages/Dashboard";
import TestingList from "./pages/TestingList";
import "./App.css";
import Error from "./pages/Error";
import { useContext } from "react";
import { AuthContext } from "./store/auth_context";
import Unauthorized from "./pages/Unauthorized";
import Report from "./pages/Report";
import CancelledCalls from "./pages/CancelledCalls";
import Profile from "./pages/Profile";
import Organizations from "./pages/Organizations";
import Test from "./pages/Test";




function App() {
  const { profile } = useContext(AuthContext)

  return (
    <>
      <Routes>
        <Route path="/" element={<Login />}></Route>
        <Route path="/dashboard" element={profile.role === "tester" ? <Navigate to="/unauthorized" /> : <Dashboard />}></Route>
        <Route path="/reports" element={profile.role === "tester" ? <Navigate to="/unauthorized" /> : <Reports />}></Route>
        <Route path="/reports/:id" element={profile.role === "tester" ? <Navigate to="/unauthorized" /> : <Report />}></Route>

        <Route path="/pending_calls" element={<PendingCalls />}></Route>
        <Route path="/cancelled_calls" element={<CancelledCalls />}></Route>
        <Route path="/settings" element={<Profile />}></Route>
        <Route path="/batch" element={profile.role === "tester" ? <Navigate to="/unauthorized" /> : <Batch />}></Route>
        <Route path="/job_order" element={profile.role === "tester" ? <Navigate to="/unauthorized" /> : <OrderRequest />}></Route>
        <Route path="/voice_agents" element={<VoiceAgent />}></Route>
        <Route path="/create_voice_agent" element={<VoiceAgentForm />}></Route>
        <Route path="/use_cases" element={profile.role === "monitor" ? <Navigate to="/unauthorized" /> : <UseCases />}></Route>
        <Route path="/callbot" element={profile.role === "monitor" ? <Navigate to="/unauthorized" /> : <CallBot />}></Route>
        <Route path="/testing_list" element={profile.role === "monitor" ? <Navigate to="/unauthorized" /> : <TestingList />}></Route>
        <Route path="/users" element={profile.role !== "administrator" ? <Navigate to="/unauthorized" /> : <UserManagementOrg />}></Route>

        <Route path="/users" element={profile.role !== "administrator" ? <Navigate to="/unauthorized" /> : <UserManagementOrg />}></Route>
        <Route path="/user_management" element={<UserManagement />}></Route>
        <Route path="/test" element={<Test />}></Route>

        <Route path="/admin" element={<Admin />}></Route>
        <Route path="/admin/organizations" element={localStorage.getItem('access') === 'zfRJDUsySR' ? <Organizations /> : <Navigate to="/unauthorized" />}></Route>

        <Route path="/unauthorized" element={<Unauthorized />}></Route>
        <Route path="*" element={<Error />} />
      </Routes>
    </>
  );
}

export default App;
