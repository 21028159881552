import { TrashIcon, PlusCircleIcon } from "@heroicons/react/24/outline";
import TextInput from "../../inputs/TextInput";

function MultiQuestionsInput({ customQuestions, setCustomQuestions }) {

  const handleInputChange = (index, value) => {
    const newData = [...customQuestions];
    newData[index] = value;
    setCustomQuestions(newData);
  };

  const handleAddInput = (e) => {
    e.preventDefault();
    setCustomQuestions([
      ...customQuestions,
      ""
    ]);
  };

  const handleRemoveInput = (e, index) => {
    e.preventDefault();
    const newData = [...customQuestions];
    newData.splice(index, 1);
    setCustomQuestions(newData);
  };

  return (
    <>

      {customQuestions.length > 0 ? customQuestions.map((data, index) => (
        <div key={index} className="flex space-x-4 mb-5">
          <div className="grow">
            <TextInput label={`Checkpoint ${index + 1}`} type="text" placeholder="checkpoint" value={data} onChange={(event) => handleInputChange(index, event.target.value)} />

          </div>


          <div className="flex space-x-2 my-auto pt-4 cursor-pointer">
            <TrashIcon
              onClick={(e) => handleRemoveInput(e, index)}
              className="h-5 w-5 text-red-700 "
            />

            <PlusCircleIcon
              onClick={(e) => handleAddInput(e)}
              className="h-5 w-5 my-auto text-green-700 cursor-pointer"
            />
          </div>
        </div>
      )) : null}
    </>
  );
}

export default MultiQuestionsInput;
