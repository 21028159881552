import { useContext, useEffect, useState } from "react";
import Layout from "../components/Layout";
import Loading from "../components/Loading";
import axios from "axios";
import { BASE_URL } from "../constants/config";
import { Slide, toast, ToastContainer } from "react-toastify";
import { ReadByOrgId as ReadBatches } from "../services/apis/batches";
import { activate, ReadByOrgId as ReadOrderRequests } from "../services/apis/orderRequest";
import moment from "moment";
import { AuthContext } from "../store/auth_context";
import NoData from "../components/NoData";
import TextInput from "../components/inputs/TextInput";
import SelectInput from "../components/inputs/SelectInput";

const dialPriorities = [
    { text: 1, value: 1 },
    { text: 2, value: 2 },
    { text: 3, value: 3 },
    { text: 4, value: 4 },
    { text: 5, value: 5 },
]

const nextdialTimes = [
    { text: "One Dial", value: 0 },
    { text: "1 Minutes", value: 5 },
    { text: "10 Minutes", value: 10 },
    { text: "20 Minutes", value: 20 },
    { text: "30 Minutes", value: 30 },
    { text: "1 Hour", value: 60 },
    { text: "2 Hours", value: 120 },
    { text: "5 Hours", value: 300 },
    { text: "1 Day", value: 1440 },
]


export default function OrderRequest() {
    const { profile } = useContext(AuthContext)
    const role = profile.role

    const [orderRequests, setOrderRequests] = useState([])
    const [batches, setBatches] = useState([])
    const [loading, setLoading] = useState(true)
    const [isChecked, setIsChecked] = useState(false);
    const [trigger, setTrigger] = useState(0)
    const [batch, setBatch] = useState('')
    const [date, setDate] = useState('')
    const [time, setTime] = useState('')
    const [retrials, setRetrials] = useState(0)

    const [batchesList, setBatchesList] = useState([])
    const [voiceAgent, setVoiceAgent] = useState({ 'name': '', 'botId': '' })

    const handleCheckboxChange = () => {
        setIsChecked(prev => !prev);
    };

    const handleOnBatchChange = (event) => {
        setBatch(event.target.value)
        const batch = batchesList.find((batch) => batch._id === event.target.value)
        setVoiceAgent({ name: batch.usecase.name, botId: batch.usecase.botId })
    }

    const handleOnActivate = (orderRequest) => {
        activate(orderRequest._id, { "active": true }).then((response) => {
            toast.success('Job Order Activated Successfully!', { transition: Slide })
            setTrigger(prev => prev + 1)
        }).catch((error) => {
            console.log('amhereshowme', error)
            toast.error(error.messsage, { transition: Slide })
        })
    }

    const handleOnChangeDateAndTime = (event) => {
        //Handle date and except Friday
        if (event.target.id === "date") {
            setDate(event.target.value)
            // On Update Version 2
            // const date = moment(event.target.value)
            // if (date.day() === 5) {
            //     setDate('')
            //     toast.error('Friday not allowed to schedule order request!', { transition: Slide })
            // } else {
            //     setDate(event.target.value)
            // }
        }

        //Handle allowed time range 9:30 - 18:30
        if (event.target.id === "time") {
            setTime(event.target.value)
            // On Update Version 2
            // const time = moment(event.target.value, 'HH:mm', true)
            // const startTime = moment().set({ hour: 9, minute: 30 })
            // const endTime = moment().set({ hour: 18, minute: 30 })
            // if (!time.isValid() || time.isBefore(startTime) || time.isAfter(endTime)) {
            //     setTime('')
            //     toast.error('Allowed range time is 9:30 AM - 6:30 PM', { transition: Slide })
            // } else {
            //     setTime(event.target.value)
            // }
        }
    }
    //handle On Create Order Request
    const handleOnCreateOrderRequest = async (event) => {
        event.preventDefault()
        const formData = new FormData(event.target)
        const data = Object.fromEntries(formData.entries())
        data.is_scheduled = isChecked
        data.start_date = isChecked ? new Date(`${date}T${time}`) : null
        axios.post(`${BASE_URL}/order_requests`, data, { 'Content-Type': 'application/json' }).then((response) => {
            console.log(response)
            setTrigger(prev => prev + 1)
            toast.success("Job Order Added Successfully!", { transition: Slide })
        }).catch((error) => {
            toast.error(error.response.data.message, { transition: Slide })
        })
    }

    useEffect(() => {
        setLoading(true)
        ReadOrderRequests(profile.organization._id).then((response) => {
            setOrderRequests(response)
            setLoading(false)
        }).catch((error) => {
            setLoading(false)
            toast.error(error, { transition: Slide })
        })
    }, [trigger, profile.organization._id])



    useEffect(() => {
        ReadBatches(profile.organization._id).then((response) => {
            console.log(response)
            if (response && response.data) {
                console.log(response.data.data)
                setBatchesList(response.data.data)
                setBatches(response.data.data.map((batch) => {
                    return { text: batch.name, value: batch._id }
                }))
            }
        }).catch((error) => {
            console.log(error)
        })
    }, [profile.organization._id])



    return (
        <Layout title='Job Order'>

            {role === "monitor" ? undefined :

                loading ? <Loading /> :
                    <form className="bg-white p-8 mb-4 border shadow-lg rounded-lg " onSubmit={handleOnCreateOrderRequest}>

                        <h1 className="text-xl font-semibold text-secondary font-inter mb-2">
                            Create and Schedule Job Orders
                        </h1>
                        <p className="text-sm leading-6 text-gray-400 font-inter mb-4 w-3/4 mb-4">
                            Submit a new job order by providing a name and a detailed description. Choose to run the batch immediately or schedule it for a later time.
                        </p>
                        <div className="grid grid-cols-1 md:grid-cols-2 gap-12">
                            <div>

                                <TextInput label='Name' placeholder="Name" type="text" id="name" name="name" required />

                                <SelectInput label='Batch' title='Batch' options={batches} id="batch" name="batch" value={batch} onChange={handleOnBatchChange} required />

                                {voiceAgent.name && voiceAgent.botId ?
                                    <div className="flex flex-col space-y-2 mb-6 ">
                                        <TextInput label='Bot Name' placeholder="Bot Name" type="text" id="bot_name" name="bot_name" disabled value={voiceAgent.name} />
                                        <TextInput label='Bot Id' placeholder="Bot Id" type="text" id="botId" name="botId" disabled value={voiceAgent.botId} />
                                    </div>
                                    : undefined}


                                <div className="mb-2 flex justify-between px-4">
                                    <label htmlFor="is_scheduled" className="ml-2 text-gray-700 font-inter my-0">
                                        Is Scheduled?
                                    </label>
                                    <label className="inline-flex items-center mb-5 cursor-pointer">
                                        <p className="inline mr-3"><span className={`${isChecked && "text-primary"} font-normal m-1`}>Yes</span>/<span className={`${!isChecked && "text-primary"} font-normal m-1`}>No</span></p>
                                        <input type="checkbox" name='is_scheduled' id="is_scheduled" checked={isChecked} onChange={handleCheckboxChange} className="shadow-sm w-4 h-4  border-gray-300 rounded focus:ring-primary focus:ring-2 font-inter sr-only peer" />
                                        <div className="relative w-9 h-5 bg-gray-200 peer-focus:outline-none peer-focus:ring-1 peer-focus:ring-primary rounded-full peer  peer-checked:after:translate-x-full rtl:peer-checked:after:-translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:start-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-4 after:w-4 after:transition-all dark:border-gray-600 peer-checked:bg-primary"></div>
                                    </label>
                                </div>

                                {isChecked &&
                                    <div className="flex items-center space-x-4 mb-5">

                                        <div className="relative flex-1">

                                            <input
                                                disabled-dates="Fri"
                                                onChange={handleOnChangeDateAndTime}
                                                disabled={!isChecked}
                                                type="date"
                                                id="date"
                                                value={date}
                                                className=" pl-10 w-full h-11 px-4 py-2 mt-1 rounded-lg border border-gray-300 focus:ring-1  focus:ring-gray-400 focus:border-gray-400 shadow-sm bg-input placeholder-gray-400 text-gray-900 focus:outline-none transition duration-300 ease-in-out"
                                                style={{ WebkitAppearance: 'none' }}
                                            />
                                            <img
                                                src="/icons/Calendar.png"
                                                alt="Calendar Icon"
                                                className="absolute left-3 top-1/2 transform -translate-y-1/2 w-5 h-5"
                                            />
                                        </div>

                                        <div className="relative flex-1">
                                            <input
                                                onChange={handleOnChangeDateAndTime}
                                                value={time}
                                                disabled={!isChecked}
                                                placeholder={time}
                                                type="time"
                                                id="time"
                                                className=" pl-10 w-full h-10 px-4 py-2 mt-1  rounded-lg border border-gray-300 focus:ring-1  focus:ring-gray-400 focus:border-gray-400 shadow-sm bg-input placeholder-gray-400 text-gray-900 focus:outline-none transition duration-300 ease-in-out"
                                            />
                                            <img
                                                src="/icons/fi_clock.png"
                                                alt="Clock Icon"
                                                className="absolute left-3 top-1/2 transform -translate-y-1/2 w-5 h-5"
                                            />
                                        </div>
                                    </div>
                                }



                                {/* <div className="mb-4">
                            <label htmlFor="start_date" className="block text-sm font-medium text-gray-700 font-inter mb-1">Start Date</label>
                            <input type="date" className="shadow-sm border p-2 rounded-lg w-full  font-inter  border border-gray-300  focus:outline-none focus:ring-2 focus:ring-primary h-10" name="start_date" id="start_date" disabled={!isChecked} required={isChecked} />
                        </div> */}

                            </div>

                            <div>


                                {/* On Update Version 2 */}
                                <div className="flex flex-col lg:flex-row justify-between gap-4 ">
                                    <TextInput label='Number Of Re-Trials' min="0" placeholder="5" type="number" id="re_trials" name="re_trials" value={retrials} onChange={(event) => setRetrials(event.target.value)} />

                                    {retrials > 0 &&
                                        <div className="grow">
                                            <SelectInput label='Next Dial Time' title='Time' options={nextdialTimes} id="next_dial_time" name="next_dial_time" />
                                        </div>
                                    }
                                    {/* <div className="grow">
                                        <SelectInput label='Dial Priority' title='Priority' options={dialPriorities} id="dial_priority" name="dial_priority" />
                                    </div> */}
                                </div>


                                <div className="mb-3">
                                    <label className="block text-sm font-medium text-gray-700 mb-1 font-inter">
                                        Description
                                    </label>
                                    <textarea type="text" id="description" name="description" placeholder="Description Here..." required className="w-full  px-4 py-2 mt-1 rounded-lg border border-gray-300 focus:ring-1  focus:ring-gray-400 focus:border-gray-400 shadow-sm bg-input placeholder-gray-400 text-gray-900 focus:outline-none transition duration-300 ease-in-out h-40" ></textarea>
                                </div>


                                <button type="submit" className=" bg-primary px-5 text-white font-semibold py-1 rounded-md shadow-sm font-inter float-right">Create</button>


                            </div>
                        </div>
                    </form>
            }

            {orderRequests.length > 0 ? <Table orderRequests={orderRequests} onActivate={handleOnActivate}></Table> : <NoData />}



            <ToastContainer />

        </Layout>
    )
}


const Table = ({ orderRequests, onActivate }) => {
    return (
        <div className="relative overflow-x-auto mb-4 bg-white my-12 px-16 py-8 rounded-lg border shadow-lg">
            <table className="w-full text-sm text-left rtl:text-right text-gray-900">
                <thead className="text-xs text-primary uppercase bg-white  ">
                    <tr className="border-b border-gray-700 text-center font-inter">
                        <th scope="col" className="px-6 py-3">
                            Name
                        </th>
                        <th scope="col" className="px-6 py-3">
                            Batch
                        </th>
                        <th scope="col" className="px-6 py-3">
                            Voice Agent
                        </th>

                        <th scope="col" className="px-6 py-3">
                            Status
                        </th>
                        <th scope="col" className="px-6 py-3">
                            Type
                        </th>
                        <th scope="col" className="px-6 py-3">
                            Active
                        </th>
                        <th scope="col" className="px-6 py-3">
                            Date
                        </th>

                    </tr>
                </thead>
                <tbody>
                    {orderRequests.map((orderRequest) => {
                        return (<TableItem key={orderRequest._id} orderRequest={orderRequest} onActivate={onActivate}></TableItem>)
                    })}
                </tbody>
            </table>
        </div>
    )
}

const TableItem = ({ orderRequest, onActivate }) => {
    return (

        <tr className="bg-white border-b border-gray-400 text-center font-inter">
            <th scope="row" className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap ">
                {orderRequest.name}
            </th>
            <td className="px-6 py-4">
                {orderRequest.batch ? orderRequest.batch.name ? orderRequest.batch.name : undefined : undefined}
            </td>
            <td className="px-6 py-4">
                {orderRequest.use_case_id.name}
            </td>


            <td className={`px-6 py-4 font-medium ${orderRequest.status === 'COMPLETED' ? 'text-primary' : 'text-red-500'}`}>
                {orderRequest.status}
            </td>
            <td className="px-6 py-4">
                <p>{orderRequest.is_scheduled ? `Scheduled` : "Immediate"}</p>
                <p className="text-sm text-gray-400">{orderRequest.is_scheduled && `${moment(orderRequest.start_date).format('DD-MM-YYYY, HH:mm')}`}</p>
            </td>
            <td className="px-6 py-4">
                {orderRequest.active ? "True" : <button onClick={() => onActivate(orderRequest)} className="bg-primary p-2 rounded-lg font-inter text-white shadow-sm">Activate</button>}
            </td>
            <td className="px-6 py-4">
                {moment(orderRequest.created_on).format('DD-MM-YYYY')}
            </td>
        </tr>

    )
}