import { useEffect, useRef, useState } from "react"
import { create, deactivate, deleteOne, read, update } from "../services/apis/organization"
import TextInput from "../components/inputs/TextInput"
import Loading from "../components/Loading"
import { NoSymbolIcon, PhotoIcon, TrashIcon } from "@heroicons/react/24/outline"
import { Link } from "react-router-dom"


const Organization = ({ organization, handleOnRemove, handleOnDeactivate }) => {
    return (
        <>
            <div className={`${organization.deactivate && 'bg-gray-300'} text-slate-800 flex w-full items-center rounded-md p-3 transition-all  border-b-4`}>
                <div className="mr-4 grid place-items-center">
                    <img
                        alt="..."
                        src={organization.logo ? organization.logo : "https://zradia.s3.us-east-2.amazonaws.com/organizations/8254610864"}
                        className="relative inline-block h-24 w-24 !rounded-full  object-contain object-center border p-2"
                    />
                </div>
                <div>
                    <h6 className="text-slate-800 font-medium">
                        {organization.name}
                    </h6>
                    <p className="text-slate-500 text-sm">
                        {organization.email}
                    </p>
                </div>

                {!organization.deactivate &&

                    <div className="flex space-x-2 ml-auto">


                        <button className="flex bg-red-400 items-center text-white px-2 py-1 rounded-lg shadow-lg " onClick={() => handleOnDeactivate(organization._id, { deactivate: new Date() })} >
                            Deactivate
                            <NoSymbolIcon className="h-5 w-5 my-auto text-white  cursor-pointer ml-2" />

                        </button>

                        <button className="flex bg-red-400 items-center text-white  px-2 py-1  rounded-lg shadow-lg " onClick={() => handleOnRemove(organization._id)} >
                            Delete
                            <TrashIcon className="h-5 w-5 my-auto text-white  cursor-pointer  ml-2" />

                        </button>

                    </div>


                }

                {organization.deactivate &&
                    <div className="flex space-x-2 ml-auto">
                        <button className="flex bg-primary items-center text-white  px-2 py-1  rounded-lg shadow-lg " onClick={() => handleOnDeactivate(organization._id, { deactivate: null })} >
                            Activate
                            <NoSymbolIcon className="h-5 w-5 my-auto text-white cursor-pointer ml-2" />

                        </button>

                    </div>
                }

            </div>

        </>

    )
}


export default function Organizations() {
    const [organizations, setOrganizations] = useState([])
    const [loading, setLoading] = useState(false)
    const [trigger, setTrigger] = useState(0)
    const [image, setImage] = useState('https://zradia.s3.us-east-2.amazonaws.com/organizations/8254610864');
    const [selectedFile, setSelectedFile] = useState(null);
    const fileInputRef = useRef(null)

    const handleButtonClick = () => {
        fileInputRef.current.click();
    };

    const handleImageChange = (e) => {
        const file = e.target.files[0];
        if (file) {
            setSelectedFile(file);
            setImage(URL.createObjectURL(file));
        }
    };

    useEffect(() => {
        setLoading(true)
        read().then((response) => {
            console.log('response', response)
            setOrganizations(response)
            setLoading(false)
        }).catch((error) => {
            setLoading(false)
            console.log('error', error)
        })
    }, [trigger])


    const handleOnCreate = async (event) => {
        event.preventDefault()
        const formData = new FormData(event.target)
        if (selectedFile) {
            formData.append('file', selectedFile);
        }
        const data = Object.fromEntries(formData.entries())

        setLoading(true)
        create(data).then((response) => {
            setTrigger(previous => previous + 1)
            setLoading(false)
        }).catch((error) => {
            setLoading(false)
            console.log('error', error)
        })

    }

    const handleOnRemove = async (id) => {
        setLoading(true)
        deleteOne(id).then((response) => {
            setTrigger(previous => previous + 1)
            setLoading(false)
        }).catch((error) => {
            setLoading(false)
            console.log(error)
        })
    }


    const handleOnDeactivate = async (id, body) => {
        setLoading(true)
        deactivate(id, body).then((response) => {
            console.log('redf')
            setTrigger(previous => previous + 1)
            setLoading(false)
        }).catch((error) => {
            setLoading(false)
            console.log(error)
        })
    }

    if (loading) {
        return <Loading />
    }

    return (
        <>
            <div className="flex items-center space-x-4 ml-auto font-inter float-right mt-10 mr-10 ">
                Logout
                <Link to='/' className="flex items-center text-red-400  p-2  hover:bg-default font-inter ">
                    <img src='/icons/Logout.png' alt="Headphone Icon" className="w-5 h-5 mr-2" />

                </Link>
            </div>

            <div className="min-h-screen flex items-center justify-center bg-default">

                <div className="w-full max-w-5xl  overflow-hidden ">
                    <div className="relative flex  flex-col rounded-lg border border-slate-200 bg-white shadow-lg p-8 mb-10 ">

                        <h1 className="text-xl font-semibold text-secondary font-inter mb-2">
                            Create and Manage Organizations
                        </h1>
                        <p className="text-sm leading-6 text-gray-400 font-inter mb-4 w-3/4 mb-4">
                            Easily create new organization by adding organization name and admin email password. manage active workspaces and deactivate others.
                        </p>
                        <div className="flex justify-between mb-5 items-center">
                            <form className="flex space-x-2" onSubmit={handleOnCreate}>
                                <TextInput type="text" id="name" name="name" placeholder="Name" required />
                                <TextInput type="email" id="email" name="email" placeholder="Admin Email" required />
                                <div className="grow">
                                    <TextInput type="password" id="password" name="password" placeholder="Admin Password" required />
                                </div>
                                <button type="submit" className="bg-primary mt-1 px-5 text-white font-semibold rounded-md  shadow-md transition duration-200 h-11  ">Create</button>
                            </form>

                            <div>

                                <div className=" border w-32 rounded-full h-32 p-4 flex justify-center items-center relative float-right ">
                                    <img className="w-full h-full object-contain transition-all duration-300 " src={image} alt="..." />
                                    <div onClick={handleButtonClick} className="cursor-pointer absolute inset-0 bg-black bg-opacity-25 opacity-100 hover:opacity-25 transition-all duration-300 flex items-center justify-center rounded-full">
                                        <PhotoIcon className="text-white text-xl hover:text-gray-200 transition duration-200 w-10" />
                                    </div>
                                </div>

                                <input
                                    type="file"
                                    id="imageUpload"
                                    accept="image/*"
                                    ref={fileInputRef}
                                    style={{ display: 'none' }}
                                    onChange={handleImageChange}
                                />
                            </div>

                        </div>

                    </div>


                    <div className="relative flex  flex-col rounded-lg border border-slate-200 bg-white shadow-lg p-8">


                        <h1 className="text-xl font-semibold text-secondary font-inter mb-2">
                            Organizations List
                        </h1>
                        <nav className="flex min-w-[240px] flex-col gap-1 rounded-lg  space-y-4 bg-gray-100 shadow-lg">
                            {organizations.map((organization) => {
                                return (
                                    <Organization key={organization._id} organization={organization} handleOnRemove={handleOnRemove} handleOnDeactivate={handleOnDeactivate} />
                                )
                            })}


                        </nav>
                    </div>
                </div>
            </div>
        </>
    )
}