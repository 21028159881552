

// export default function Layout({ children }) {
  // const navigate = useNavigate();
  // const location = useLocation();
  // const user = JSON.parse(localStorage.getItem("userData"));
  // const navigation =
  //   user.role === "full_access"
  //     ? [
  //       {
  //         name: "Dashboard",
  //         href: "/dashboard",
  //         icon: ChartPieIcon,
  //       },
  //       {
  //         name: "Use Cases",
  //         href: "/use_cases",
  //         icon: Squares2X2Icon,
  //       },
  //       {
  //         name: "Pending Calls",
  //         href: "/pending_calls",
  //         icon: Squares2X2Icon,
  //       },
  //       {
  //         name: "Config Manager",
  //         href: "/config_manager",
  //         icon: Cog6ToothIcon,
  //       },
  //       {
  //         name: "User Management",
  //         href: "/user_management",
  //         icon: UsersIcon,
  //       },
  //     ]
  //     : user.role == "monitor_access"
  //       ? [
  //         {
  //           name: "Dashboard",
  //           href: "/dashboard",
  //           icon: ChartPieIcon,
  //         },
  //         // {
  //         //   name: "Use Cases",
  //         //   href: "/use_cases",
  //         //   icon: Squares2X2Icon,
  //         // },
  //       ]
  //       : user.role == "tester"
  //         ? [
  //           {
  //             name: "Use Cases",
  //             href: "/use_cases",
  //             icon: Squares2X2Icon,
  //           },
  //         ]
  //         : [
  //           {
  //             name: "Use Cases",
  //             href: "/use_cases",
  //             icon: Squares2X2Icon,
  //           },
  //         ];

//   return (
//     <>
//       <aside className="fixed top-0 left-0 z-40 w-64 h-screen pt-10 transition-transform -translate-x-full  bg-primary sm:translate-x-0 bg-primary">
//         <img className="w-48 mx-auto" src="./logo.png" alt="user photo" />

//         <div className="h-full px-3 pb-4 overflow-y-auto bg-primary pt-8">
//           <ul className="space-y-2 font-medium">
//             {navigation.map((navItem, idx) => {
//               return (
//                 <li
//                   key={idx}
                  // onClick={() => {
                  //   navigate(navItem.href);
                  // }}
//                 >
//                   <div className="flex items-center p-2 rounded-lg text-white hover:bg-white-100  group cursor-pointer">
//                     <navItem.icon
                      // className={`flex-shrink-0 w-5 h-5  transition duration-75 group-hover:text-gray-900 group-hover:text-white ${navItem.href === location.pathname
                      //     ? "text-white"
                      //     : "text-gray-400"
                      //   }`}
//                     />
//                     <span className="flex-1 ml-3 whitespace-nowrap">
//                       {navItem.name}
//                     </span>
//                   </div>
//                 </li>
//               );
//             })}

            // <li>
            //   <div className="flex items-center p-2 rounded-lg text-white hover:bg-white-100  group cursor-pointer">
            //     <ArrowRightEndOnRectangleIcon className="flex-shrink-0 w-5 h-5  transition duration-75 text-gray-400 group-hover:text-gray-900 group-hover:text-white" />

            //     <span
            //       onClick={(e) => {
            //         localStorage.clear();
            //         navigate("/");
            //       }}
            //       className="flex-1 ml-3 whitespace-nowrap text-white"
            //     >
            //       Sign Out
            //     </span>
            //   </div>
            // </li>
//           </ul>
//         </div>
//       </aside>

//       <div className="px-4 sm:ml-64">{children}</div>
//     </>
//   );
// }


import { Fragment, useState } from 'react'
import { Dialog, Transition } from '@headlessui/react'

import { useNavigate, useLocation } from "react-router-dom";
import {
  Bars3Icon,
  ChartPieIcon,
  PhoneIcon,
  Cog6ToothIcon,
  Squares2X2Icon,
  ArrowRightEndOnRectangleIcon,
  UsersIcon,
  XMarkIcon,
} from "@heroicons/react/24/outline";




function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}

export default function Layout({ children }) {
  const [sidebarOpen, setSidebarOpen] = useState(false)
  const navigate = useNavigate();
  const location = useLocation();
  const navigation =
   [  {
    name: "Tanants",
    href: "/tanants",
    icon: ChartPieIcon,
  },]
  return (
    <>
    
      <div>
        <Transition.Root show={sidebarOpen} as={Fragment}>
          <Dialog as="div" className="relative z-50 lg:hidden" onClose={setSidebarOpen}>
            <Transition.Child
              as={Fragment}
              enter="transition-opacity ease-linear duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="transition-opacity ease-linear duration-300"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <div className="fixed inset-0 bg-gray-900/80" />
            </Transition.Child>

            <div className="fixed inset-0 flex">
              <Transition.Child
                as={Fragment}
                enter="transition ease-in-out duration-300 transform"
                enterFrom="-translate-x-full"
                enterTo="translate-x-0"
                leave="transition ease-in-out duration-300 transform"
                leaveFrom="translate-x-0"
                leaveTo="-translate-x-full"
              >
                <Dialog.Panel className="relative mr-16 flex w-full max-w-xs flex-1">
                  <Transition.Child
                    as={Fragment}
                    enter="ease-in-out duration-300"
                    enterFrom="opacity-0"
                    enterTo="opacity-100"
                    leave="ease-in-out duration-300"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                  >
                    <div className="absolute left-full top-0 flex w-16 justify-center pt-5">
                      <button type="button" className="-m-2.5 p-2.5" onClick={() => setSidebarOpen(false)}>
                        <span className="sr-only">Close sidebar</span>
                        <XMarkIcon className="h-6 w-6 text-white" aria-hidden="true" />
                      </button>
                    </div>
                  </Transition.Child>
                  {/* Sidebar component, swap this element with another sidebar if you like */}
                  <div className="flex grow flex-col gap-y-5 overflow-y-auto bg-indigo-300 px-6 pb-2">
                  <div className="flex shrink-0 items-center mt-10 mb-3">
                    <img
                      className="h-20 w-auto mx-auto"
                      src="./logo.png"
                      alt="Your Company"
                    />
                  </div>
                    <nav className="flex flex-1 flex-col">
                      <ul role="list" className="flex flex-1 flex-col gap-y-7">
                        <li>
                          <ul role="list" className="-mx-2 space-y-1">
                            {navigation.map((item) => (
                              <li key={item.name}>
                                <div
                                  onClick={() => {
                                      navigate(item.href);
                                    }}                    
                                  className={classNames(
                                    'group flex gap-x-3 rounded-md p-2 text-sm leading-6 font-semibold text-white'
                                  )}
                                >
                                  <item.icon
                                     className={`flex-shrink-0 w-5 h-5  transition duration-75 group-hover:text-gray-900 group-hover:text-white ${item.href === location.pathname
                                      ? "text-white"
                                      : "text-gray-400"
                                    }`}
                                    aria-hidden="true"
                                  />
                                  {item.name}
                                </div>
                              </li>
                            ))}
                          </ul>

                          <div className="flex items-center mt-2 rounded-lg text-white hover:bg-white-100  group cursor-pointer">
                            <ArrowRightEndOnRectangleIcon className="flex-shrink-0 w-5 h-5  transition duration-75 text-gray-400 group-hover:text-red-700 group-hover:text-white" />

                            <span
                              onClick={(e) => {
                                localStorage.clear();
                                navigate("/admin");
                              }}
                              className="flex-1 ml-3 whitespace-nowrap text-white"
                            >
                              Sign Out
                            </span>
                          </div>
                        </li>
                  
                      </ul>
                    </nav>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </Dialog>
        </Transition.Root>

        {/* Static sidebar for desktop */}
        <div className="hidden lg:fixed lg:inset-y-0 lg:z-50 lg:flex lg:w-72 lg:flex-col">
          {/* Sidebar component, swap this element with another sidebar if you like */}
          <div className="flex grow flex-col gap-y-5 overflow-y-auto bg-indigo-400 px-6">
            <div className="flex shrink-0 items-center mt-10 mb-3">
              <img
                className="h-20 w-auto mx-auto"
                src="./logo.png"
                alt="Your Company"
              />
            </div>
            <nav className="flex flex-1 flex-col">
              <ul role="list" className="flex flex-1 flex-col gap-y-7">
                <li>
                  <ul role="list" className="-mx-2 space-y-1">
                    {navigation.map((item) => (
                      <li key={item.name}>
                        <div
                         onClick={() => {
                          navigate(item.href);
                        }}
                          className={classNames(
                            'group flex gap-x-3 rounded-md p-2 text-sm leading-6 font-semibold text-white cursor-pointer'
                          )}
                        >
                          <item.icon
                             className={`flex-shrink-0 w-5 h-5  transition duration-75 group-hover:text-gray-900 group-hover:text-white ${item.href === location.pathname
                              ? "text-white"
                              : "text-gray-400"
                            }`}
                            aria-hidden="true"
                          />
                          {item.name}
                        </div>
                      </li>
                    ))}
                  </ul>

                  <div className="flex items-center mt-2 rounded-lg text-white hover:bg-white-100  group cursor-pointer">
                    <ArrowRightEndOnRectangleIcon className="flex-shrink-0 w-5 h-5  transition duration-75 text-gray-400 group-hover:text-red-700 group-hover:text-white" />

                    <span
                      onClick={(e) => {
                        localStorage.clear();
                        navigate("/admin");
                      }}
                      className="flex-1 ml-3 whitespace-nowrap text-white"
                    >
                      Sign Out
                    </span>
                  </div>

                </li>
             
          
              </ul>
            </nav>
          </div>
        </div>

        <div className="sticky top-0 z-40 flex items-center gap-x-6 bg-primary px-4 py-4 shadow-sm sm:px-6 lg:hidden">
          <button type="button" className="-m-2.5 p-2.5 text-white lg:hidden" onClick={() => setSidebarOpen(true)}>
            <span className="sr-only">Open sidebar</span>
            <Bars3Icon className="h-6 w-6" aria-hidden="true" />
          </button>
        </div>

        <main className=" lg:pl-72">
          <div className="px-4">{children}</div>
        </main>
      </div>
    </>
  )
}
