


export default function Error() {
    return (
        <div className="flex flex-col justify-center items-center bg-white rounded-lg p-10 ">
            <img src='error404.png' alt='...' className=" h-50" />
            <p className="text-xlg font-inter text-red-500 font-bold text-center ">Internal Server Error</p>
            <p className="font-inter text-gray-400 w-full lg:w-1/2 text-center mb-4">We're sorry, something went bad please contact your provider!</p>
        </div>
    )
}