import { useEffect, useState } from "react"
import SidebarGroupItems from "./SidebarGroupItems"
import { Transition } from "@headlessui/react"


export default function SidebarGroup({ title, icon, items, location }) {
    const [isOpen, setIsOpen] = useState(false)

    useEffect(() => {
        if (items.some(item => item.link === location.pathname || new RegExp(`^${item.link}(\\/\\w+)?$`).test(location.pathname))) {
            setIsOpen(true)
        }
    }, [items, location])

    return (
        <li>
            <button type="button" onClick={() => setIsOpen(!isOpen)} className="flex items-center w-full p-3 text-normal text-gray-900 font-inter text-sm transition duration-75 rounded-lg group bg-active  shadow-sm" aria-controls="dropdown-example" data-collapse-toggle="dropdown-example">
                <img src={icon ? icon : '/icons/Category.png'} alt="Headphone Icon" className="w-5 h-5 mr-2" />
                <span className="flex-1 ms-3 text-left rtl:text-right whitespace-nowrap">{title}</span>
                <svg className="w-4 h-4" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 10 6">
                    <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1" d="m1 1 4 4 4-4" />
                </svg>
            </button>

            <Transition
                show={isOpen}
                enter="transition-opacity duration-300"
                enterFrom="opacity-0"
                enterTo="opacity-100"
                leave="transition-opacity duration-200"
                leaveFrom="opacity-100"
                leaveTo="opacity-0"
            >
                <SidebarGroupItems items={items} location={location} />

            </Transition>

        </li>
    )
}