export const API_GET_USE_CASES = {
  url: "/use_cases",
  method: "GET",
};

export const API_CREATE_USE_CASES = {
  url: "/use_cases",
  method: "POST",
};

export const API_EDIT_USE_CASE = {
  url: "/use_cases",
  method: "PUT",
};

export const API_EDIT_SYSTEM_CONFIG = {
  url: "/system_config",
  method: "PUT",
};



