import publicInstance from "../../utils/publicInstance";
import {
  API_CREATE_ORDER_REQ,
  API_STOP_ORDER_REQ,
} from "../../constants/apis/orderRequest";
import axios from "axios";
import { BASE_URL } from "../../constants/config";

const createOrderReqAPI = async (data) => {
  return await publicInstance({
    ...API_CREATE_ORDER_REQ,
    data,
  })
    .then((res) => {
      return res;
    })
    .catch((e) => {
      throw e;
    });
};

const stopOrderReqAPI = async (id, data) => {
  return await publicInstance({
    url: `${API_STOP_ORDER_REQ.url}/${id}`,
    method: API_STOP_ORDER_REQ.method,
    data,
  })
    .then((res) => {
      return res;
    })
    .catch((e) => {
      throw e;
    });
};


export const read = async () => {
  try {
    const response = await axios.get(`${BASE_URL}/order_requests`, {
      headers: { 'Content-Type': 'application/json' }
    })
    return response
  } catch (error) {
    throw (error)
  }
}


export const ReadByOrgId = async (id) => {
  try {
    const response = await axios.get(`${BASE_URL}/order_requests/organization/${id}`, {
      headers: { 'Content-Type': 'application/json' }
    })
    if (response.status === 200) {
      return response.data.data
    }
    return response
  } catch (error) {
    console.log('error', error)
    throw (error.response.data)
  }
}

export const create = async (data) => {
  try {
    const response = await axios.post(`${BASE_URL}/order_requests`, data, {
      headers: { 'Content-Type': 'application/json' }
    })
    return response
  } catch (error) {
    throw (error)
  }
}


export const report = async (id, query) => {
  try {
    const response = await axios.get(`${BASE_URL}/order_requests/report/organization/${id}?${query}`, {
      headers: {
        'Content-Type': 'application/json',
      }
    })
    return response
  } catch (error) {
    console.log('error', error)
    throw (error.response.data)
  }
}

export const activate = async (id, body) => {
  try {
    const response = await axios.put(`${BASE_URL}/order_requests/${id}/activate`, body, { headers: { 'Content-Type': 'application/json' } })
    console.log('response', response)
    if (response.status === 200) {
      return "Report updated Successfully!"
    }
    return response
  } catch (error) {
    console.log('error', error)
    throw (error.response.data)
  }
}

export { createOrderReqAPI, stopOrderReqAPI };
