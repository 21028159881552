

import { createContext, useEffect, useReducer, useState, } from 'react'
import { useNavigate } from 'react-router-dom'


export const AuthContext = createContext({
    profile: null,
    signIn: () => { },
    signOut: () => { }
})

const AuthReducer = (state, action) => {
    if (action.type === 'SIGNIN') {
        const data = {
            _id: action.payload.profile._id,
            name: action.payload.profile.name,
            email: action.payload.profile.email,
            role: action.payload.profile.role,
            organization: {
                _id: action.payload.profile.organization._id,
                name: action.payload.profile.organization.name,
                logo: action.payload.profile.organization.logo
            }
        }

        localStorage.setItem('profile', JSON.stringify(data))
        return {
            ...state,
            profile: action.payload.profile,
        }
    }

    if (action.type === 'SIGNOUT') {
        localStorage.removeItem('profile')
        return {
            ...state,
            profile: null
        }
    }

    if (action.type === 'CHANGELOGO') {
        const profile = JSON.parse(localStorage.getItem('profile'))
        profile["organization"]["logo"] = action.payload.logo
        localStorage.setItem('profile', JSON.stringify(profile))
        return {
            ...state,
            profile
        }
    }

    return state
}

export default function AuthContextProvider({ children }) {
    const [authenticationState, authenitcationDispatch] = useReducer(AuthReducer, { profile: '' })
    const [loading, setLoading] = useState(true)
    const navigate = useNavigate()
    useEffect(() => {
        if (localStorage.getItem("profile") && localStorage.getItem("profile") != null) {
            authenitcationDispatch({
                type: 'SIGNIN',
                payload: { profile: JSON.parse(localStorage.getItem("profile")) },
            });

            setLoading(false)
        } else {
            setLoading(false)
            navigate('/')
        }
    }, []);

    const handleLogin = (profile) => {
        authenitcationDispatch({
            type: 'SIGNIN',
            payload: { profile: profile }
        })

    }

    const handleLogout = () => {
        authenitcationDispatch({
            type: 'SIGNOUT',
        })
    }

    const handleChangeLogo = (logo) => {
        authenitcationDispatch({
            type: 'CHANGELOGO',
            payload: { logo: logo }
        })
    }

    const contextValue = {
        profile: authenticationState.profile,
        signIn: handleLogin,
        signOut: handleLogout,
        changeLogo: handleChangeLogo
    }

    return (
        <AuthContext.Provider value={contextValue}>
            {loading ? 'Loading...' : children}
        </AuthContext.Provider>
    )
}

