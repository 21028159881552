import React, { useContext, useEffect, useState } from "react";
import { ToastContainer, toast, Slide } from "react-toastify";
import AddUserForm from "./AddUserForm";
import Layout from "../../../components/Layout";
import { create, ReadByOrgId, deleteUser as removeUser } from "../../../services/apis/users";
import { AuthContext } from "../../../store/auth_context";
import moment from "moment";
import Table from "../../../components/shared/tables/Table";
import Thead from "../../../components/shared/tables/Thead";
import Tbody from "../../../components/shared/tables/Tbody";
import TableRow from "../../../components/shared/tables/TableRow";
import TableCell from "../../../components/shared/tables/TableCell";
import { TrashIcon } from "@heroicons/react/24/outline";
import ConfirmDialog from "../../../components/dialogs/ConfirmDialog";



export default function UserManagement2() {

    const { profile } = useContext(AuthContext)
    const [trigger, setTrigger] = useState(0)
    const [users, setusers] = useState([]);
    const [user, setuser] = useState({
        name: "",
        email: "",
        password: "",
        role: "",
    });
    const [openDeleteModal, setopenDeleteModal] = useState(false);
    const [selectedUserId, setselectedUserId] = useState("");



    const createUser = async () => {
        user['organization'] = profile.organization._id
        create(user).then((response) => {
            toast.success("User Added Successfully!", { transition: Slide })
            setuser({
                name: "",
                email: "",
                password: "",
                role: "",
            });
            setTrigger(prev => prev + 1)
        }).catch((error) => {
            toast.error(error.messsage, { transition: Slide })
        })
    }


    const deleteUser = async () => {
        removeUser(selectedUserId).then((response) => {
            toast.success(response, { transition: Slide })
            setTrigger(prev => prev + 1)
        }).catch((error) => {
            toast.error(error.messsage, { transition: Slide })
        })
    }

    useEffect(() => {
        ReadByOrgId(profile.organization._id).then((response) => {
            setusers(response);
        }).catch((error) => {
            toast.error(error.messsage, { transition: Slide })
        });
    }, [trigger, profile.organization._id]);


    return (
        <Layout title='User Management'>
            <AddUserForm user={user} setuser={setuser} createUser={createUser} />

            <Table className="mt-5">
                <Thead>
                    <TableRow>
                        <TableCell className="font-semibold">#</TableCell>
                        <TableCell className="font-semibold">Name</TableCell>
                        <TableCell className="font-semibold">Email</TableCell>
                        <TableCell className="font-semibold">Role</TableCell>
                        <TableCell className="font-semibold">Date</TableCell>
                        <TableCell className="font-semibold">Action</TableCell>
                    </TableRow>
                </Thead>
                <Tbody>
                    {users.map((user, index) => (
                        <TableRow key={index}>
                            <TableCell className="font-medium">{index + 1}</TableCell>
                            <TableCell>{user.name}</TableCell>
                            <TableCell>{user.email}</TableCell>
                            <TableCell>{user?.role && user.role.replace(/_/g, ' ').replace(/\b\w/g, char => char.toUpperCase())}</TableCell>
                            <TableCell>{moment(user.created_on).format('DD-MM-YYYY')}</TableCell>
                            <TableCell>
                                {profile?.role === "administrator" &&
                                    <TrashIcon className="text-red-600 h-6 w-6 mx-auto cursor-pointer"
                                        onClick={() => {
                                            setopenDeleteModal(true);
                                            setselectedUserId(user._id);
                                        }} />}
                            </TableCell>
                        </TableRow>
                    ))}
                </Tbody>
            </Table>


            <ConfirmDialog
                isOpen={openDeleteModal}
                setIsOpen={setopenDeleteModal}
                title="Delete User"
                description="Are you sure you want to delete this user?"
                eventFunction={() => {
                    deleteUser();
                }}
            />

            <ToastContainer />
        </Layout>
    )
}