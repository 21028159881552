import SelectInput from "../inputs/SelectInput"
import TextInput from "../inputs/TextInput"


const nextdialTimes = [
    { text: "One Dial", value: 0 },
    { text: "5 Minutes", value: 5 },
    { text: "10 Minutes", value: 10 },
    { text: "15 Minutes", value: 15 },
    { text: "20 Minutes", value: 20 },
    { text: "25 Minutes", value: 25 },
    { text: "30 Minutes", value: 30 },
]


export default function PendingDialog({ isOpen, setIsOpen }) {

    if (!isOpen) return null


    return (
        <>
            <div className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
                <div className="relative w-1/2 my-6 mx-auto max-w-6xl">
                    <div className="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
                        <div className="flex items-start justify-between p-5 border-b border-solid border-blueGray-200 rounded-t">
                            <h4 className="text-xl font-semibold font-inter">Create Order Request</h4>
                            <button onClick={() => setIsOpen(false)} className="p-1 ml-auto  border-0 text-black  float-right text-3xl leading-none font-semibold outline-none focus:outline-none">
                                <span className=" text-black  h-6 w-6 text-2xl block outline-none focus:outline-none">×</span>
                            </button>
                        </div>

                        <form className="grid grid-cols-1 md:grid-cols-2 gap-12 p-5">
                            <div>

                                <TextInput label='Name' placeholder="Name" type="text" id="name" name="name" required />
                                <TextInput label='Bot Name' placeholder="Bot Name" type="text" id="bot_name" name="bot_name" required />
                                <SelectInput label='Next Dial Time' title='Time' options={nextdialTimes} id="next_dial_time" name="next_dial_time" />

                            </div>
                            <div>
                                <TextInput label='Number Of Re-Trials' placeholder="5" type="number" id="re_trials" name="re_trials" defaultValue={0} />
                                <div className="mb-3">
                                    <label className="block text-sm font-medium text-gray-700 mb-1 font-inter">
                                        Description
                                    </label>
                                    <textarea type="text" id="description" name="description" placeholder="Description Here..." required className="w-full  px-4 py-2 mt-1 rounded-lg border border-gray-300 focus:ring-1  focus:ring-gray-400 focus:border-gray-400 shadow-sm bg-input placeholder-gray-400 text-gray-900 focus:outline-none transition duration-300 ease-in-out h-40" ></textarea>
                                </div>

                                <button type="submit" className=" bg-primary px-5 text-white font-semibold py-1 rounded-md shadow-sm font-inter float-right">Create</button>

                            </div>
                        </form>
                    </div>
                </div>
            </div>
            <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
        </>
    )
}