
// Function to determine sidebar links upon user role
export const getNavigation = (role) => {
    let pages = []
    if (role === "administrator") {
        pages = [
            {
                title: 'Analytics',
                icon: '/icons/Category.png',
                items: [
                    { title: 'Dashboard', link: '/dashboard' },
                    { title: 'Reports', link: '/reports' },
                    { title: 'Pending Calls', link: '/pending_calls' },
                    { title: 'Cancelled Calls', link: '/cancelled_calls' },
                ]
            },
            {
                title: 'Execution',
                icon: '/icons/Manager.png',
                items: [
                    { title: 'Batch Manager', link: '/batch' },
                    { title: 'Job Order Manager', link: '/job_order' }
                ]
            },
            {
                title: 'Voice Agents',
                icon: '/icons/Setting.png',
                items: [
                    { title: 'Configuration', link: '/voice_agents' },
                    { title: 'Testing', link: '/use_cases' },
                    { title: 'Testing List', link: '/testing_list' }
                ]
            },

            {
                title: 'User Management',
                icon: '/icons/User.png',
                link: '/users'
            },
            {
                title: 'Settings',
                icon: '/icons/User.png',
                link: '/settings'
            }
        ]
    } else if (role === "tester") {
        pages = [
            {
                title: 'Voice Agents',
                icon: '/icons/Setting.png',
                items: [
                    { title: 'Configuration', link: '/voice_agents' },
                    { title: 'Testing', link: '/use_cases' },
                    { title: 'Testing List', link: '/testing_list' }
                ]
            }
        ]
    } else if (role === "call_manager") {
        pages = [
            {
                title: 'Analytics',
                icon: '/icons/Category.png',
                items: [
                    { title: 'Dashboard', link: '/dashboard' },
                    { title: 'Reports', link: '/reports' },
                    { title: 'Pending Calls', link: '/pending_calls' }
                ]
            },
            {
                title: 'Execution',
                icon: '/icons/Manager.png',
                items: [
                    { title: 'Batch Manager', link: '/batch' },
                    { title: 'Order Request', link: '/order_request' }
                ]
            },
            {
                title: 'Config',
                icon: '/icons/Setting.png',
                items: [
                    { title: 'Configuratoin', link: '/voice_agents' },
                    { title: 'Testing', link: '/use_cases' },
                    { title: 'Testing List', link: '/testing_list' }
                ]
            },
        ]
    } else if ("monitor") {
        pages = [
            {
                title: 'Analytics',
                icon: '/icons/Category.png',
                items: [
                    { title: 'Dashboard', link: '/dashboard' },
                    { title: 'Reports', link: '/reports' },
                    { title: 'Pending Calls', link: '/pending_calls' }
                ]
            },
            {
                title: 'Execution',
                icon: '/icons/Manager.png',
                items: [
                    { title: 'Batch Manager', link: '/batch' },
                    { title: 'Order Request', link: '/order_request' }
                ]
            },
            {
                title: 'Voice Agents',
                icon: '/icons/Setting.png',
                items: [
                    { title: 'Configuration', link: '/voice_agents' },
                ]
            },
        ]
    }
    else {
        pages = []
    }


    return pages
}

