

import axios from "axios"
import { BASE_URL } from "../../constants/config"


export const ReadByOrgId = async (id) => {
    try {
        const response = await axios.get(`${BASE_URL}/users/organization/${id}`, { headers: { 'Content-Type': 'application/json' } })
        console.log('response', response)
        if (response.status === 200) {
            return response.data.data
        }
        return response
    } catch (error) {
        console.log('error', error)
        throw (error.response.data)
    }
}

export const create = async (data) => {
    try {
        const response = await axios.post(`${BASE_URL}/users`, data, { headers: { 'Content-Type': 'application/json' } })
        console.log('response', response)
        if (response.status === 201) {
            return response.data.data
        }
        return response
    } catch (error) {
        console.log('error', error)
        throw (error.response.data)
    }
}

export const deleteUser = async (id) => {
    try {
        const response = await axios.delete(`${BASE_URL}/users/delete/${id}`, { headers: { 'Content-Type': 'application/json' } })
        console.log('response', response)
        if (response.status === 204) {
            return "User Removed Successfully!"
        }
        return response
    } catch (error) {
        console.log('error', error)
        throw (error.response.data)
    }
}