import publicInstance from "../../utils/publicInstance";
import {
API_SEND_QUEUE
} from "../../constants/apis/queue";



const sendToQueue = async (data) => {
  return await publicInstance({
    ...API_SEND_QUEUE,
    data,
  })
    .then((res) => {
      return res;
    })
    .catch((e) => {
      throw e;
    });
};

export { sendToQueue };
