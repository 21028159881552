import { useContext, useEffect, useState } from "react";
import Layout from "../components/Layout";
import { AuthContext } from "../store/auth_context";
import CancelledCallsTable from "../components/CancelledCallsTable";
import NoData from "../components/NoData";
import { ReadCancelledCalls } from "../services/apis/calls";
import { saveAs } from 'file-saver';
import Papa from 'papaparse';


export default function CancelledCalls() {
    const { profile } = useContext(AuthContext)
    const [calls, setCalls] = useState([{}]);

    const handleExportCSV = () => {
        const csv = Papa.unparse(calls);
        const blob = new Blob([csv], { type: 'text/csv;charset=utf-8;' });
        saveAs(blob, 'data.csv');
    };

    useEffect(() => {
        ReadCancelledCalls(profile.organization._id).then((response) => {
            console.log('h', response)
            setCalls(response)
        }).catch((error) => {
            console.log(error)
        })
    }, [profile.organization._id])

    return (
        <Layout title="Cancelled Calls">
            <button className="mb-4 bg-primary h-10 w-20 rounded-lg text-white shadow-sm mt-4 ml-auto float-right" onClick={handleExportCSV}>Export</button>

            {calls.length > 0 ?
                <CancelledCallsTable calls={calls} />
                :
                <NoData />
            }

        </Layout>
    )
}