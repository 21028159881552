import React, { useContext, useEffect, useState } from "react";
import moment from "moment";
import { ToastContainer, toast, Slide } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { TrashIcon } from "@heroicons/react/24/outline";



import Layout from "../components/Layout";
import DeleteModal from "../components/DeleteModal";
import { create, ReadByOrgId, deleteUser as removeUser } from "../services/apis/users";
import { AuthContext } from "../store/auth_context";
import TextInput from "../components/inputs/TextInput";
import SelectInput from "../components/inputs/SelectInput";

const roleOptions = [
    { text: "Administrator", value: "administrator" },
    { text: "Tester", value: "tester" },
    { text: "Call Manager", value: "call_manager" },
    { text: "Monitor", value: "monitor" }
];

export default function UserManagementOrg() {
    const { profile } = useContext(AuthContext)
    const [trigger, setTrigger] = useState(0)
    const [users, setusers] = useState([]);
    const [user, setuser] = useState({
        name: "",
        email: "",
        password: "",
        role: "",
    });
    const [openDeleteModal, setopenDeleteModal] = useState(false);
    const [selectedUserId, setselectedUserId] = useState("");

    const createUser = async () => {
        user['organization'] = profile.organization._id
        create(user).then((response) => {
            toast.success("User Added Successfully!", { transition: Slide })
            setuser({
                name: "",
                email: "",
                password: "",
                role: "",
            });
            setTrigger(prev => prev + 1)
        }).catch((error) => {
            toast.error(error.messsage, { transition: Slide })
        })
    }


    const deleteUser = async () => {
        removeUser(selectedUserId).then((response) => {
            toast.success(response, { transition: Slide })
            setTrigger(prev => prev + 1)
        }).catch((error) => {
            toast.error(error.messsage, { transition: Slide })
        })
    }

    useEffect(() => {
        ReadByOrgId(profile.organization._id).then((response) => {
            setusers(response);
        }).catch((error) => {
            toast.error(error.messsage, { transition: Slide })
        });
    }, [trigger]);

    return (
        <Layout title='User Management'>

            <div className=" shadow-lg border p-8 bg-white   rounded-lg">
                <h1 className="text-xl font-semibold text-secondary font-inter mb-2">
                    Add and Manage Users
                </h1>
                <p className="text-sm leading-6 text-gray-400 font-inter mb-4 w-3/4 mb-4">
                    Adding new profiles, updating existing ones, and assigning roles or permissions. Provide essential information such as user name, email, and role to ensure accurate access control.
                </p>

                <div className="grid grid-cols-1 lg:grid-cols-5  gap-5">
                    <TextInput label="Name" placeholder="John doe" name="name" id="name" value={user.name} onChange={(event) => { setuser({ ...user, [event.target.name]: event.target.value }); }} />
                    <TextInput label="Email" placeholder="john@example.com" name="email" id="email" value={user.email} onChange={(event) => { setuser({ ...user, [event.target.name]: event.target.value }); }} />
                    <TextInput label="Password" placeholder="*********" name="password" id="password" type="password" value={user.password} onChange={(event) => { setuser({ ...user, [event.target.name]: event.target.value }); }} />
                    <SelectInput label="Access Type" options={roleOptions} title='select' id="role" name="role" value={user.role} onChange={(event) => { setuser({ ...user, [event.target.name]: event.target.value }); }} />

                    <div className="flex">
                        <button
                            onClick={() => createUser()}
                            type="button"
                            className="place-self-end self-center py-2 px-12 text-sm font-medium rounded-md text-white bg-primary font-inter shadow-sm h-11 "
                        >
                            Create user
                        </button>
                    </div>
                </div>
            </div>

            <br />

            <Table
                columns={["Name", "Email", "Access Type", "Date", ""]}
                data={users}
                setopenDeleteModal={setopenDeleteModal}
                setselectedUserId={setselectedUserId}
            />

            <DeleteModal
                open={openDeleteModal}
                setOpen={setopenDeleteModal}
                deleteFunc={() => {
                    deleteUser();
                }}
            />
            <ToastContainer />
        </Layout>
    );
}

const Table = ({ columns, data, setopenDeleteModal, setselectedUserId }) => {
    return (
        <div className="relative overflow-x-auto mb-4 bg-white px-5 py-8 rounded-lg border shadow-lg">
            <table className="w-full text-sm text-left rtl:text-right text-gray-900 ">
                <thead className="text-xs text-primary uppercase bg-white  ">
                    <tr className="border-b border-gray-700 text-center font-inter">
                        {columns.map((col, idx) => {
                            return (
                                <th key={idx} className="border-b border-gray-300 py-3 px-4">
                                    {col}
                                </th>
                            );
                        })}
                    </tr>
                </thead>
                <tbody>
                    {data.map((item, index) => (
                        <tr className="bg-white border-b border-gray-400 text-center font-inter" key={item.name}>

                            <td className="w-52 border-b border-gray-300 p-4 text-center">
                                {item?.name}
                            </td>

                            <td className="border-b border-gray-300 p-4 text-center">
                                {item?.email}
                            </td>
                            <td className="border-b border-gray-300 p-4 text-center">
                                {item?.role === "administrator"
                                    ? "Administrator"
                                    : item?.role === "call_manager"
                                        ? "Call Manager"
                                        : item?.role === "tester" ?
                                            "Tester" :
                                            item?.role === 'monitor' ?
                                                "Monitor" :
                                                item.role}
                            </td>
                            <td className="border-b border-gray-300 p-4 text-center">
                                {moment(item?.created_on).format("DD/MM/YYYY")}
                            </td>
                            {item.role === 'administrator' ? <td></td> :
                                <td

                                    onClick={() => {
                                        setopenDeleteModal(true);
                                        setselectedUserId(item._id);
                                    }}
                                    className="border-b border-gray-300 text-center"
                                >
                                    <TrashIcon className="text-red-600 h-6 w-6 mx-auto cursor-pointer" />

                                </td>
                            }
                        </tr>
                    ))}
                </tbody>
            </table>
        </div>
    );
};
