import { useContext, useEffect, useRef, useState } from "react";
import Layout from "../components/Layout";
import Loading from "../components/Loading";
import { Slide, toast, ToastContainer } from "react-toastify";
import { getAllUseCasesAPI } from "../services/apis/useCases";
import { createWithFile, ReadByOrgId } from "../services/apis/batches";
import { AuthContext } from "../store/auth_context";
import NoData from "../components/NoData";
import TextInput from "../components/inputs/TextInput";
import SelectInput from "../components/inputs/SelectInput";
import moment from "moment";
import CsvTemplate from "../components/CsvTemplate";
import DeleteModal from "../components/DeleteModal";



export default function Batch() {
    const { profile } = useContext(AuthContext)
    const [batches, setBatches] = useState([])
    const [loading, setLoading] = useState(true)
    const [trigger, setTrigger] = useState(0)
    const [useCases, setUseCases] = useState([])
    const [cases, setCases] = useState([])
    const [headers, setHeaders] = useState()
    const [isOpen, setIsOpen] = useState(false)
    const role = profile.role

    const fileInputRef = useRef(null);
    const [selectedFile, setSelectedFile] = useState(null);
    const [fileName, setFileName] = useState('');
    const [usecase, setUsecase] = useState('')
    const [name, setName] = useState('')
    const [description, setDescription] = useState('')
    const [confirmMessage, setConfirmMessage] = useState('')

    const handleButtonClick = () => {
        fileInputRef.current.click();
    };

    const handleFileChange = (event) => {
        const file = event.target.files[0];
        if (file) {
            setSelectedFile(file);
            setFileName(file.name);
        }
    };

    const handleSelectChange = (event) => {
        setUsecase(event.target.value)
        const headers = cases.find((item) => item._id === event.target.value).headers
        setHeaders(headers)
    }

    useEffect(() => {
        setLoading(true)

        ReadByOrgId(profile.organization._id).then((response) => {
            console.log(response)

            if (response && response.data) {
                setBatches(response.data.data)
            }

            setLoading(false)
        }).catch((error) => {
            setLoading(false)
            toast.error(error.messsage, { transition: Slide })
        })
    }, [trigger, profile.organization._id])

    useEffect(() => {
        getAllUseCasesAPI(profile.organization._id).then((response) => {

            if (response && response.data) {
                setCases(response.data.usecases)
                setUseCases(response.data.usecases.map((usecase) => {
                    return { text: usecase.name, value: usecase._id }
                }))
            }
        }).catch((error) => {
            console.log('error', error)
        })
    }, [profile.organization._id])

    const handleOnCreateBatch = async (flag) => {

        const formData = new FormData();
        formData.append('file', selectedFile);
        formData.append('name', name);
        formData.append('usecase', usecase);
        formData.append('description', description);
        formData.append('flag', flag);
        // const formData = new FormData(event.target)
        // const data = Object.fromEntries(formData.entries())

        setLoading(true)
        createWithFile(formData).then((response) => {
            setLoading(false)

            if (response.status === 201) {
                toast.success("Batch Added Successfully!", { transition: Slide })
                setTrigger(prev => prev + 1)
            }

            if (response.status === 200) {
                setConfirmMessage(response.data.message)
                setIsOpen(true)
            }

            // if (response.faileds > 0) {
            //     toast.warn(`Warning: Batch uploaded and number of failed rows is (${response.faileds})`, { transition: Slide })

            // } else {
            //     toast.success("Batch Added Successfully!", { transition: Slide })
            // }

            console.log('responsessss', response)

        }).catch((error) => {
            // console.log('error', error)
            setLoading(false)
            toast.error(error.messsage, { transition: Slide })
        })

    }


    return (
        <Layout title='Batch Manager'>

            <DeleteModal open={isOpen} setOpen={setIsOpen} deleteFunc={() => { handleOnCreateBatch(true); }} title="Confirm Action" description={confirmMessage} />

            {role === "monitor" ? undefined :

                loading ? <Loading /> :
                    <form className="bg-white p-8 mb-4 border shadow-lg rounded-lg ">
                        <h1 className="text-xl font-semibold text-secondary font-inter mb-2">
                            Create and Upload Batches
                        </h1>
                        <p className="text-sm leading-6 text-gray-400 font-inter mb-4 w-3/4 mb-4">
                            Easily create new batches by adding batch names and uploading a CSV file. Ensure the CSV file contains properly formatted data to avoid errors during upload.
                        </p>

                        <div className="grid grid-cols-2 gap-12">
                            <div>
                                <div className="flex flex-col space-y-2 lg:flex-row lg:space-x-5 lg:space-y-0">
                                    <TextInput label='Name' type="text" id="name" name="name" placeholder="Name Here..." required value={name} onChange={(event) => setName(event.target.value)} />
                                    <div className="grow">
                                        <SelectInput label='Voice Agent' title='Voice Agent' options={useCases} id="usecase" name="usecase" value={usecase} onChange={handleSelectChange} />
                                    </div>
                                </div>

                                <div className="mb-3">
                                    <label className="block text-sm font-medium text-gray-700 mb-1 font-inter">
                                        Description
                                    </label>
                                    <textarea type="text" id="description" name="description" placeholder="Description Here..." value={description} onChange={(event) => setDescription(event.target.value)} className="w-full  px-4 py-2 mt-1 rounded-lg border border-gray-300 focus:ring-1  focus:ring-gray-400 focus:border-gray-400 shadow-sm bg-input placeholder-gray-400 text-gray-900 focus:outline-none transition duration-300 ease-in-out h-40" ></textarea>
                                </div>

                                <button onClick={() => handleOnCreateBatch(false)} type="submit" className=" bg-primary px-5 text-white font-semibold py-1 rounded-md  shadow-md transition duration-200">Create</button>
                            </div>

                            <div className="border rounded-lg shadow-sm ">
                                <CsvTemplate headers={headers} classes={'text-sm font-semibold text-secondary float-right shadow-sm mr-5 mt-5 mb-0 drop-shadow-lg'} />
                                <div className="mb-4 flex flex-col justify-center items-center clear-right  h-full">
                                    <button className="" onClick={handleButtonClick}><img src="/img/data-uploader.png" alt="..." className="" /></button>
                                    <p className="font-inter text-center font-light text-sm text-gray-400 w-3/4">Upload files from data files for your product.
                                    </p>
                                    <p className="font-inter text-center font-light text-sm text-gray-400 w-3/4">
                                        File Format .csv .xlsx .xls
                                    </p>
                                    {fileName && <p className="font-inter mt-2 text-sm text-gray-600">Selected file: {fileName}</p>}

                                    <input ref={fileInputRef} onChange={handleFileChange} type="file" id="file" name="file" required className=" mt-1  invisible w-full border border-gray-300 rounded-md p-2" />
                                </div>

                            </div>


                        </div>
                    </form>

            }

            {batches.length > 0 ? <Table batches={batches}></Table> : <NoData />}


            <ToastContainer />
        </Layout>

    )
}

const Table = ({ batches, filters, paginate }) => {
    return (
        <div className="relative overflow-x-auto mb-4 bg-white my-12 px-16 py-8 rounded-lg border shadow-lg ">
            <table className="w-full text-sm text-left rtl:text-right text-gray-900 ">
                <thead className="text-xs text-primary uppercase bg-white  ">
                    <tr className="border-b border-gray-700 text-center font-inter">
                        <th scope="col" className="px-6 py-3">
                            Id
                        </th>
                        <th scope="col" className="px-6 py-3">
                            Name
                        </th>
                        <th scope="col" className="px-6 py-3">
                            Description
                        </th>
                        <th scope="col" className="px-6 py-3">
                            Voice Agent
                        </th>
                        <th scope="col" className="px-6 py-3">
                            File Link
                        </th>
                        <th scope="col" className="px-6 py-3">
                            Warning
                        </th>
                        <th scope="col" className="px-6 py-3">
                            date
                        </th>
                    </tr>
                </thead>
                <tbody>
                    {batches.map((batch) => {
                        return (<TableItem key={batch._id} batch={batch}></TableItem>)
                    })}
                </tbody>
            </table>
        </div>
    )
}


const TableItem = ({ batch }) => {
    return (

        <tr className="bg-white border-b border-gray-400 text-center font-inter">
            <th scope="row" className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap ">
                {batch._id}
            </th>
            <td className="px-6 py-4 text-gray-600">
                {batch.name}
            </td>
            <td className="px-6 py-4 text-gray-600">
                {batch.description}
            </td>
            <td className="px-6 py-4">
                {batch.usecase.name}
            </td>
            <td className="px-6 py-4">
                <a href={`${batch.file}`}>FILE</a>
            </td>
            <td className="px-6 py-4">
                {batch.faileds > 0 ? <span className="text-orange-500">Faileds ({batch.faileds})</span> : <span className="text-primary">Validated</span>}
            </td>
            <td className="px-6 py-4">
                {moment(batch.createdAt).format('DD-MM-YYYY')}
            </td>
        </tr>

    )
}