import { useContext, useEffect, useState } from "react"
import { constructQueryParams } from "../utils/encodeURI"
import { ReadByOrgId as callsReport } from "../services/apis/reports";
import { report as batchesReport } from "../services/apis/batches"
import { report as orderRequestsReport } from '../services/apis/orderRequest'
import { AuthContext } from "../store/auth_context";
import { ReadOne } from "../services/apis/custom_reports";
import { saveAs } from 'file-saver';
import Papa from 'papaparse';
import NoData from "../components/NoData";
import Layout from "../components/Layout";
import { useParams } from "react-router-dom";
import Loading from "../components/Loading";
import Error from "../components/Error";
import TextInput from "../components/inputs/TextInput";
import { ReadConversationReport } from "../services/apis/calls";
import Table from "../components/tables/Table";
import * as XLSX from "xlsx";
import ResponseDialog from "./reports/dialog/ResponseDialog";


const formatDate = (custom) => {
    const date = new Date(custom)
    const day = String(date.getDate()).padStart(2, '0');
    const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are 0-indexed in JS
    const year = date.getFullYear();
    return `${day}-${month}-${year}`
}

export default function CustomReport() {
    const params = useParams()
    const { profile } = useContext(AuthContext)
    const [filters, setFilters] = useState({ usecase: '', status: '', subStatus: '', checkPoint: '', conversationStatus: '', checkpoints: [], gte: '', lte: '', lastN: '', isExecution: false, reportType: 'All', limit: 10, page: 1 })

    const [type, setType] = useState('calls')
    const [fields, setFields] = useState([])
    const [systemFields, setSystemFields] = useState([])
    const [questionFields, setQuestionFields] = useState([])
    const [calls, setCalls] = useState({ data: [], total: 0, count: 0 })
    const [batches, setBatches] = useState({ data: [], total: 0, count: 0 })
    const [orderRequests, setOrderRequests] = useState({ data: [], total: 0, count: 0 })
    const [conversations, setConversations] = useState({ data: [], total: 0, count: 0 })

    const [loading, setLoading] = useState(false)
    const [error, setError] = useState('')

    const [isOpen, setIsOpen] = useState(false)
    const [response, setResponse] = useState('')

    const handleOnShowResponse = (value) => {
        setIsOpen(true)
        setResponse(value)
    }

    const paginate = (page) => {
        setFilters((previous) => { return { ...previous, page: page } })
    }

    const limitation = (limit) => {
        setFilters((previous) => { return { ...previous, limit: limit } })
    }

    // //handle columns for array of objects CSV
    // const handleExportCSV = () => {
    //     const csv = Papa.unparse(type === "calls" ? calls.data : type === "batches" ? batches.data : orderRequests.data);
    //     const blob = new Blob([csv], { type: 'text/csv;charset=utf-8;' });
    //     saveAs(blob, 'data.csv');
    // };

    //EXCEL
    const handleOnExport = (fileName = "report.xlsx") => {
        let worksheet

        //Handel Custom Fields
        //const custom_fields = [...fields, ...systemFields, questionFields]
        //const exportdata = []
        //console.log('exportdata', exportdata)


        if (type === "calls") {
            worksheet = XLSX.utils.json_to_sheet(processingForExport(calls.data));
        } else if (type === "batches") {
            worksheet = XLSX.utils.json_to_sheet(batches.data);
        } else if (type === "orderRequests") {
            worksheet = XLSX.utils.json_to_sheet(orderRequests.data);
        } else if (type === "conversations") {
            worksheet = XLSX.utils.json_to_sheet(processingForExport(conversations.data));
        }


        const workbook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");

        const excelBuffer = XLSX.write(workbook, {
            bookType: "xlsx",
            type: "array",
        });
        const blob = new Blob([excelBuffer], { type: "application/octet-stream" });
        saveAs(blob, 'report.xlsx');
    }


    const processingForExport = (calls) => {
        const data = preprocessingCalls(calls)
        const headers = [...fields, ...systemFields, ...questionFields]
        console.log('fields', fields)
        console.log('headers', headers)

        const maskings = {
            "target": "رقم الهاتف",
            "Fname": "الأسم كامل",
            "Ticket number": "رقم التذكرة",
            "AskForMoreDetail": "أخذ ملاحظات من العميل"
        }

        const result = data.map(item => {
            return headers.reduce((obj, header) => {
                if (item.hasOwnProperty(header)) {
                    obj[header] = item[header];
                } else {
                    const maskingKey = Object.keys(maskings).find(key =>
                        item.hasOwnProperty(key) && maskings[key] === header
                    );

                    obj[header] = maskingKey ? item[maskingKey] : "-";
                }
                return obj;
            }, {});
        });


        console.log('resultss', result)



        return result
    }

    useEffect(() => {
        setLoading(true)
        ReadOne(params.id).then((response) => {
            setFilters({
                checkPoint: response.checkPoint ? response.checkPoint : '',
                conversationStatus: response.conversationStatus ? response.conversationStatus : '',
                checkpoints: response.checkpoints ? response.checkpoints : [],
                usecase: response.usecase ? response.usecase._id : '',
                subStatus: response.subStatus || '',
                gte: response.start_date ? formatDate(response.start_date) : '',
                lte: response.end_date ? formatDate(response.end_date) : '',
                lastN: response.lastN || '',
                isExecution: response.isExecution,
                reportType: response.reportType,
                limit: 10, page: 1
            })
            setQuestionFields(response.questions_fields || [])
            setSystemFields(response.system_fields || [])
            setFields(response.fields || [])
            setType(response.type)
            setLoading(false)
        }).catch((error) => {
            console.log('error', error)
            setLoading(false)
            setError(error.message)
        })
    }, [params.id])


    const preprocessingCalls = (calls) => {
        const updatedCalls = calls.map(call => {
            const { target, Fname, ...otherMetadata } = call.metadata || {};
            const questions = {};
            call.dynamic_values.forEach(({ question, value }) => {
                questions[question] = value;
            });
            return {
                ...call,
                target,
                Fname,
                ...otherMetadata,
                ...questions
            };
        });
        console.log('updatedCalls', updatedCalls)
        return updatedCalls
    }


    useEffect(() => {
        setLoading(true)
        const query = constructQueryParams(filters);
        if (type === "calls") {
            callsReport(profile.organization._id, query).then((response) => {
                setLoading(false)
                setCalls((previous) => {
                    return ({
                        ...previous,
                        data: preprocessingCalls(response.calls),
                        total: response.total,
                        count: response.count
                    })
                })
                console.log('data', response)
            }).catch((error) => {
                setLoading(false)
                setError(error.message)
            })
        }
        else if (type === "batches") {
            batchesReport(profile.organization._id, query).then((response) => {
                setLoading(false)
                setBatches((previous) => {
                    return ({
                        ...previous,
                        data: response.data.batches,
                        total: response.data.total,
                        count: response.data.count
                    })
                })
            }).catch((error) => {
                setLoading(false)
                setError(error.message)
            })
        } else if (type === "orderRequests") {
            orderRequestsReport(profile.organization._id, query).then((response) => {
                setLoading(false)
                setOrderRequests((previous) => {
                    return ({
                        ...previous,
                        data: response.data.orderRequests,
                        total: response.data.total,
                        count: response.data.count
                    })
                })
            }).catch((error) => {
                setLoading(false)
                setError(error.message)
            })
        } else if (type === "conversations") {
            ReadConversationReport(profile.organization._id, query).then((response) => {
                console.log('conversation', response)
                setLoading(false)
                setConversations((previous) => {
                    return ({
                        ...previous,
                        data: preprocessingCalls(response.calls),
                        total: response.total,
                        count: response.count
                    })
                })
                console.log('data', response)
            }).catch((error) => {
                setLoading(false)
                setError(error.message)
            })
        }

    }, [filters, type, profile.organization._id])


    if (loading) {
        return (
            <Layout title='Reports'>
                <Loading />
            </Layout>
        )
    }

    if (error) {
        return (
            <Layout title='Reports'>
                <Error />
            </Layout>
        )
    }

    return (
        <Layout title={`Reports`}>

            <div className="flex mb-5">
                <div className="flex space-x-4 justify-start ">
                    {type && <TextInput label="Type" value={type} disabled />}
                    {filters.usecase && <TextInput label="Voice Agent" value={calls.data.length > 0 ? calls.data[0].use_case_id.name : filters.usecase} disabled />}

                    {type === "conversations" ?
                        filters.checkPoint && <TextInput label="Status" value={filters.checkPoint} disabled />
                        :
                        filters.subStatus && <TextInput label="Status" value={filters.subStatus} disabled />
                    }

                    {filters.lastN && <TextInput label="last N-Days" value={filters.lastN} disabled />}
                    {filters.gte && <TextInput label="Date Range" value={`${filters.gte} - ${filters.lte}`} disabled />}
                </div>
                <button className="self-center  bg-primary h-10 w-20 rounded-lg text-white shadow-sm mt-4 ml-auto" onClick={handleOnExport}>Export</button>
            </div>

            {type === 'batches' ? batches.data.length > 0 ? <Table data={batches} filters={filters} paginate={paginate} limitation={limitation} header={systemFields} questions={questionFields}></Table> : <NoData /> : undefined}
            {type === 'orderRequests' ? orderRequests.data.length > 0 ? <Table data={orderRequests} filters={filters} paginate={paginate} limitation={limitation} header={systemFields} questions={questionFields}></Table> : <NoData /> : undefined}
            {type === 'conversations' ? calls.data.length > 0 ? <Table data={conversations} filters={filters} paginate={paginate} limitation={limitation} header={[...fields, ...systemFields, ...questionFields]} questions={questionFields} handleOnShowResponse={handleOnShowResponse}></Table> : <NoData /> : undefined}
            {type === 'calls' ? calls.data.length > 0 ? <Table data={calls} filters={filters} paginate={paginate} limitation={limitation} header={[...fields, ...systemFields, ...questionFields]} questions={questionFields}></Table> : <NoData /> : undefined}

            {/* {type === 'calls' ? calls.data.length > 0 ? <CallsTable calls={calls} filters={filters} paginate={paginate} fields={fields} systemFields={systemFields} questionFields={questionFields}></CallsTable> : <NoData /> : undefined} */}
            {/* {type === 'conversations' ? conversations.data.length > 0 ? <ConversationsTable conversations={conversations} filters={filters} paginate={paginate} fields={fields} systemFields={systemFields} questionFields={questionFields}></ConversationsTable> : <NoData /> : undefined} */}

            <ResponseDialog isOpen={isOpen} setIsOpen={setIsOpen} description={response} />

        </Layout>

    )
}