import moment from "moment"
import Pagination from "../Pagination"



export default function OrderRequestsTable({ orderRequests, filters, paginate }) {
    return (
        <div className="relative overflow-x-auto mb-4 bg-white my-8 px-16 py-8 rounded-lg border shadow-lg">
            <table className="w-full text-sm text-left rtl:text-right text-gray-900">
                <thead className="text-xs text-primary uppercase bg-white  ">
                    <tr className="border-b border-gray-700 text-center font-inter">
                        <th scope="col" className="px-6 py-3" >
                            Name
                        </th>
                        <th scope="col" className="px-6 py-3" >
                            Voice Agent
                        </th>
                        <th scope="col" className="px-6 py-3" >
                            Batch
                        </th>
                        <th scope="col" className="px-6 py-3" >
                            Status
                        </th>
                        <th scope="col" className="px-6 py-3" >
                            is Scheduled
                        </th>
                        <th scope="col" className="px-6 py-3" >
                            Date
                        </th>

                    </tr>
                </thead>
                <tbody>
                    {orderRequests.data.map((order) => {
                        return (<TableItem key={order._id} order={order}></TableItem>)
                    })}
                </tbody>
            </table>
            <Pagination total={orderRequests.total} limit={filters.limit} paginate={paginate} />
        </div>
    )
}

const TableItem = ({ order }) => {

    return (

        <tr className="bg-white border-b border-gray-400 text-center font-inter">
            <th scope="row" className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap ">
                {order.name}
            </th>
            <td className="px-6 py-4 text-gray-600">
                {order.use_case_id.name}
            </td>
            <td className="px-6 py-4 text-gray-600">
                {order.batch}
            </td>
            <td className="px-6 py-4">
                {order.status}
            </td>
            <td className="px-6 py-4">
                <p>{order.is_scheduled ? `Scheduled` : "Immediate"}</p>
                <p className="text-sm text-gray-400">{order.is_scheduled && `${moment(order.start_date).format('DD-MM-YYYY, HH:mm')}`}</p>
            </td>
            <td className="px-6 py-4">
                {moment(order.created_on).format('DD-MM-YYYY')}
            </td>
        </tr>

    )
}