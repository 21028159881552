import axios from "axios"
import { BASE_URL } from "../../constants/config"


export const read = async () => {
    try {
        const response = await axios.get(`${BASE_URL}/batch`, {
            headers: { 'Content-Type': 'application/json', }
        })
        return response
    } catch (error) {
        throw (error)
    }
}

export const ReadByOrgId = async (id) => {
    try {
        const response = await axios.get(`${BASE_URL}/batch/organization/${id}`, {
            headers: { 'Content-Type': 'application/json', }
        })
        return response
    } catch (error) {
        throw (error)
    }
}

export const create = async (data) => {
    try {
        const response = await axios.post(`${BASE_URL}/batch`, data, {
            headers: { 'Content-Type': 'application/json' }
        })
        if (response.status === 201) {
            return response.data.data
        }
        return response
    } catch (error) {
        console.log('error', error)
        throw (error.response.data)
    }
}

export const createWithFile = async (data) => {
    try {
        const response = await axios.post(`${BASE_URL}/batch/file`, data, {
            headers: { 'Content-Type': 'multipart/form-data' }
        })
        return response
    } catch (error) {
        console.log('error', error)
        throw (error.response.data)
    }
}

export const attach = async (data, id) => {
    try {
        const response = await axios.put(`${BASE_URL}/batch/${id}/attach`, { 'documents': data }, {
            headers: { 'Content-Type': 'multipart/form-data' }
        })
        console.log('hero', response)
        return response
    } catch (error) {
        console.log('error', error)
        console.log(error.response.data)
        throw (error.response.data)
    }
}

export const report = async (id, query) => {
    try {
        const response = await axios.get(`${BASE_URL}/batch/report/organization/${id}?${query}`, {
            headers: {
                'Content-Type': 'application/json',
            }
        })
        return response
    } catch (error) {
        console.log('error', error)
        throw (error.response.data)
    }
}